import { Pagination, PageModel } from '@/store/modules/creditorsAPI'
import { CreditorDepartmentsGetModel, CreditorDepartmentsDetailsModel, CreditorDepartmentsPatchModel, CreditorDepartmentsPatchResponseModel } from '@/models/creditors'
import { CreditorDepartment } from '@/store/modules/creditorDepartments'

export function mockCreditorDepartmentsDetailsModel(current?: CreditorDepartment): CreditorDepartmentsDetailsModel {
  return {
  }
}

export function mockCreditorDepartmentsPatchResponseModel(creditorDepartmentId: number, data: CreditorDepartmentsPatchModel): CreditorDepartmentsPatchResponseModel {
  return {
  }
}

export function mockCreditorDepartmentsGetModel(pagination: Pagination): PageModel<CreditorDepartmentsGetModel> {
  const items: CreditorDepartmentsGetModel[] = [
    {
    },
  ]

  // No filtering
  let results = items
  const total = results.length

  // Paging
  const start = (pagination.page - 1) * pagination.maxResults
  const end = start + pagination.maxResults
  results = results.slice(start, end)

  return {
    _total: total,
    _max_results: pagination.maxResults,
    _page: pagination.page,
    extra: undefined,
    _items: results,
  }
}
