import { ActionTree, GetterTree, Module, MutationTree } from 'vuex'
import { RootState } from '@/store/index'
import { creditorsAPI, APIRequest, APIError } from '@/store/modules/creditorsAPI'
import { StatusType, translateStatusType } from '@/models/cases'
import { GetDashboardGetModel, DashboardEntryModel } from '@/models/dashboard'
import { Alert, apiError } from '@/models/alerts'
import * as mock from '@/mockdata/cases'
import { Formatting } from '@/models/formatting'

export type DashboardsProgress = 'INITIAL' | 'LOADING' | 'LOADED' | 'EMPTY' | 'FAILED'

export interface Dashboard {
  status: string;
  remainingTotal: number;
  formattedTotal: string;
  caseMessagesUnread: number;
  count: number;
  matches: StatusType[];
}

export interface DashboardsState {
  alert: Alert | undefined;
  progress: DashboardsProgress;

  activeCount: string;
  activeRemainingTotal: string;
  activeCaseMessagesUnread: number;
  dashboards: Dashboard[];
}

export const state: DashboardsState = {
  alert: undefined,
  progress: 'INITIAL',

  activeCount: '',
  activeRemainingTotal: '',
  activeCaseMessagesUnread: 0,
  dashboards: [],
}

const namespaced = true

export const getters: GetterTree<DashboardsState, RootState> = {
  getAlert(state): Alert | undefined {
    return state.alert
  },
  getProgress(state): DashboardsProgress {
    return state.progress
  },
  getActiveCount(state): string {
    return state.activeCount
  },
  getActiveRemainingTotal(state): string {
    return state.activeRemainingTotal
  },
  getActiveCaseMessagesUnread(state): number {
    return state.activeCaseMessagesUnread
  },
  getDashboards(state, getters): Dashboard[] {
    return state.dashboards
  },
}

export const mutations: MutationTree<DashboardsState> = {
  setAlert(state, alert: Alert | undefined) {
    state.alert = alert
  },
  setProgress(state, progress: DashboardsProgress) {
    console.log(`PROGRESS: ${progress}`)
    state.progress = progress
  },
  setActiveCount(state, activeCount: number) {
    state.activeCount = activeCount.toString()
  },
  setActiveRemainingTotal(state, activeRemainingTotal: string) {
    state.activeRemainingTotal = activeRemainingTotal
  },
  setActiveCaseMessagesUnread(state, activeCaseMessagesUnread: number) {
    state.activeCaseMessagesUnread = activeCaseMessagesUnread
  },
  setDashboards(state, dashboards: Dashboard[]) {
    var found = false
    var updated: Dashboard[] = []
    dashboards.forEach((replace) => {
      found = false
      state.dashboards.forEach((current) => {
        if (current.matches === replace.matches) {
          updated.push(<Dashboard> {
            status: current.status,
            remainingTotal: replace.remainingTotal,
            formattedTotal: replace.formattedTotal,
            caseMessagesUnread: replace.caseMessagesUnread,
            count: replace.count,
            matches: current.matches,
          })
          found = true
        }
      })
      if (!found) {
        updated.push(replace)
      }
    })
    state.dashboards = updated
  },
}

export const actions: ActionTree<DashboardsState, RootState> = {
  /**
   * Refresh the Dashboard entries
   *
   * @param store The vuex store.
   */
  async refreshDashboards(store): Promise<true | APIError> {
    console.log('REFRESH DASHBOARD START')

    // Set progress to loading
    store.commit('setProgress', 'LOADING')

    const request = <APIRequest> {
      method: 'get',
      path: '/dashboard',
      action: 'dashboards/recieveGetDashboardGetModel',
      mock: mock.mockGetDashboardGetModel(),
    }
    const result: true | APIError = await store.dispatch('creditorsAPI/call', request)
    if (result !== true) {
      apiError(store, result)
      store.commit('setProgress', 'FAILED')
      return result
    }

    // If there are no cases, progress is empty otherwise loaded
    const totalCount = state.dashboards.map((it) => it.count).reduce((previous, current) => previous + current, 0)
    if (totalCount === 0) {
      store.commit('setProgress', 'EMPTY')
    } else {
      store.commit('setProgress', 'LOADED')
    }

    return result
  },

  /**
   * Recieves a GetDashboardGetModel and transforms it into Dashboard entries an commits them to the store
   *
   * @param store The vuex store.
   * @param model The GetDashboardGetModel model.
   */
  async recieveGetDashboardGetModel(store, model: GetDashboardGetModel) {
    // Extract dashboards from the entries
    // According to discussion with VFS, INVOICE should not be shown
    const dashboards: Dashboard[] = [
      // extractDashboard(model, 'INVOICE'),
      extractDashboard(model, ['REMINDER']),
      extractDashboard(model, ['COLLECTION']),
      extractDashboard(model, ['SURVEILLANCE']),
      extractDashboard(model, ['VERDICT_WARNING']),
      extractDashboard(model, ['VERDICT']),
      extractDashboard(model, ['ENFORCEMENT']),
      extractDashboard(model, ['AMORTISATION']),
      extractDashboard(model, ['OTHER']),
    ]
    const active = ['REMINDER', 'COLLECTION', 'SURVEILLANCE', 'VERDICT_WARNING', 'VERDICT', 'ENFORCEMENT', 'AMORTISATION', 'OTHER']
    // const active = ['INVOICE', 'REMINDER', 'COLLECTION', 'SURVEILLANCE', 'VERDICT_WARNING', 'VERDICT', 'ENFORCEMENT', 'AMORTISATION', 'OTHER']

    const activeCount = dashboards.filter((it) => active.includes(it.matches[0])).reduce(((acc, it) => acc + it.count), 0)
    const activeRemainingTotal = dashboards.filter((it) => active.includes(it.matches[0])).reduce(((acc, it) => acc + it.remainingTotal), 0)
    const activeCaseMessagesUnread = dashboards.filter((it) => active.includes(it.matches[0])).reduce(((acc, it) => acc + it.caseMessagesUnread), 0)

    store.commit('setActiveCount', Formatting.formatNumber(activeCount))
    store.commit('setActiveRemainingTotal', Formatting.formatAmount(activeRemainingTotal))
    store.commit('setActiveCaseMessagesUnread', activeCaseMessagesUnread)
    store.commit('setDashboards', dashboards)
  },
}

export const dashboards: Module<DashboardsState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations,
  modules: {
    creditorsAPI,
  },
}

function joinDashboardEntries(lhs: DashboardEntryModel, rhs: DashboardEntryModel): DashboardEntryModel {
  return {
    remaining_capital: lhs.remaining_capital + rhs.remaining_capital,
    count: lhs.count + rhs.count,
    unread_messages: lhs.unread_messages + rhs.unread_messages,
  }
}

function extractDashboard(model: GetDashboardGetModel, statuses: StatusType[]): Dashboard {
  var entry: DashboardEntryModel = { remaining_capital: 0, count: 0, unread_messages: 0 }
  statuses.forEach((it) => {
    switch (it) {
      case 'INVOICE':
        entry = joinDashboardEntries(entry, model.invoice)
        break
      case 'REMINDER':
        entry = joinDashboardEntries(entry, model.reminder)
        break
      case 'COLLECTION':
        entry = joinDashboardEntries(entry, model.collection)
        break
      case 'SURVEILLANCE':
        entry = joinDashboardEntries(entry, model.surveillance)
        break
      case 'VERDICT_WARNING':
        entry = joinDashboardEntries(entry, model.verdict_warning)
        break
      case 'VERDICT':
        entry = joinDashboardEntries(entry, model.verdict)
        break
      case 'ENFORCEMENT':
        entry = joinDashboardEntries(entry, model.enforcement)
        break
      case 'AMORTISATION':
        entry = joinDashboardEntries(entry, model.amortisation)
        break
      case 'OTHER':
        entry = joinDashboardEntries(entry, model.other)
        break
    }
  })

  const primary = statuses[0]
  return {
    status: translateStatusType(primary),
    remainingTotal: entry.remaining_capital,
    formattedTotal: Formatting.formatAmount(entry.remaining_capital),
    caseMessagesUnread: entry.unread_messages,
    count: entry.count,
    matches: statuses,
  }
}
