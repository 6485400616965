import i18n from '@/plugins/i18n'

export type PaymentType = 'CREDIT' | 'SWISH' | 'TRUSTLY' | 'BANKGIRO-OCR' | 'CREDITOR' | 'POSTGIRO-OCR' | 'AUTHORITY'
export function translatePaymentType(payment: PaymentType | undefined): string {
  switch (payment) {
    case 'CREDIT': return i18n.tc('models.paymentType.CREDIT')
    case 'SWISH': return i18n.tc('models.paymentType.SWISH')
    case 'TRUSTLY': return i18n.tc('models.paymentType.TRUSTLY')
    case 'BANKGIRO-OCR': return i18n.tc('models.paymentType.BANKGIRO-OCR')
    case 'CREDITOR': return i18n.tc('models.paymentType.CREDITOR')
    case 'POSTGIRO-OCR': return i18n.tc('models.paymentType.POSTGIRO-OCR')
    case 'AUTHORITY': return i18n.tc('models.paymentType.AUTHORITY')
    case undefined: return i18n.tc('models.paymentType.OTHER')
    default: return ''
  }
}

export type ReceiverType = 'TO_VISMA' | 'TO_CREDITOR'
export function translateReceiverType(receiver: ReceiverType | undefined): string {
  switch (receiver) {
    case 'TO_VISMA': return i18n.tc('models.paymentReceiver.TO_VISMA')
    case 'TO_CREDITOR': return i18n.tc('models.paymentReceiver.TO_CREDITOR')
    case undefined: return i18n.tc('models.paymentReceiver.OTHER')
    default: return ''
  }
}

export interface CasePaymentsGetModel {
  payment_type: PaymentType; // Type of payment
  payment_receiver: ReceiverType; // Payment from debtor received
  accounting_date: string; // Date of payment
  reference_number: string; // Payment against ocr or invoice number
  amount: number; // Payment amount
  currency: string; // Payment currency
}

export interface CasePaymentsPostModel {
  invoice_number: string; // Payment against invoice number, max_length=20
  accounting_date: string; // Date of payment
  amount: number; // Payment amount
  currency: string; // Payment currency according to ISO 4217:2001, min_length=3, max_length=3
  payment_message: string | null; // Message with payment
}

export interface CasePaymentsPostResponseModel {
  case_payments_id: number; // Temporary id to use when register sub entities to Case Payments
}
