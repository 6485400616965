import {
  ActionTree, GetterTree, Module, MutationTree,
} from 'vuex'
import { RootState } from '@/store/index'
import {
  creditorsAPI, APIRequest, Pagination, PageModel, Page, APIError, missingError,
} from '@/store/modules/creditorsAPI'
import { CaseActionsGetModel, CaseActionsPostModel, CaseActionsPostResponseModel } from '@/models/actions'
import { Alert, apiError } from '@/models/alerts'
import { Case } from '@/store/modules/cases'
import * as mock from '@/mockdata/caseActions'
import { Formatting } from '@/models/formatting'

export interface CaseAction {
  caseId: number;
  action: string;
  type: string;
  date: Date;
  dateFormatted: string;
}

export interface CaseActionsState {
  alert: Alert | undefined;
  caseActions?: Page<CaseAction>;
}

export const state: CaseActionsState = {
  alert: undefined,
  caseActions: undefined,
}

const namespaced = true

export const getters: GetterTree<CaseActionsState, RootState> = {
  getAlert(state): Alert | undefined {
    return state.alert
  },
  getCaseActions(state): Page<CaseAction> | undefined {
    return state.caseActions
  },
}

export const mutations: MutationTree<CaseActionsState> = {
  setAlert(state, alert: Alert | undefined) {
    state.alert = alert
  },
  setCaseActions(state, CaseActions: Page<CaseAction>) {
    state.caseActions = CaseActions
  },
}

export const actions: ActionTree<CaseActionsState, RootState> = {
  /**
   * Refresh the case actions entries
   *
   * @param store The vuex store.
   */
  async refreshCaseActions(store, page: number): Promise<Page<CaseAction> | APIError> {
    console.log(`REFRESHING CASE ACTIONS ${page} START`)
    const currentCase: Case | undefined = store.rootGetters['cases/getCurrentCase']

    if (currentCase === undefined) return missingError('currentCase')

    // Build pagination from requested page
    const pagination = new Pagination(10, page)

    const request = <APIRequest> {
      method: 'get',
      path: `/case-actions?case_id=${currentCase.caseId}`,
      extra: currentCase.caseId,
      action: 'caseActions/recieveCaseActionsGetModel',
      mock: mock.mockCaseActionsGetModel(pagination),
      paging: pagination,
    }
    const result: true | APIError = await store.dispatch('creditorsAPI/call', request)
    if (result !== true) {
      apiError(store, result)
      return result
    }
    return store.getters.getCaseActions
  },

  /**
   * Recieves a CaseActionsGetModel and transforms it into costs an commits them to the store
   *
   * @param store The vuex store.
   * @param model The CaseActionsGetModel model.
   */
  async recieveCaseActionsGetModel(store, page: PageModel<CaseActionsGetModel>) {
    const caseId = page.extra as number

    const CaseActions: Page<CaseAction> = Page.transform(page, ((it) => <CaseAction> {
      caseId,
      action: it.action,
      type: it.type,
      date: new Date(it.date),
      dateFormatted: Formatting.formatDate(new Date(it.date)),
    }))

    store.commit('setCaseActions', CaseActions)
  },

  /**
   * Registers a new case action
   *
   * @param store The vuex store.
   */
  async createCaseAction(store): Promise<true | APIError> {
    const currentCase: Case | undefined = store.rootGetters['cases/getCurrentCase']
    console.log('CREATE CASE ACTION START')

    if (currentCase === undefined) return missingError('currentCase')

    const data: CaseActionsPostModel = {
      action_code: 'CLOSE_OTHER',
      action_date: Formatting.formatDate(new Date()),
    }

    const request = <APIRequest> {
      method: 'post',
      path: `/case-actions?case_id=${currentCase.caseId}`,
      data,
      action: 'caseActions/recieveCaseActionsPostResponseModel',
      mock: mock.mockCaseActionsPostResponseModel(data),
    }
    const result: true | APIError = await store.dispatch('creditorsAPI/call', request)
    if (result !== true) {
      apiError(store, result)
      return result
    }
    return true
  },

  /**
   * Recieves a response for registering a new case action
   *
   * @param store The vuex store.
   * @param response The model for case action creation.
   */
  async recieveCaseActionsPostResponseModel(store, model: CaseActionsPostResponseModel) {
  },
}

export const caseActions: Module<CaseActionsState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations,
  modules: {
    creditorsAPI,
  },
}
