
import Vue from 'vue'
import router from '@/router'
import Component from 'vue-class-component'
import { namespace } from 'vuex-class'
import { State2Way } from 'vuex-class-state2way'
import { Alert } from '@/models/alerts'
import { InputText, InputFile, validInputs } from '@/models/validations'
import { MenuBar } from '@/models/menus'
import { DocumentType } from '@/models/documents'
import { APIError } from '@/store/modules/creditorsAPI'

const Authentications = namespace('authentications')
const Cases = namespace('cases')
const CaseDocuments = namespace('caseDocuments')
const Navigation = namespace('navigation')

type Progress = 'INITIAL' | 'UPLOADING' | 'UPLOADED'

@Component
export default class RegisterManuallySuccessView extends Vue {
  @Cases.Getter getInvoiceDescription!: InputText
  @Cases.Getter getCurrentRegisterId!: number
  @Navigation.Getter getMenuBar!: MenuBar

  @CaseDocuments.Mutation setCurrentDescription!: (currentDescription: InputText) => void
  @CaseDocuments.Mutation setCurrentDocumentType!: (documentType: DocumentType) => void

  @CaseDocuments.Action startUploadCaseDocument!: () => void
  @CaseDocuments.Action uploadCaseDocument!: (registerId: number) => Promise<true | APIError>
  @Authentications.Action switchCurrentCreditor!: (creditorId: number) => Promise<true | APIError>

  @State2Way('cases/setAlert', (state) => state.cases.alert) casesAlert!: Alert | undefined
  @State2Way('caseDocuments/setAlert', (state) => state.caseDocuments.alert) caseDocumentsAlert!: Alert | undefined
  @State2Way('caseDocuments/setCurrentFile', (state) => state.caseDocuments.currentFile) currentFile!: InputFile

  strict = false
  invoiceFile: File | null = null
  current: Progress = 'INITIAL'

  async reset() {
    this.strict = false
  }

  get progress(): Progress { return this.current }
  set progress(progress: Progress) { this.current = progress }

  async switchCreditor(creditorId: number) {
    if (await this.switchCurrentCreditor(creditorId) === true) router.push('/cases-overviews')
  }

  async upload() {
    const inputs = [this.currentFile]
    this.strict = true
    if (validInputs(inputs, this.strict)) {
      this.progress = 'UPLOADING'
      if (await this.uploadCaseDocument(this.getCurrentRegisterId) === true) {
        this.progress = 'UPLOADED'
      } else {
        this.progress = 'INITIAL'
      }
      this.strict = false
    }
  }

  success() {
    router.push('/register-manually-start')
  }

  async mounted() {
    this.progress = 'INITIAL'
    await this.startUploadCaseDocument()
    this.setCurrentDescription(this.getInvoiceDescription)
    this.setCurrentDocumentType('INVOICE')
  }
}
