import { ActionTree, GetterTree, Module, MutationTree } from 'vuex'
import { RootState } from '@/store/index'
import Axios, { Method, AxiosError } from 'axios'
import jwt_decode from 'jwt-decode'

export const MOCK_SIMPLIFIED_TOKEN = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJtYl9pZCI6IjE5NTAwMTE4MjA0NiIsImZpcnN0X25hbWUiOiJUZXN0IiwibGFzdF9uYW1lIjoiVGVzdHNzb24iLCJpcCI6IjEyNy4wLjAuMSIsImZ1bmN0aW9uYWxfc2NvcGUiOiJzaW1wbGlmaWVkIn0.JLiVMaVFS3z6_QIovSKCt57wSfAKadW5m40t59cSm7o'
export const MOCK_OTHER_TOKEN = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJtYl9pZCI6IjE5NTAwMTE4MjA0NiIsImZpcnN0X25hbWUiOiJUZXN0IiwibGFzdF9uYW1lIjoiVGVzdHNzb24iLCJpcCI6IjEyNy4wLjAuMSIsImZ1bmN0aW9uYWxfc2NvcGUiOiJvdGhlciJ9.yDnE6LTguoUMMThcerrgQQwgbb6BBBsiAdnW8VLOTD8'
export const MOCK_UNAUTHORIZED_TOKEN = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJtYl9pZCI6IjE5NTAwMTE4MjA0NiIsImZpcnN0X25hbWUiOiJUZXN0IiwibGFzdF9uYW1lIjoiVGVzdHNzb24iLCJpcCI6IjEyNy4wLjAuMSJ9.wEO0FYafj5W3-UI6Trw9dDGD3ETdLbspi1GH0k4gWHQ'

export class Pagination {
  maxResults: number

  page: number

  constructor(maxResults = 25, page = 1) {
    this.maxResults = maxResults
    this.page = page
  }
}

export class Page<T> {
  items: T[]
  total: number
  maxResults: number
  page: number

  constructor(items: T[], total: number, maxResults: number, page: number) {
    this.items = items
    this.total = total
    this.maxResults = maxResults
    this.page = page
  }

  map<U>(callbackfn: (value: T, index: number, array: T[]) => U): Page<U> {
    return new Page(this.items.map(callbackfn), this.total, this.maxResults, this.page)
  }

  static transform<A, B>(model: PageModel<A>, callbackfn: (value: A, index: number, array: A[]) => B): Page<B> {
    const items: B[] | undefined = model._items?.map(callbackfn)
    return new Page<B>(items ?? [], model._total, model._max_results, model._page)
  }
}

export function isPage<T>(object: any): object is Page<T> {
  return 'page' in object
}

export interface PageModel<T> {
  _items: null | T[];
  _total: number;
  _max_results: number;
  _page: number;
  extra: any;
}

export interface APIRequest {
  method: Method;
  path: string;
  data?: any;
  _etag?: string;
  extra?: any;
  commit?: string;
  action?: string;
  mock?: any | APIError;
  paging?: Pagination;
}

export interface APIUpload {
  method: Method;
  path: string;
  file: File;
  extra?: any;
  commit?: string;
  action?: string;
  mock?: any | APIError;
}

export interface APIError {
  errors: { [field: string]: string };
  message: string;
}

export function isAPIError(object: any): object is APIError {
  return 'errors' in object && 'message' in object
}

export function missingError(value: string): APIError {
  return { errors: {}, message: `Missing value ${value}` }
}

export interface CreditorsAPIState {
  host: string;
  token?: string;
  expires?: Date;
}

export const state: CreditorsAPIState = {
  host: `${process.env.VUE_APP_BACKEND_BASE_URL}`,
  token: undefined,
  expires: undefined,
}

const namespaced = true

export const getters: GetterTree<CreditorsAPIState, RootState> = {
  getToken(state): string | undefined {
    return state.token
  },
  getExpires(state): Date | undefined {
    return state.expires
  },
}

export const mutations: MutationTree<CreditorsAPIState> = {
  setToken(state, token?: string) {
    var expires: Date | undefined
    if (token !== undefined) {
      expires = new Date((jwt_decode(token) as any).t_exp)
    }
    console.log(`TOKEN UPDATED (${expires}): ${token}`)
    state.expires = expires
    state.token = token
  },
  setExpires(state, expires?: Date) {
    state.expires = expires
  },
}

const sleep = (milliseconds: number) => new Promise((resolve) => setTimeout(resolve, milliseconds))

export const actions: ActionTree<CreditorsAPIState, RootState> = {
  /**
   * Calls the creditor-rest-api with the parameters specified by the request.
   *
   * @param store The vuex store.
   * @param request The request to be processed.
   */
  async call(store, request: APIRequest): Promise<true | APIError> {
    var headers: any = { 'X-API-KEY': state.token }

    // Mock support
    if (state.token === MOCK_SIMPLIFIED_TOKEN || state.token === MOCK_OTHER_TOKEN || state.token === MOCK_UNAUTHORIZED_TOKEN) {
      await sleep(1000)
      const data = request.mock
      if (isAPIError(data)) return data
      data.extra = request.extra
      if (request.commit !== undefined) {
        store.commit(request.commit, data, { root: true })
      }
      if (request.action !== undefined) {
        await store.dispatch(request.action, data, { root: true })
      }
      return true
    }

    console.log('CREDITORS API CALL')

    // Token refresh
    const expires: Date | undefined = store.getters.getExpires
    if (expires !== undefined && new Date() >= expires) {
      store.commit('setExpires', undefined)
      await store.dispatch('authentications/refreshToken', null, { root: true })
    }

    let query = ''

    // Support for paging
    if (request.paging !== undefined) {
      if (request.path.includes('?')) query = `&max_results=${request.paging.maxResults}&page=${request.paging.page}`
      else query = `?max_results=${request.paging.maxResults}&page=${request.paging.page}`
    }

    // Headers
    if (request._etag !== undefined) {
      headers = { 'X-API-KEY': state.token, 'If-Match': request._etag }
    }

    // Make request
    try {
      const response = await Axios({
        method: request.method,
        url: state.host + request.path + query,
        headers: headers,
        data: request.data,
      })

      // Retrieve data
      const { data } = response
      if (data.constructor === Object) {
        data.extra = request.extra
      }

      // Data can be delivered either through commit or action
      if (request.commit !== undefined) {
        store.commit(request.commit, data, { root: true })
      }
      if (request.action !== undefined) {
        await store.dispatch(request.action, data, { root: true })
      }

      return true
    } catch (err) {
      if (err instanceof AxiosError) {
        const axiosError = err as AxiosError<APIError>
        const apiError = axiosError.response?.data

        // Extra check for token ip changes
        if (axiosError.response?.status === 401 && axiosError.response?.data?.message === 'The token_schema can only be used from the same location.') {
          store.commit('setExpires', undefined)
          if (await store.dispatch('authentications/refreshToken', null, { root: true }) === true) {
            return store.dispatch('call', request)
          }
        }

        console.log(apiError)
        return apiError ?? {} as APIError
      }
      throw err
    }
  },

  async upload(store, request: APIUpload): Promise<true | APIError> {
    // Mock support
    if (state.token === MOCK_SIMPLIFIED_TOKEN || state.token === MOCK_OTHER_TOKEN || state.token === MOCK_UNAUTHORIZED_TOKEN) {
      await sleep(1000)
      const data = request.mock
      if (isAPIError(data)) return data
      data.extra = request.extra
      if (request.commit !== undefined) {
        store.commit(request.commit, data, { root: true })
      }
      if (request.action !== undefined) {
        await store.dispatch(request.action, data, { root: true })
      }
      return true
    }

    console.log('CREDITORS API UPLOAD')

    // Token refresh
    const expires: Date | undefined = store.getters.getExpires
    if (expires !== undefined && new Date() >= expires) {
      await store.dispatch('authentications/refreshToken', null, { root: true })
    }

    const formData = new FormData()
    formData.append('file', request.file)

    // Make request
    try {
      const response = await Axios({
        method: request.method,
        url: state.host + request.path,
        headers: {
          'X-API-KEY': state.token,
          'Content-Type': 'multipart/form-data',
        },
        data: formData,
      })

      // Retrieve data
      const { data } = response
      data.extra = request.extra

      // Data can be delivered either through commit or action
      if (request.commit !== undefined) {
        store.commit(request.commit, data, { root: true })
      }
      if (request.action !== undefined) {
        await store.dispatch(request.action, data, { root: true })
      }

      return true
    } catch (err) {
      if (err instanceof AxiosError) {
        const axiosError = err as AxiosError<APIError>
        const apiError = axiosError.response?.data
        console.log(apiError)
        return apiError ?? {} as APIError
      }
      throw err
    }
  },
}

export const creditorsAPI: Module<CreditorsAPIState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations,
}
