
import Vue from 'vue'
import router from '@/router'
import { namespace } from 'vuex-class'
import { State2Way } from 'vuex-class-state2way'
import Component from 'vue-class-component'
import { MenuBar } from '@/models/menus'
import { InputText, InputFile, InputSelect, validInputs } from '@/models/validations'
import { CaseDocument } from '@/store/modules/caseDocuments'
import { Alert } from '@/models/alerts'
import { Page, APIError } from '@/store/modules/creditorsAPI'
import { FieldType } from '@/components/NordicCoolTable.vue'

const Authentications = namespace('authentications')
const CaseDocuments = namespace('caseDocuments')
const Navigation = namespace('navigation')

@Component
export default class CaseDocumentsView extends Vue {
  @Navigation.Getter getMenuBar!: MenuBar

  @Authentications.Action switchCurrentCreditor!: (creditorId: number) => Promise<true | APIError>
  @CaseDocuments.Action refreshCaseDocuments!: (page: number) => Promise<Page<CaseDocument> | APIError>
  @CaseDocuments.Action uploadCaseDocument!: () => Promise<true | APIError>
  @CaseDocuments.Action startUploadCaseDocument!: () => void
  @CaseDocuments.Action queryCaseDocumentUrl!: (caseDocumentId: number) => Promise<string | APIError>

  @State2Way('caseDocuments/setAlert', (state) => state.caseDocuments.alert) alert!: Alert | undefined
  @State2Way('caseDocuments/setCurrentFile', (state) => state.caseDocuments.currentFile) currentFile!: InputFile
  @State2Way('caseDocuments/setCurrentDescription', (state) => state.caseDocuments.currentDescription) currentDescription!: InputText
  @State2Way('caseDocuments/setCurrentDocumentOption', (state) => state.caseDocuments.currentDocumentOption) currentDocumentOption!: InputSelect

  strict = false

  async reset() {
    this.strict = false
  }

  async switchCreditor(creditorId: number) {
    if (await this.switchCurrentCreditor(creditorId) === true) router.push('/cases-overviews')
  }

  async upload() {
    const inputs = [this.currentFile, this.currentDescription, this.currentDocumentOption]
    this.strict = true
    if (validInputs(inputs, this.strict)) {
      await this.uploadCaseDocument()
      this.strict = false
      this.$root.$emit('bv::refresh::table', 'caseDocuments')
    }
  }

  async download(data: {item: CaseDocument}) {
    const url = await this.queryCaseDocumentUrl(data.item.caseDocumentId)
    if (typeof (url) === 'string') {
      window.open(url, '_blank')
    }
  }

  caseDocumentsItems(page: number): Promise<Page<CaseDocument> | APIError> {
    return this.refreshCaseDocuments(page)
  }

  get fields(): FieldType[] {
    return [
      {
        key: 'caseDocumentId', label: this.$i18n.tc('common.fields.id'), sortable: false, size: 'small', alignment: 'left',
      },
      {
        key: 'description', label: this.$i18n.tc('common.fields.description'), sortable: false, size: 'large', alignment: 'left',
      },
      {
        key: 'filename', label: this.$i18n.tc('case.details.documents.file'), sortable: false, size: 'large', alignment: 'left',
      },
      {
        key: 'type', label: this.$i18n.tc('case.details.documents.type'), sortable: false, size: 'small', alignment: 'right',
      },
      {
        key: 'createFormatted', label: this.$i18n.tc('common.fields.created'), sortable: false, size: 'small', alignment: 'right',
      },
      {
        key: 'actions', label: '', sortable: false, size: 'actions', alignment: 'right',
      },
    ]
  }

  async mounted() {
    this.startUploadCaseDocument()
  }
}
