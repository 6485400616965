import { ActionTree, GetterTree, Module, MutationTree } from 'vuex'
import { RootState } from '@/store/index'
import { creditorsAPI, APIRequest, Pagination, PageModel, Page, APIError, missingError } from '@/store/modules/creditorsAPI'
import { CreditorUsersGetModel, CreditorUsersPostModel, CreditorUsersPostResponseModel, CreditorUsersDetailsModel, CreditorUsersPatchModel, CreditorUsersPatchResponseModel, CreditorUsersDeleteModel, AdministrationRights } from '@/models/creditors'
import { Alert, apiError } from '@/models/alerts'
import * as mock from '@/mockdata/creditorUsers'
import { InputText, InputCheckbox } from '@/models/validations'
import { CreditorDepartment } from './creditorDepartments'

export interface CreditorUser {
  creditorUserId: number;
  creditorId: number;
  personalNumber: string;
  firstName: string;
  lastName: string;
  fullName: string;
  email: string;
  userName: string;

  isCurrent: boolean;

  mobilePhone: string | undefined;
  reportSettlement: boolean | undefined;
  reportInvoiceSpecification: boolean | undefined;
  administrationRights: AdministrationRights[]
}

export interface CreditorUsersState {
  alert: Alert | undefined;
  creditorUsers?: Page<CreditorUser>;
  currentCreditorUser?: CreditorUser;
  currentEtag?: string;
  createdCreditorUserId?: string;

  personalNumber: InputText;
  firstName: InputText;
  lastName: InputText;
  mobilePhone: InputText;
  email: InputText;
  rightsAll: InputCheckbox;
  rightsUser: InputCheckbox;
  rightsDepartment: InputCheckbox;
  rightsCase: InputCheckbox;
  reportSettlement: InputCheckbox;
  reportInvoiceSpecification: InputCheckbox;
}

function resetPersonalNumber(value: string | null = null): InputText {
  return InputText.eitherNumberLike({ value: value, minLength: 10, maxLength: 12, digitsOnly: true, required: true })
}
function resetFirstName(value: string | null = null): InputText {
  return InputText.standard({ value, minLength: null, maxLength: 50, required: true })
}
function resetLastName(value: string | null = null): InputText {
  return InputText.standard({ value, minLength: null, maxLength: 50, required: true })
}
function resetMobilePhone(value: string | null = null): InputText {
  return InputText.numberLike({ value: value, minLength: null, maxLength: 20, digitsOnly: false, required: true })
}
function resetEmail(value: string | null = null): InputText {
  return InputText.email({ value: value, required: true })
}
function resetRightsAll(value = true): InputCheckbox {
  return InputCheckbox.minimum({ value: value })
}
function resetRightsUser(value: boolean | null = null): InputCheckbox {
  return InputCheckbox.minimum({ value: value })
}
function resetRightsDepartment(value: boolean | null = null): InputCheckbox {
  return InputCheckbox.minimum({ value: value })
}
function resetRightsCase(value: boolean | null = null): InputCheckbox {
  return InputCheckbox.minimum({ value: value })
}
function resetReportSettlement(value = true): InputCheckbox {
  return InputCheckbox.minimum({ value: value })
}
function resetReportInvoiceSpecification(value = true): InputCheckbox {
  return InputCheckbox.minimum({ value: value })
}

export const state: CreditorUsersState = {
  alert: undefined,
  creditorUsers: undefined,
  currentCreditorUser: undefined,

  personalNumber: resetPersonalNumber(),
  firstName: resetFirstName(),
  lastName: resetLastName(),
  mobilePhone: resetMobilePhone(),
  email: resetEmail(),
  rightsAll: resetRightsAll(),
  rightsUser: resetRightsUser(),
  rightsDepartment: resetRightsDepartment(),
  rightsCase: resetRightsCase(),
  reportSettlement: resetReportSettlement(),
  reportInvoiceSpecification: resetReportInvoiceSpecification(),
}

const namespaced = true

export const getters: GetterTree<CreditorUsersState, RootState> = {
  getAlert(state): Alert | undefined {
    return state.alert
  },
  getCreditorUsers(state): Page<CreditorUser> | undefined {
    return state.creditorUsers
  },
  getCurrentCreditorUser(state): CreditorUser | undefined {
    return state.currentCreditorUser
  },
  getCurrentEtag(state): string | undefined {
    return state.currentEtag
  },
  getCreatedCreditorUserId(state): string | undefined {
    return state.createdCreditorUserId
  },

  getPersonalNumber(state): InputText {
    return state.personalNumber
  },
  getFirstName(state): InputText {
    return state.firstName
  },
  getLastName(state): InputText {
    return state.lastName
  },
  getMobilePhone(state): InputText {
    return state.mobilePhone
  },
  getEmail(state): InputText {
    return state.email
  },
  getRightsAll(state): InputCheckbox {
    return state.rightsAll
  },
  getRightsUser(state): InputCheckbox {
    return state.rightsUser
  },
  getRightsDepartment(state): InputCheckbox {
    return state.rightsDepartment
  },
  getRightsCase(state): InputCheckbox {
    return state.rightsCase
  },
  getReportSettlement(state): InputCheckbox {
    return state.reportSettlement
  },
  getReportInvoiceSpecification(state): InputCheckbox {
    return state.reportInvoiceSpecification
  },
}

export const mutations: MutationTree<CreditorUsersState> = {
  setAlert(state, alert: Alert | undefined) {
    state.alert = alert
  },
  setCreditorUsers(state, creditorUsers: Page<CreditorUser>) {
    state.creditorUsers = creditorUsers
  },
  setCurrentCreditorUser(state, creditorUser?: CreditorUser) {
    state.currentCreditorUser = creditorUser

    state.personalNumber = resetPersonalNumber(creditorUser?.personalNumber)
    state.firstName = resetFirstName(creditorUser?.firstName)
    state.lastName = resetLastName(creditorUser?.lastName)
    state.mobilePhone = resetMobilePhone(creditorUser?.mobilePhone)
    state.email = resetEmail(creditorUser?.email)
    state.rightsAll = resetRightsAll(creditorUser?.administrationRights.includes('full_administration_rights') ?? false)
    state.rightsUser = resetRightsUser(creditorUser?.administrationRights.includes('user_administration_rights'))
    state.rightsDepartment = resetRightsDepartment(creditorUser?.administrationRights.includes('department_administration_rights'))
    state.rightsCase = resetRightsCase(creditorUser?.administrationRights.includes('case_administration_rights'))
    state.reportSettlement = resetReportSettlement(creditorUser?.reportSettlement ?? false)
    state.reportInvoiceSpecification = resetReportSettlement(creditorUser?.reportInvoiceSpecification ?? false)
  },
  setCurrentEtag(state, currentEtag?: string) {
    state.currentEtag = currentEtag
  },
  setCreatedCreditorUserId(state, createdCreditorUserId?: string) {
    state.createdCreditorUserId = createdCreditorUserId
  },

  setPersonalNumber(state, personalNumber: InputText) {
    state.personalNumber = personalNumber
  },
  setFirstName(state, firstName: InputText) {
    state.firstName = firstName
  },
  setLastName(state, lastName: InputText) {
    state.lastName = lastName
  },
  setMobilePhone(state, mobilePhone: InputText) {
    state.mobilePhone = mobilePhone
  },
  setEmail(state, email: InputText) {
    state.email = email
  },
  setRightsAll(state, rightsAll: InputCheckbox) {
    state.rightsAll = rightsAll
    if (state.rightsAll.value) {
      state.rightsUser.value = true
      state.rightsDepartment.value = true
      state.rightsCase.value = true
    } else if (state.rightsUser.value && state.rightsCase.value && state.rightsDepartment.value) {
      state.rightsUser.value = false
      state.rightsDepartment.value = false
      state.rightsCase.value = false
    }
  },
  setRightsUser(state, rightsUser: InputCheckbox) {
    state.rightsUser = rightsUser
    state.rightsAll.value = state.rightsUser.value && state.rightsCase.value && state.rightsDepartment.value
  },
  setRightsDepartment(state, rightsDepartment: InputCheckbox) {
    state.rightsDepartment = rightsDepartment
    state.rightsAll.value = state.rightsDepartment.value && state.rightsCase.value && state.rightsUser.value
  },
  setRightsCase(state, rightsCase: InputCheckbox) {
    state.rightsCase = rightsCase
    state.rightsAll.value = state.rightsCase.value && state.rightsDepartment.value && state.rightsUser.value
  },
  setReportSettlement(state, reportSettlement: InputCheckbox) {
    state.reportSettlement = reportSettlement
  },
  setReportInvoiceSpecification(state, reportInvoiceSpecification: InputCheckbox) {
    state.reportInvoiceSpecification = reportInvoiceSpecification
  },
}

export const actions: ActionTree<CreditorUsersState, RootState> = {
  /**
   * Refreshes a page of creditor users
   *
   * @param store The vuex store.
   */
  async refreshCreditorUsers(store, page: number): Promise<Page<CreditorUser> | APIError> {
    console.log(`REFRESHING CREDITOR USERS ${page} START`)

    // Build pagination from requested page
    const pagination = new Pagination(20, page)

    const request = <APIRequest> {
      method: 'get',
      path: '/creditor-users',
      action: 'creditorUsers/recieveCreditorUsersGetModels',
      mock: mock.mockCreditorUsersGetModel(pagination),
      paging: pagination,
    }
    const result: true | APIError = await store.dispatch('creditorsAPI/call', request)
    if (result !== true) {
      apiError(store, result)
      return result
    }
    return store.getters.getCreditorUsers
  },

  async recieveCreditorUsersGetModels(store, page: PageModel<CreditorUsersGetModel>) {
    const creditorUserId: number | undefined = store.rootGetters['authentications/getCreditorUserId']
    const creditorUsers: Page<CreditorUser> = Page.transform(page, ((it) => <CreditorUser> {
      creditorUserId: it.creditor_user_id,
      creditorId: it.creditor_id,
      personalNumber: it.personal_id_mobile_bankid,
      firstName: it.first_name,
      lastName: it.last_name,
      fullName: it.full_name,
      email: it.email,
      userName: it.user_name,

      isCurrent: it.creditor_user_id === creditorUserId,
      
      mobilePhone: undefined,
      reportSettlement: undefined,
      reportInvoiceSpecification: undefined,
      administrationRights: [],
    }))
    store.commit('setCreditorUsers', creditorUsers)
    console.log('REFRESHING CREDITOR USERS END')
  },

  /**
   * Retrieves the details for the provided creditor user
   *
   * @param store The vuex store.
   */
  async retrieveCreditorUserDetails(store, creditorUserId: number): Promise<CreditorUser | APIError> {
    console.log('RETRIEVING CREDITOR USER')

    const request = <APIRequest> {
      method: 'get',
      path: `/creditor-users/${creditorUserId}`,
      action: 'creditorUsers/recieveCreditorUsersDetailsModel',
      mock: mock.mockCreditorUsersDetailsModel(undefined),
    }
    const result: true | APIError = await store.dispatch('creditorsAPI/call', request)
    if (result !== true) {
      apiError(store, result)
      return result
    }

    const { currentCreditorUser } = store.state
    if (currentCreditorUser === undefined) {
      return missingError('currentCreditorUser is missing')
    }

    return currentCreditorUser
  },

  /**
   * Retrieves the details for the current creditor user
   *
   * @param store The vuex store.
   */
  async refreshCurrentCreditorUserDetails(store): Promise<true | APIError> {
    console.log('REFRESHING CREDITOR USER')
    const current: CreditorUser | undefined = store.getters.getCurrentCreditorUser

    if (current === undefined) return true

    const request = <APIRequest> {
      method: 'get',
      path: `/creditor-users/${current?.creditorUserId}`,
      action: 'creditorUsers/recieveCreditorUsersDetailsModel',
      mock: mock.mockCreditorUsersDetailsModel(current),
    }
    const result: true | APIError = await store.dispatch('creditorsAPI/call', request)
    if (result !== true) {
      apiError(store, result)
      return result
    }

    return true
  },

  async recieveCreditorUsersDetailsModel(store, details: CreditorUsersDetailsModel) {
    const creditorUserId: number | undefined = store.rootGetters['authentications/getCreditorUserId']
    const creditorUser: CreditorUser = {
      creditorUserId: details.creditor_user_id,
      creditorId: details.creditor_id,
      personalNumber: details.personal_id_mobile_bankid,
      fullName: details.full_name,
      firstName: details.first_name,
      lastName: details.last_name,
      email: details.email,
      userName: details.user_name,

      isCurrent: details.creditor_user_id === creditorUserId,

      mobilePhone: details.mobile_phone,
      reportSettlement: details.send_settlement_report,
      reportInvoiceSpecification: details.send_invoice_specification,
      administrationRights: details.administration_rights,
    }

    store.commit('setCurrentCreditorUser', creditorUser)
    store.commit('setCurrentEtag', details._etag)
  },

  /**
   * Creates a new creditor user
   *
   * @param store The vuex store.
   */
  async createCreditorUser(store): Promise<true | APIError> {
    // Make 10 digit be 12 instead
    var value = state.personalNumber.toPersonalNumber()
    store.commit('setPersonalNumber', resetPersonalNumber(value))

    console.log('CREATE CREDITOR USER')

    const currentCreditorId = store.rootGetters.getCurrentCreditorId

    const administration_rights: AdministrationRights[] = []
    if (state.rightsAll.value) { administration_rights.push('full_administration_rights') }
    if (state.rightsUser.value) { administration_rights.push('user_administration_rights') }
    if (state.rightsDepartment.value) { administration_rights.push('department_administration_rights') }
    if (state.rightsCase.value) { administration_rights.push('case_administration_rights') }

    const data: CreditorUsersPostModel[] = [{
      creditor_id: currentCreditorId,

      personal_id_mobile_bankid: state.personalNumber.toString(),
      full_name: `${state.firstName.toString()} ${state.lastName.toString()}`,
      first_name: state.firstName.toString(),
      last_name: state.lastName.toString(),
      mobile_phone: state.mobilePhone.toString(),
      email: state.email.toString(),

      description: '',
      no_messages: false,
      send_settlement_report: state.reportSettlement.value,
      send_invoice_specification: state.reportInvoiceSpecification.value,
      unique_identifier: '',
      administration_rights: administration_rights,
    }]

    const request: APIRequest = {
      method: 'post',
      path: '/creditor-users',
      data,
      action: 'creditorUsers/recieveCreditorUsersPostResponseModel',
      mock: mock.mockCreditorUsersPostResponseModel(data),
    }
    const result: true | APIError = await store.dispatch('creditorsAPI/call', request)
    if (result !== true) {
      apiError(store, result)
      return result
    }

    const { createdCreditorUserId } = store.state
    const creditorDepartments: CreditorDepartment[] = store.rootGetters['creditorDepartments/getAllCreditorDepartments']
    const creditorDepartmentIds = creditorDepartments.map((it) => it.creditorDepartmentId)
    await store.dispatch('creditorUserDepartmentAccesses/createCreditorUserDepartmentAccesses', { creditorUserId: createdCreditorUserId, creditorDepartmentIds: creditorDepartmentIds }, { root: true })

    return true
  },

  /**
   * Recieves a response for creating a new creditor user
   *
   * @param store The vuex store.
   * @param response The model for creditor user creation.
   */
  async recieveCreditorUsersPostResponseModel(store, models: CreditorUsersPostResponseModel[]) {
    const model = models[0]
    store.commit('setCreatedCreditorUserId', model.creditor_user_id)
    store.commit('setCurrentEtag', model._etag)
  },

  /**
   * Edits a creditor user
   *
   * @param store The vuex store.
   */
  async editCreditorUser(store): Promise<true | APIError> {
    // Make 10 digit be 12 instead
    var value = state.personalNumber.toPersonalNumber()
    store.commit('setPersonalNumber', resetPersonalNumber(value))

    console.log('EDIT CREDITOR USER')
    const current = store.state.currentCreditorUser

    if (current === undefined) return missingError('currentCreditorUser')

    const administration_rights: AdministrationRights[] = []
    if (state.rightsAll.value) { administration_rights.push('full_administration_rights') }
    if (state.rightsUser.value) { administration_rights.push('user_administration_rights') }
    if (state.rightsDepartment.value) { administration_rights.push('department_administration_rights') }
    if (state.rightsCase.value) { administration_rights.push('case_administration_rights') }

    // Create a PATCH body
    const data = <CreditorUsersPatchModel> {
      personal_id_mobile_bankid: state.personalNumber.toString(),
      full_name: `${state.firstName.toString()} ${state.lastName.toString()}`,
      first_name: state.firstName.toString(),
      last_name: state.lastName.toString(),
      mobile_phone: state.mobilePhone.toString(),
      email: state.email.toString(),
      send_settlement_report: state.reportSettlement.value,
      send_invoice_specification: state.reportInvoiceSpecification.value,
      administration_rights: administration_rights,
    }

    // Invoke API
    const request = <APIRequest> {
      method: 'patch',
      path: `/creditor-users/${current.creditorUserId}`,
      data: data,
      _etag: store.state.currentEtag,
      action: 'creditorUsers/recieveCreditorUsersPatchModel',
      mock: mock.mockCreditorUsersPatchResponseModel(current.creditorUserId, data),
    }
    const result: true | APIError = await store.dispatch('creditorsAPI/call', request)
    if (result !== true) {
      apiError(store, result)
      return result
    }

    return true
  },

  /**
   * Recieves a response for editing a creditor user
   *
   * @param store The vuex store.
   * @param response The model for creditor user update.
   */
  async recieveCreditorUsersPatchModel(store, model: CreditorUsersPatchResponseModel) {
    store.commit('setCurrentEtag', model._etag)
  },

  /**
   * Removes a creditor user
   *
   * @param store The vuex store.
   */
  async removeCreditorUser(store): Promise<true | APIError> {
    console.log('REMOVE CREDITOR USER')
    const current = store.state.currentCreditorUser

    if (current === undefined) return missingError('currentCreditorUser')

    // Create a DELETE body
    const data = <CreditorUsersDeleteModel> {
      creditor_user_id: current.creditorUserId,
      _etag: store.state.currentEtag,
    }

    // Invoke API
    const request = <APIRequest> {
      method: 'delete',
      path: `/creditor-users/${current.creditorUserId}`,
      data: data,
      _etag: store.state.currentEtag,
    }
    const result: true | APIError = await store.dispatch('creditorsAPI/call', request)
    if (result !== true) {
      apiError(store, result)
      return result
    }
    return true
  },

  /**
   * Prepares the store for starting a new creditor user
   *
   * @param store The vuex store.
   */
  async startCreatingCreditorUser(store) {
    store.commit('setPersonalNumber', resetPersonalNumber())
    store.commit('setFirstName', resetFirstName())
    store.commit('setLastName', resetLastName())
    store.commit('setMobilePhone', resetMobilePhone())
    store.commit('setEmail', resetEmail())
    store.commit('setRightsAll', resetRightsAll())
    store.commit('setRightsUser', resetRightsUser())
    store.commit('setRightsDepartment', resetRightsDepartment())
    store.commit('setRightsCase', resetRightsCase())
    store.commit('setReportSettlement', resetReportSettlement())
    store.commit('setReportInvoiceSpecification', resetReportInvoiceSpecification())
  },

  async switchCurrentCreditorUserId(store, creditorUserId?: number) {
    const creditorUser = store.state.creditorUsers?.items.find((it) => it.creditorUserId === creditorUserId)
    store.commit('setCurrentCreditorUser', creditorUser)
  },
}

export const creditorUsers: Module<CreditorUsersState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations,
  modules: {
    creditorsAPI,
  },
}
