import {
  ActionTree, GetterTree, Module, MutationTree,
} from 'vuex'
import { RootState } from '@/store/index'
import {
  creditorsAPI, APIRequest, Pagination, PageModel, Page, APIError, missingError,
} from '@/store/modules/creditorsAPI'
import { CasePaymentsGetModel, CasePaymentsPostModel, CasePaymentsPostResponseModel, PaymentType, ReceiverType, translatePaymentType, translateReceiverType } from '@/models/payments'
import { Alert, apiError } from '@/models/alerts'
import { Case } from '@/store/modules/cases'
import { InputText, InputDate, InputNumber } from '@/models/validations'
import * as mock from '@/mockdata/casePayments'
import { Formatting } from '@/models/formatting'

export interface CasePayment {
  caseId: number;
  payment: string;
  paymentType: PaymentType;
  receiver: string;
  receiverType: ReceiverType;
  accountingDate: Date;
  accountingDateFormatted: string;
  referenceNumber: string;
  amount: number;
  amountFormatted: string;
  currency: string;
}

export interface CasePaymentsState {
  alert: Alert | undefined;
  casePayments?: Page<CasePayment>;

  invoiceNumber: InputText;
  accountingDate: InputDate;
  amount: InputNumber;
  paymentMessage: InputText;
}

function resetInvoiceNumber(value: string | null): InputText {
  return InputText.standard({ value, minLength: 1, maxLength: 20, required: true })
}
function resetAccountingDate(): InputDate {
  return InputDate.standard({ value: null, minValue: null, maxValue: null, required: true })
}
function resetAmount(): InputNumber {
  return InputNumber.unconstrainedAmount({ value: null, required: true })
}
function resetPaymentMessage(): InputText {
  return InputText.standard({ value: null, minLength: null, maxLength: 80, required: true })
}

export const state: CasePaymentsState = {
  alert: undefined,
  casePayments: undefined,

  invoiceNumber: resetInvoiceNumber(null),
  accountingDate: resetAccountingDate(),
  amount: resetAmount(),
  paymentMessage: resetPaymentMessage(),
}

const namespaced = true

export const getters: GetterTree<CasePaymentsState, RootState> = {
  getAlert(state): Alert | undefined {
    return state.alert
  },
  getCasePayments(state): Page<CasePayment> | undefined {
    return state.casePayments
  },
  getInvoiceNumber(state): InputText {
    return state.invoiceNumber
  },
  getAccountingDate(state): InputDate {
    return state.accountingDate
  },
  getAmount(state): InputNumber {
    return state.amount
  },
  getPaymentMessage(state): InputText {
    return state.paymentMessage
  },
}

export const mutations: MutationTree<CasePaymentsState> = {
  setAlert(state, alert: Alert | undefined) {
    state.alert = alert
  },
  setCasePayments(state, casePayments: Page<CasePayment>) {
    state.casePayments = casePayments
  },
  setInvoiceNumber(state, invoiceNumber: InputText) {
    state.invoiceNumber = invoiceNumber
  },
  setAccountingDate(state, accountingDate: InputDate) {
    state.accountingDate = accountingDate
  },
  setAmount(state, amount: InputNumber) {
    state.amount = amount
  },
  setPaymentMessage(state, paymentMessage: InputText) {
    state.paymentMessage = paymentMessage
  },
}

export const actions: ActionTree<CasePaymentsState, RootState> = {
  /**
   * Refresh the case payments entries
   *
   * @param store The vuex store.
   */
  async refreshCasePayments(store, page: number): Promise<Page<CasePayment> | APIError> {
    console.log(`REFRESHING CASE PAYMENTS ${page} START`)
    const currentCase: Case | undefined = store.rootGetters['cases/getCurrentCase']

    if (currentCase === undefined) return missingError('currentCase')

    // Build pagination from requested page
    const pagination = new Pagination(10, page)

    const request = <APIRequest> {
      method: 'get',
      path: `/case-payments?case_id=${currentCase.caseId}`,
      extra: currentCase.caseId,
      action: 'casePayments/recieveCasePaymentsGetModel',
      mock: mock.mockCasePaymentsGetModel(pagination),
      paging: pagination,
    }
    const result: true | APIError = await store.dispatch('creditorsAPI/call', request)
    if (result !== true) {
      apiError(store, result)
      return result
    }
    return store.getters.getCasePayments
  },

  /**
   * Recieves a CasePaymentsGetModel and transforms it into payments and commits them to the store
   *
   * @param store The vuex store.
   * @param model The CasePaymentsGetModel model.
   */
  async recieveCasePaymentsGetModel(store, page: PageModel<CasePaymentsGetModel>) {
    const caseId = page.extra as number

    const casePayments: Page<CasePayment> = Page.transform(page, ((it) => <CasePayment> {
      caseId,
      payment: translatePaymentType(it.payment_type),
      paymentType: it.payment_type,
      receiver: translateReceiverType(it.payment_receiver),
      receiverType: it.payment_receiver,
      accountingDate: new Date(it.accounting_date),
      accountingDateFormatted: Formatting.formatDate(new Date(it.accounting_date)),
      referenceNumber: it.reference_number,
      amount: it.amount,
      amountFormatted: Formatting.formatAmount(it.amount),
      currency: it.currency,
    }))

    store.commit('setCasePayments', casePayments)
  },

  /**
   * Registers a new case payment
   *
   * @param store The vuex store.
   */
  async createCasePayment(store): Promise<true | APIError> {
    const currentCase: Case | undefined = store.rootGetters['cases/getCurrentCase']
    console.log('CREATE CASE PAYMENT START')

    if (currentCase === undefined) return missingError('currentCase')

    const data: CasePaymentsPostModel = {
      invoice_number: state.invoiceNumber.toString(),
      accounting_date: Formatting.formatDate(state.accountingDate.toDate()),
      amount: state.amount.toNumber(),
      currency: 'SEK',
      payment_message: state.paymentMessage.toString(),
    }

    const request = <APIRequest> {
      method: 'post',
      path: `/case-payments?case_id=${currentCase.caseId}`,
      data,
      action: 'casePayments/recieveCasePaymentsPostResponseModel',
      mock: mock.mockCasePaymentsPostResponseModel(data),
    }
    const result: true | APIError = await store.dispatch('creditorsAPI/call', request)
    if (result !== true) {
      apiError(store, result)
      return result
    }
    return true
  },

  /**
   * Recieves a response for registering a new case payment
   *
   * @param store The vuex store.
   * @param response The model for case payment creation.
   */
  async recieveCasePaymentsPostResponseModel(store, model: CasePaymentsPostResponseModel) {
  },

  /**
   * Prepares the store for starting a new case payment registration
   *
   * @param store The vuex store.
   */
  async startCreatingCasePayment(store) {
    // Look-up dependent data
    const currentCase: Case | undefined = store.rootGetters['cases/getCurrentCase']
    const invoiceNumbers = currentCase?.invoiceNumbers

    if (invoiceNumbers !== undefined) store.commit('setInvoiceNumber', resetInvoiceNumber(invoiceNumbers[0]))
    else store.commit('setInvoiceNumber', resetInvoiceNumber(null))

    store.commit('setAccountingDate', resetAccountingDate())
    store.commit('setAmount', resetAmount())
    store.commit('setPaymentMessage', resetPaymentMessage())
  },
}

export const casePayments: Module<CasePaymentsState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations,
  modules: {
    creditorsAPI,
  },
}
