import { ActionTree, GetterTree, Module, MutationTree } from 'vuex'
import { RootState } from '@/store/index'
import { creditorsAPI, APIRequest, Pagination, PageModel, Page, APIError, missingError } from '@/store/modules/creditorsAPI'
import { CaseDebtorsGetModel, RoleType, translateRoleType, DebtorType, translateDebtorType } from '@/models/debtors'
import { Alert, apiError } from '@/models/alerts'
import { Case } from '@/store/modules/cases'
import * as mock from '@/mockdata/caseDebtors'
import { InputOption, InputSelect } from '@/models/validations'

export interface CaseDebtor {
  caseId: number;
  caseDebtorId: number;
  debtor: string;
  debtorType: DebtorType;
  role: string;
  roleType: RoleType;
  organisationId: string;
  name: string;
}

export interface DebtorOption {
  id: number;
  title: string;
  matches: DebtorType[];
}

const allDebtorOptions: DebtorOption[] = [
  { id: 1, title: 'Privatperson', matches: ['PRIVATE_PERSON', 'PRIVAT_PERSON'] },
  { id: 2, title: 'Aktiebolag', matches: ['COMPANY'] },
  { id: 3, title: 'Enskild firma', matches: ['PRIVATE_FIRM'] },
]

export interface CaseDebtorsState {
  alert: Alert | undefined;
  caseDebtors?: Page<CaseDebtor>;
  debtorOptions: DebtorOption[];

  debtorOption: InputSelect;
}

export function debtorTypeFromInput(select: InputSelect): DebtorType | null {
  return allDebtorOptions.find((it) => it.id === select.value)?.matches[0] ?? null
}

export function resetDebtorOption(debtorType: DebtorType | null = null): InputSelect {
  var value: number | null = allDebtorOptions[0].id
  if (debtorType !== null) {
    value = allDebtorOptions.find((it) => it.matches.includes(debtorType))?.id ?? null
  }
  const options = allDebtorOptions.map((it) => <InputOption> { value: it.id, text: it.title, data: it })
  return InputSelect.standard({ value: value, options: options })
}

export const state: CaseDebtorsState = {
  alert: undefined,
  caseDebtors: undefined,
  debtorOptions: allDebtorOptions,

  debtorOption: resetDebtorOption(),
}

const namespaced = true

export const getters: GetterTree<CaseDebtorsState, RootState> = {
  getAlert(state): Alert | undefined {
    return state.alert
  },
  getCaseDebtors(state): Page<CaseDebtor> | undefined {
    return state.caseDebtors
  },
  getMainCaseDebtor(state, getters): CaseDebtor | undefined {
    const caseDebtors: Page<CaseDebtor> | undefined = getters.getCaseDebtors
    if (caseDebtors === undefined) return undefined
    return caseDebtors.items.find((it) => it.roleType === 'MAIN_DEBTOR')
  },
  getAllDebtorOptions(state): DebtorOption[] {
    return state.debtorOptions
  },
  getDebtorOption(state): InputSelect {
    return state.debtorOption
  },
  getCurrentDebtorOption(state): DebtorOption {
    const debtorOption = allDebtorOptions.find((it) => it.id === state.debtorOption.value)
    return debtorOption ?? allDebtorOptions[0]
  },
}

export const mutations: MutationTree<CaseDebtorsState> = {
  setAlert(state, alert: Alert | undefined) {
    state.alert = alert
  },
  setCaseDebtors(state, caseDebtors: Page<CaseDebtor>) {
    state.caseDebtors = caseDebtors
  },
  setDebtorOption(state, debtorOption: InputSelect) {
    state.debtorOption = debtorOption
  },
  setCurrentDebtorType(state, debtorType: DebtorType) {
    state.debtorOption = resetDebtorOption(debtorType)
  },
  setCurrentDebtorOptionId(state, debtorOptionId: number) {
    const debtorOption = state.debtorOptions.find((it) => it.id === debtorOptionId)
    if (debtorOption !== undefined) state.debtorOption = resetDebtorOption(debtorOption.matches[0])
  },
}

export const actions: ActionTree<CaseDebtorsState, RootState> = {
  async refreshCaseDebtors(store, page: number): Promise<Page<CaseDebtor> | APIError> {
    console.log(`REFRESHING CASE DEBTORS ${page} START`)
    const currentCase: Case | undefined = store.rootGetters['cases/getCurrentCase']

    if (currentCase === undefined) return missingError('currentCase')

    // Build pagination from requested page
    const pagination = new Pagination(10, page)

    const request = <APIRequest> {
      method: 'get',
      path: `/case-debtors?case_id=${currentCase.caseId}`,
      extra: currentCase.caseId,
      action: 'caseDebtors/recieveCaseGetModel',
      mock: mock.mockCaseDebtorsGetModel(pagination),
      paging: pagination,
    }
    const result: true | APIError = await store.dispatch('creditorsAPI/call', request)
    if (result !== true) {
      apiError(store, result)
      return result
    }
    return store.getters.getCaseDebtors
  },

  async recieveCaseGetModel(store, page: PageModel<CaseDebtorsGetModel>) {
    const caseId = page.extra as number

    const caseDebtors: Page<CaseDebtor> = Page.transform(page, ((it) => <CaseDebtor> {
      caseId,
      caseDebtorId: it.case_debtor_id,
      debtor: translateDebtorType(it.type),
      debtorType: it.type,
      role: translateRoleType(it.role),
      roleType: it.role,
      organisationId: it.organisation_id,
      name: it.name,
    }))

    store.commit('setCaseDebtors', caseDebtors)
    console.log('REFRESHING CASE DEBTORS END')
  },
}

export const caseDebtors: Module<CaseDebtorsState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations,
  modules: {
    creditorsAPI,
  },
}
