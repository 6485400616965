
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'

@Component
export default class NordicCoolToast extends Vue {
  @Prop(Boolean) readonly value: boolean | undefined
  @Prop(String) readonly message: string | undefined

  get show(): number {
    return this.value === true ? 5 : 0
  }
  set show(value: number) {
    if (value === 0) {
      this.$emit('input', false)
    }
  }
}
