import { ActionTree, GetterTree, Module, MutationTree } from 'vuex'
import { RootState } from '@/store/index'
import { TokenModel, UserListTokenModel, Status, FunctionalScope } from '@/models/authenticates'
import { creditorsAPI, APIRequest, APIError, Page, isAPIError } from '@/store/modules/creditorsAPI'
import { Alert, bankidCancelled, bankidFailed, apiError } from '@/models/alerts'
import jwt_decode from 'jwt-decode'
import * as mock from '@/mockdata/authentications'
import { InputText, InputNumber } from '@/models/validations'
import { CreditorDepartment } from './creditorDepartments'

export interface UserToken {
  creditorUserId: number;
  creditorId: number;
  creditorName: string;
  firstName: string;
  lastName: string;
  fullName: string;
  email: string;
  description: string;
  status: Status;
  token: string;
  expiration: string;
  functionalScope: FunctionalScope;
}

export interface AuthenticationState {
  alert: Alert | undefined;
  firstName?: string;
  lastName?: string;
  status: Status;
  unauthorizedToken?: string;
  userTokens: UserToken[];

  personalNumber: InputText;
}

function resetPersonalNumber(value: string | null = null): InputText {
  return InputText.eitherNumberLike({ value: value, minLength: 10, maxLength: 12, digitsOnly: true, required: true })
}

export const state: AuthenticationState = {
  alert: undefined,
  firstName: undefined,
  lastName: undefined,
  status: 'pending',
  unauthorizedToken: undefined,
  userTokens: [],

  personalNumber: resetPersonalNumber(),
}

const namespaced = true

export const getters: GetterTree<AuthenticationState, RootState> = {
  getAlert(state): Alert | undefined {
    return state.alert
  },
  getPersonalNumber(state): InputText {
    return state.personalNumber
  },
  getFirstName(state): string | undefined {
    return state.firstName
  },
  getLastName(state): string | undefined {
    return state.lastName
  },
  getIsAuthorized(state): boolean {
    return state.userTokens.length > 0
  },
  getUnauthorizedToken(state): string | undefined {
    return state.unauthorizedToken
  },
  getFullName(state, getters, rootState, rootGetters): string {
    const currentId = rootGetters.getCurrentCreditorId
    const userToken = state.userTokens.find((creditor) => creditor.creditorId === currentId)
    return userToken?.fullName ?? ''
  },
  getCreditorUserId(state, getters, rootState, rootGetters): number | undefined {
    const currentId = rootGetters.getCurrentCreditorId
    const userToken = state.userTokens.find((creditor) => creditor.creditorId === currentId)
    return userToken?.creditorUserId
  },
  getCurrentUserToken(state, getters, rootState, rootGetters): UserToken | undefined {
    const currentId = rootGetters.getCurrentCreditorId
    return state.userTokens.find((creditor) => creditor.creditorId === currentId)
  },
  getAllUserTokens(state): UserToken[] {
    return state.userTokens
  },
}

export const mutations: MutationTree<AuthenticationState> = {
  setAlert(state, alert: Alert | undefined) {
    state.alert = alert
  },
  setPersonalNumber(state, personalNumber: InputText) {
    state.personalNumber = personalNumber
  },
  setFirstName(state, firstName?: string) {
    state.firstName = firstName
  },
  setLastName(state, lastName?: string) {
    state.lastName = lastName
  },
  setUnauthorizedToken(state, unauthorizedToken: string | undefined) {
    state.unauthorizedToken = unauthorizedToken
  },
  setProgress(state, progress: { status: Status; alert: Alert | undefined }) {
    state.status = progress.status
    state.alert = progress.alert
  },
  setUserTokens(state, userTokens: UserToken[]) {
    state.userTokens = userTokens
  },
}

export const actions: ActionTree<AuthenticationState, RootState> = {

  /**
   * Starts an authentication with Bank ID.
   *
   * @param store The vuex store.
   * @param response true if successful othewaise an error.
   */
  async startAuthentication(store): Promise<true | APIError> {
    const personalNumber: InputText = store.getters.getPersonalNumber
    
    // Make 10 digit be 12 instead
    const value = personalNumber.toPersonalNumber()
    console.log('personalNumber', value)
    store.commit('setPersonalNumber', resetPersonalNumber(value))

    const request = <APIRequest> {
      method: 'post',
      path: '/authenticates/mobile-bankid',
      data: { personal_number: value },
      action: 'authentications/recieveTokenModel',
      mock: mock.mockTokenModel(store),
      paging: undefined,
    }
    const result: true | APIError = await store.dispatch('creditorsAPI/call', request)
    if (result !== true) {
      apiError(store, result)
      return result
    }
    return true
  },

  async cancelAuthentication(store): Promise<true | APIError> {
    const request = <APIRequest> {
      method: 'delete',
      path: '/authenticates/mobile-bankid',
      paging: undefined,
    }
    const result: true | APIError = await store.dispatch('creditorsAPI/call', request)
    if (result !== true) {
      apiError(store, result)
      return result
    }
    return true
  },

  async recieveTokenModel(store, model: TokenModel) {
    store.commit('creditorsAPI/setToken', model.token)
  },

  /*
   *
   * @param store The vuex store
   */
  async waitAuthentication(store): Promise<true | APIError> {
    const request = <APIRequest> {
      method: 'get',
      path: '/authenticates/mobile-bankid',
      action: 'authentications/recieveUserListTokenModel',
      mock: mock.mockUserListTokenModelComplete(store),
      paging: undefined,
    }
    const result: true | APIError = await store.dispatch('creditorsAPI/call', request)
    if (result !== true) {
      apiError(store, result)
      return result
    }
    return true
  },

  /*
   * Renews an authentication after changes has been made that affects the user model.
   *
   * @param store The vuex store
   */
  async renewAuthentication(store): Promise<true | APIError> {
    console.log('RENEW AUTHENTICATION')

    const request: APIRequest = {
      method: 'get',
      path: '/authenticates/re-authenticate',
      action: 'authentications/recieveUserListTokenModel',
      mock: mock.mockUserListTokenModelComplete(store),
    }

    const result: true | APIError = await store.dispatch('creditorsAPI/call', request)
    if (result !== true) {
      apiError(store, result, '/')
      return result
    }
    return true
  },

  async recieveUserListTokenModel(store, model: UserListTokenModel) {
    console.log(`STATUS RECEIVED: ${model.status}`)

    const userTokens = model.token_list?.map((it) => <UserToken> {
      creditorUserId: it.user.creditor_user_id,
      creditorId: it.user.creditor_id,
      creditorName: it.user.creditor_name,
      firstName: it.user.first_name,
      lastName: it.user.last_name,
      fullName: it.user.full_name,
      email: it.user.email,
      description: it.user.description,
      status: it.user_token.status,
      token: it.user_token.token,
      expiration: it.user_token.expiration,
      functionalScope: (jwt_decode(it.user_token.token) as any).functional_scope,
    })

    let creditorId: number | undefined
    if (userTokens !== undefined && userTokens.length) {
      creditorId = userTokens[0].creditorId
    }

    let result: true | APIError = true
    const unauthorizedToken: string | undefined = model.unauthorized_token?.token ?? undefined

    switch (model.status) {
      case 'pending':
        break
      case 'cancelled':
        store.commit('setProgress', { status: model.status, alert: bankidCancelled() })
        break
      case 'failed':
        store.commit('setProgress', { status: model.status, alert: bankidFailed() })
        break
      case 'complete':
        store.commit('setProgress', { status: model.status, alert: undefined })
        store.commit('setUnauthorizedToken', unauthorizedToken)
        store.commit('setUserTokens', userTokens)
        result = await store.dispatch('switchCurrentCreditor', creditorId)
        if (result !== true) {
          apiError(store, result)
        }
        break
    }
  },

  /**
   * Called when switching UserToken/Creditor to signal that the store should be
   * updated for a different workspace. We also preload data that are more of a
   * static nature.
   *
   * @param store The vuex store.
   * @param response The creditor identifier of the new selection.
   */
  async switchCurrentCreditor(store, creditorId: number): Promise<true | APIError> {
    // Look-up the corresponding user token in the store
    const userToken = store.state.userTokens.find((creditor) => creditor.creditorId === creditorId)
    let token = userToken?.token

    // If user token is missing, try unauthorized token
    if (token === undefined) {
      token = store.state.unauthorizedToken
    }

    // Update base data such as API token and the current creditor id
    store.commit('creditorsAPI/setToken', token)
    store.commit('setCurrentCreditorId', creditorId, { root: true })
    store.commit('setCurrentFunctionalScope', userToken?.functionalScope, { root: true })

    // Normally we get personalNumber, first and last name from the user token list, but in the case
    // of new signups the user token list is empty. In order to pre-fill these values in the signup
    // form we extract them from the token directly
    if (store.state.unauthorizedToken !== undefined) {
      const decoded = jwt_decode(store.state.unauthorizedToken!!) as any
      store.commit('setPersonalNumber', resetPersonalNumber(decoded.mb_id))
      store.commit('setFirstName', decoded.first_name)
      store.commit('setLastName', decoded.last_name)
    } else {
      // Look-up the corresponding user token in the store
      const userToken = store.state.userTokens.find((creditor) => creditor.creditorId === creditorId)
      const decoded = jwt_decode(userToken?.token!!) as any
      store.commit('setPersonalNumber', resetPersonalNumber(decoded.mb_id))
      store.commit('setFirstName', decoded.first_name)
      store.commit('setLastName', decoded.last_name)
    }

    // Dispatch actions to preload static data, but only if we are really logged in
    if (userToken !== undefined) {
      const result1: Page<CreditorDepartment> | APIError = await store.dispatch('creditorDepartments/refreshCreditorDepartments', 1, { root: true })
      if (isAPIError(result1)) {
        apiError(store, result1)
        return result1
      }
    }

    return true
  },

  /**
   * Prepares the store for starting a new login
   *
   * @param store The vuex store.
   */
  async startLogin(store) {
    store.commit('setPersonalNumber', resetPersonalNumber())
  },

  /*
   * Refreshes an unauthorized token.
   *
   * @param store The vuex store
   */
  async refreshUnauthorizedToken(store): Promise<true | APIError> {
    console.log('REFRESH TOKEN UNAUTHORIZED')

    const request: APIRequest = {
      method: 'get',
      path: '/refresh-token/unauthorized',
      action: 'authentications/recieveUserListTokenModel',
      mock: mock.mockUserListTokenModelComplete(store),
    }

    const result: true | APIError = await store.dispatch('creditorsAPI/call', request)
    if (result !== true) {
      apiError(store, result, '/')
      return result
    }
    return true
  },

  /*
   * Refreshes an authorized token.
   *
   * @param store The vuex store
   */
  async refreshToken(store): Promise<true | APIError> {
    console.log('REFRESH TOKEN')

    const request: APIRequest = {
      method: 'get',
      path: '/refresh-token/authorized',
      action: 'authentications/recieveTokenModel',
      mock: mock.mockTokenModel(store),
    }

    const result: true | APIError = await store.dispatch('creditorsAPI/call', request)
    if (result !== true) {
      apiError(store, result, '/')
      return result
    }
    return true
  },
}

export const authentications: Module<AuthenticationState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations,
  modules: {
    creditorsAPI,
  },
}
