import i18n from '@/plugins/i18n'

export type DebtorType = 'PRIVATE_FIRM' | 'PRIVATE_PERSON' | 'PRIVAT_PERSON' | 'COMPANY'
export function translateDebtorType(debtor: DebtorType): string {
  switch (debtor) {
    case 'PRIVAT_PERSON': return i18n.tc('models.debtor.PRIVATE_PERSON')
    case 'PRIVATE_PERSON': return i18n.tc('models.debtor.PRIVATE_PERSON')
    case 'PRIVATE_FIRM': return i18n.tc('models.debtor.PRIVATE_FIRM')
    case 'COMPANY': return i18n.tc('models.debtor.COMPANY')
    default: return ''
  }
}

export type RoleType = 'MAIN_DEBTOR' | 'APPLICANT' | 'GUARANTOR'
export function translateRoleType(role: RoleType): string {
  switch (role) {
    case 'MAIN_DEBTOR': return i18n.tc('models.role.MAIN_DEBTOR')
    case 'APPLICANT': return i18n.tc('models.role.APPLICANT')
    case 'GUARANTOR': return i18n.tc('models.role.GUARANTOR')
    default: return ''
  }
}

export type CompanyType = 'SWEDISH_CORPORATION' | 'SWEDISH_COMPANY' | 'OTHER'

export interface CaseDebtorsGetModel {
  case_debtor_id: number | null; // Case debtor identifier'
  type: DebtorType; // Type of debtor,
  role: RoleType; // Role of debtor
  organisation_id: string | null; // Identification number of a person or organisation number of company
  name: string | null; // Name of person or Company
}

export interface CaseDebtorsPostModel {
  debtor_role: RoleType; // Role of debtor
  debtor_type: DebtorType; // Type of debtor
  organisation_id_type?: CompanyType; // dentification type for corporation, a company or person
  organisation_id?: string; // Identification number of a person or organisation number of company, max_length=16
  name: string; // Name of person or Company, max_length=50
  address_line_co?: string; // c/o address, max_length=35
  address_line_main: string; // Main address, max_length=35
  address_line_extra?: string; // Extra address line, for use with example foreign address, max_length=35
  postal_code: number; // Postal code, max_length=5
  city: string; // City, max_length=50
  country_code: string; // Country code according to ISO 3166. Values other than SE will be handled and follow process for foreign debt collection, max_length=2
  phone?: string; // Phone number, max_length=20
  mobile?: string; // Mobile number, max_length=20,
  email?: string; // Email, max_length=199,
  language_code?: string; // Language code used for communication with debtor according to ISO 639-2 Code, min_length=3, max_length=3
  gln?: string; // Global Location Number used for debtor
}
