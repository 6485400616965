import i18n from '@/plugins/i18n'
import { APIError } from '@/store/modules/creditorsAPI'

export enum Severity {
  Warning,
  Error,
  Information
}

export interface Alert {
  severity: Severity;
  title: string;
  message: string;
  cancel?: string;
}

export function applicationError(store: any, message: string): boolean {
  const alert = <Alert> {
    severity: Severity.Error,
    title: i18n.tc('common.application.error'),
    message,
  }
  store.commit('setAlert', alert)
  return false
}

export function apiError(store: any, error: APIError, cancel?: string): boolean {
  let message = ''
  const errors = error?.errors
  if (errors !== undefined) {
    message = Object.entries(errors).map(
      ([key, value]) => `${key}:${value}`,
    ).join(', ')
  }
  const alert = <Alert> {
    severity: Severity.Error,
    title: error?.message ?? i18n.tc('common.api.error'),
    message: message,
    cancel: cancel,
  }
  store.commit('setAlert', alert)
  return false
}

export function taxCodeRequired(cancel?: string): Alert {
  return <Alert> {
    severity: Severity.Error,
    title: i18n.tc('onboarding.ftax.required.title'),
    message: i18n.tc('onboarding.ftax.required.message'),
    cancel: cancel,
  }
}

export function taxCodeUnavailable(cancel?: string): Alert {
  return <Alert> {
    severity: Severity.Error,
    title: i18n.tc('onboarding.ftax.unavailable.title'),
    message: i18n.tc('onboarding.ftax.unavailable.message'),
    cancel: cancel,
  }
}

export function bankidCancelled(): Alert {
  return <Alert> {
    severity: Severity.Warning,
    title: i18n.tc('onboarding.bankid.cancelled.title'),
    message: i18n.tc('onboarding.bankid.cancelled.message'),
    cancel: '/',
  }
}

export function bankidFailed(): Alert {
  return <Alert> {
    severity: Severity.Error,
    title: i18n.tc('onboarding.bankid.failed.title'),
    message: i18n.tc('onboarding.bankid.failed.message'),
    cancel: '/',
  }
}

export function importValidationFailed(invoiceNumber: string): Alert {
  return <Alert> {
    severity: Severity.Error,
    title: 'Importfel',
    message: `Fakturan ${invoiceNumber} innehåller fel. Rätta felen eller avmarkera den fakuran`,
  }
}
