
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { InputText, validInputText, placeholderInputText, filteredInputText } from '@/models/validations'

@Component
export default class NordicCoolInputPhone extends Vue {
  @Prop(String) readonly id: string | undefined
  @Prop(String) readonly label: string | undefined
  @Prop(Object) readonly value: InputText | undefined
  @Prop(Boolean) readonly disabled: boolean | undefined
  @Prop(Boolean) readonly strict: boolean | undefined
  @Prop({ type: Function, default: undefined }) readonly reset!: () => void

  get placeholder(): string {
    if (this.disabled === true) return ''
    if (this.value === undefined) return ''
    return placeholderInputText(this.value)
  }

  get state(): true | false | null {
    if (this.disabled === true) return null
    if (this.value === undefined) {
      return null
    }
    return validInputText(this.value, this.strict ?? false)
  }

  get localValue(): string {
    if (this.value === undefined) {
      return ''
    }
    return this.value.value ?? ''
  }

  set localValue(localValue: string) {
    if (this.reset !== undefined) this.reset()
    if (this.value !== undefined) {
      const input = this.value
      input.value = filteredInputText(input, localValue)
      this.$emit('input', input)
    }
  }
}
