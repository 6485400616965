import Vue from 'vue'
import Vuex, { Store, StoreOptions, MutationTree } from 'vuex'

import { authentications } from '@/store/modules/authentications'
import { caseActions } from '@/store/modules/caseActions'
import { caseCosts } from '@/store/modules/caseCosts'
import { caseDebtors } from '@/store/modules/caseDebtors'
import { caseDebts } from '@/store/modules/caseDebts'
import { caseDocuments } from '@/store/modules/caseDocuments'
import { caseHistoryLogs } from '@/store/modules/caseHistoryLogs'
import { caseMessages } from '@/store/modules/caseMessages'
import { casePayments } from '@/store/modules/casePayments'
import { cases } from '@/store/modules/cases'
import { creditorDepartments } from '@/store/modules/creditorDepartments'
import { creditors } from '@/store/modules/creditors'
import { creditorsAPI } from '@/store/modules/creditorsAPI'
import { creditorUserDepartmentAccesses } from '@/store/modules/creditorUserDepartmentAccesses'
import { creditorUserMessageGroups } from '@/store/modules/creditorUserMessageGroups'
import { creditorUserMessages } from '@/store/modules/creditorUserMessages'
import { creditorUserMessageSubjects } from '@/store/modules/creditorUserMessageSubjects'
import { creditorUsers } from '@/store/modules/creditorUsers'
import { dashboards } from '@/store/modules/dashboards'
import { navigation } from '@/store/modules/navigation'
import { FunctionalScope } from '@/models/authenticates'

Vue.use(Vuex)

export interface RootState {
  version: number;
  currentCreditorId?: number;
  currentFunctionalScope?: FunctionalScope;
}

const store: StoreOptions<RootState> = {
  state: {
    version: Math.floor(Math.random() * 101),
    currentCreditorId: undefined,
    currentFunctionalScope: undefined,
  },
  getters: {
    getCurrentCreditorId(state): number | undefined {
      return state.currentCreditorId
    },
    getCurrentFunctionalScope(state): FunctionalScope | undefined {
      return state.currentFunctionalScope
    },
  },
  mutations: {
    setCurrentCreditorId(state, creditorId?: number) {
      state.currentCreditorId = creditorId
    },
    setCurrentFunctionalScope(state, functionalScope?: FunctionalScope) {
      state.currentFunctionalScope = functionalScope
    },
  },
  modules: {
    authentications,
    caseActions,
    caseCosts,
    caseDebtors,
    caseDebts,
    caseDocuments,
    caseHistoryLogs,
    caseMessages,
    casePayments,
    cases,
    creditorDepartments,
    creditors,
    creditorsAPI,
    creditorUserDepartmentAccesses,
    creditorUserMessageGroups,
    creditorUserMessages,
    creditorUserMessageSubjects,
    creditorUsers,
    dashboards,
    navigation,
  },
  plugins: [
  ],
}

export default new Vuex.Store<RootState>(store)
