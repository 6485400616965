import i18n from '@/plugins/i18n'

export class InputText {
  value: string | null
  minLength: number | null
  maxLength: number | null
  rangeIsEither: boolean
  numberLike: boolean
  digitsOnly: boolean
  required: boolean
  expression: RegExp | null

  constructor(value: string | null, minLength: number | null, maxLength: number | null, rangeIsEither: boolean, numberLike: boolean, digitsOnly: boolean, required: boolean, expression: RegExp | null) {
    this.value = value
    this.minLength = minLength
    this.maxLength = maxLength
    this.rangeIsEither = rangeIsEither
    this.numberLike = numberLike
    this.digitsOnly = digitsOnly
    this.required = required
    this.expression = expression
  }

  get isBlank(): boolean {
    if (this.value === null) return true
    if (this.value.length === 0) return true
    return false
  }

  static minimum({ value }: { value: string | null }): InputText {
    return new InputText(value, null, null, false, false, false, false, null)
  }

  static unconstrained({ value, required }: { value: string | null, required: boolean }): InputText {
    return new InputText(value, null, null, false, false, false, required, null)
  }

  static standard({ value, minLength, maxLength, required }: { value: string | null, minLength: number | null, maxLength: number | null, required: boolean }): InputText {
    return new InputText(value, minLength, maxLength, false, false, false, required, null)
  }

  static either({ value, minLength, maxLength, required }: { value: string | null, minLength: number | null, maxLength: number | null, required: boolean }): InputText {
    return new InputText(value, minLength, maxLength, true, false, false, required, null)
  }

  static numberLike({ value, minLength, maxLength, digitsOnly, required }: { value: string | null, minLength: number | null, maxLength: number | null, digitsOnly: boolean, required: boolean }): InputText {
    return new InputText(value, minLength, maxLength, false, true, digitsOnly, required, null)
  }

  static eitherNumberLike({ value, minLength, maxLength, digitsOnly, required }: { value: string | null, minLength: number | null, maxLength: number | null, digitsOnly: boolean, required: boolean }): InputText {
    return new InputText(value, minLength, maxLength, true, true, digitsOnly, required, null)
  }

  static email({ value, required }: { value: string | null, required: boolean }): InputText {
    return new InputText(value, null, null, false, false, false, required, /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)
  }

  toString(): string {
    return filteredInputText(this, this.value) ?? ''
  }

  /*
   * Allow both 10 and 12 digit input, but result is always 12 digits (if successful)
   */
  toPersonalNumber(): string {
    var value = this.toString()
    if (value.length === 10) {
      const year = parseInt(value.substring(0, 2), 10)
      if (year <= 15) {
        value = `20${value}`
      } else {
        value = `19${value}`
      }
    }
    return value
  }

  /*
   * Allow both 10 and 12 digit input, but result is always 10 digits (if successful)
   */
  toOrganisationNumber(): string {
    var value = this.toPersonalNumber()
    if (value.length === 12) {
      return value.substring(2, 12)
    }
    return value
  }

  isCompany(): boolean {
    var value = this.toOrganisationNumber()
    if (value.length === 10) {
      const month = parseInt(value.substring(2, 4), 10)
      if (month >= 20) {
        return true
      }
    }
    return false
  }
}

export class InputNumber {
  value: number | null
  minValue: number | null
  maxValue: number | null
  rangeIsLength: boolean
  rangeIsEither: boolean
  zeroIsBlank: boolean
  numberIsAmount: boolean
  required: boolean

  constructor(value: number | null, minValue: number | null, maxValue: number | null, rangeIsLength: boolean, rangeIsEither: boolean, zeroIsBlank: boolean, numberIsAmount: boolean, required: boolean) {
    this.value = value
    this.minValue = minValue
    this.maxValue = maxValue
    this.rangeIsLength = rangeIsLength
    this.rangeIsEither = rangeIsEither
    this.zeroIsBlank = zeroIsBlank
    this.numberIsAmount = numberIsAmount
    this.required = required
  }

  get isBlank(): boolean {
    if (this.value === null) return true
    if (this.value === 0 && this.zeroIsBlank) return true
    return false
  }

  static minimum({ value }: { value: number | null }): InputNumber {
    return new InputNumber(value, null, null, false, false, false, false, false)
  }

  static zeroIsBlank({ value }: { value: number | null }): InputNumber {
    return new InputNumber(value, null, null, false, false, true, false, false)
  }

  static unconstrained({ value, required }: { value: number | null, required: boolean }): InputNumber {
    return new InputNumber(value, null, null, false, false, false, false, required)
  }

  static unconstrainedAmount({ value, required }: { value: number | null, required: boolean }): InputNumber {
    return new InputNumber(value, null, null, false, false, false, true, required)
  }

  static valueInRange({ value, minValue, maxValue, required }: { value: number | null, minValue: number | null, maxValue: number | null, required: boolean }): InputNumber {
    return new InputNumber(value, minValue, maxValue, false, false, true, false, required)
  }

  static amountInRange({ value, minValue, maxValue, required }: { value: number | null, minValue: number | null, maxValue: number | null, required: boolean }): InputNumber {
    return new InputNumber(value, minValue, maxValue, false, false, true, true, required)
  }

  static lengthInRange({ value, minLength, maxLength, required }: { value: number | null, minLength: number | null, maxLength: number | null, required: boolean }): InputNumber {
    return new InputNumber(value, minLength, maxLength, true, false, true, false, required)
  }

  static eitherValues({ value, minValue, maxValue, required }: { value: number | null, minValue: number | null, maxValue: number | null, required: boolean }): InputNumber {
    return new InputNumber(value, minValue, maxValue, false, true, true, false, required)
  }

  static eitherLengths({ value, minLength, maxLength, required }: { value: number | null, minLength: number | null, maxLength: number | null, required: boolean }): InputNumber {
    return new InputNumber(value, minLength, maxLength, true, true, true, false, required)
  }

  toNumber(): number {
    return this.value ?? 0
  }

  toString(): string {
    return this.value?.toString() ?? ''
  }
}

export class InputDate {
  value: Date | null
  minValue: Date | null
  maxValue: Date | null
  required: boolean
  additional: ((value: Date) => boolean) | null

  constructor(value: Date | null, minValue: Date | null, maxValue: Date | null, required: boolean, additional: ((value: Date) => boolean) | null) {
    this.value = value
    this.minValue = minValue
    this.maxValue = maxValue
    this.required = required
    this.additional = additional
  }

  static minimum({ value }: { value: Date | null }): InputDate {
    return new InputDate(value, null, null, false, null)
  }

  static unconstrained({ value, required }: { value: Date | null, required: boolean }): InputDate {
    return new InputDate(value, null, null, required, null)
  }

  static standard({ value, minValue, maxValue, required }: { value: Date | null, minValue: Date | null, maxValue: Date | null, required: boolean }): InputDate {
    return new InputDate(value, minValue, maxValue, required, null)
  }

  static additional({ value, minValue, maxValue, required, additional }: { value: Date | null, minValue: Date | null, maxValue: Date | null, required: boolean, additional: (value: Date) => boolean }): InputDate {
    return new InputDate(value, minValue, maxValue, required, additional)
  }

  toDate(): Date {
    return this.value ?? new Date()
  }
}

export class InputFile {
  value: File | null
  required: boolean

  constructor(value: File | null, required: boolean) {
    this.value = value
    this.required = required
  }

  static minimum({ value }: { value: File | null }): InputFile {
    return new InputFile(value, false)
  }

  static standard({ value, required }: { value: File | null, required: boolean }): InputFile {
    return new InputFile(value, required)
  }
}

export class InputCheckbox {
  value: boolean | null
  requiredTrue: boolean

  constructor(value: boolean | null, requiredTrue: boolean) {
    this.value = value
    this.requiredTrue = requiredTrue
  }

  static minimum({ value }: { value: boolean | null }): InputCheckbox {
    return new InputCheckbox(value, false)
  }

  static standard({ value, requiredTrue }: { value: boolean | null, requiredTrue: boolean }): InputCheckbox {
    return new InputCheckbox(value, requiredTrue)
  }
}

export interface InputOption {
  value: number | null;
  text: string;
  data: any;
}

export class InputSelect {
  value: number | null
  options: InputOption[] | null

  get optionCount(): number { return this.options === null ? 0 : this.options.length }
  get selected(): InputOption | undefined { return this.options?.filter((it) => it.value === this.value)[0] }
  
  constructor(value: number | null, options: InputOption[] | null) {
    this.value = value
    this.options = options
  }

  static standard({ value, options }: { value: number | null, options: InputOption[] | null }): InputSelect {
    return new InputSelect(value, options)
  }
}

function isInputText(object: any): object is InputText {
  return object instanceof InputText
}

function isInputNumber(object: any): object is InputNumber {
  return object instanceof InputNumber
}

function isInputDate(object: any): object is InputDate {
  return object instanceof InputDate
}

function isInputFile(object: any): object is InputFile {
  return object instanceof InputFile
}

function isInputCheckbox(object: any): object is InputCheckbox {
  return object instanceof InputCheckbox
}

function isInputSelect(object: any): object is InputSelect {
  return object instanceof InputSelect
}

export function placeholder(input: InputText | InputNumber | InputDate | InputFile | InputCheckbox | InputSelect | undefined): string {
  let result = ''
  if (isInputText(input)) result = 'Ange text'
  else if (isInputNumber(input)) result = 'Ange siffror'
  else if (isInputDate(input)) result = 'Ange datum'
  else if (isInputFile(input)) result = i18n.tc('common.nofile')
  else if (isInputCheckbox(input)) result = ''
  else if (isInputSelect(input)) result = ''
  return result
}

export function validInputs(inputs: (InputText | InputNumber | InputDate | InputFile | InputCheckbox | InputSelect)[], strict: boolean): boolean | null {
  let result: boolean | null = true
  inputs.forEach((it) => {
    if (result === true) {
      if (isInputText(it)) result = validInputText(it, strict)
      else if (isInputNumber(it)) result = validInputNumber(it, strict)
      else if (isInputDate(it)) result = validInputDate(it, strict)
      else if (isInputFile(it)) result = validInputFile(it, strict)
      else if (isInputCheckbox(it)) result = validInputCheckbox(it, strict)
      else if (isInputSelect(it)) result = validInputSelect(it, strict)
    }
  })
  // console.log(`valid: ${result}`)
  return result
}

export function filteredInputText(input: InputText, text: string | null): string | null {
  const { digitsOnly } = input
  const filteredText = text?.replace(/[^0-9]+/g, '') ?? null
  return digitsOnly ? filteredText : text
}

export function validInputText(input: InputText, strict: boolean): boolean | null {
  const min = input.minLength
  const max = input.maxLength
  const { value, required, numberLike, expression } = input
  const validValue = value?.replace(/[^0-9-+ ,]+/g, '') ?? null
  const filteredValue = filteredInputText(input, value)
  const isEither = input.rangeIsEither

  const testedValue = numberLike ? filteredValue : value
  
  // console.log(`validInputText: value="${value}" => "${testedValue}", min="${min}", max="${max}", isEither="${isEither}"`)

  // FAILED

  // Required check, only in strict mode
  const checkRequired = strict && required
  if (checkRequired && (testedValue === null || testedValue.length === 0)) return false
  // Length in range check, only when isEither is off. Min is strict mode only.
  const checkLengthInRange = !isEither
  if (checkLengthInRange && strict && min !== null && testedValue !== null && testedValue.length < min) return false
  if (checkLengthInRange && max !== null && testedValue !== null && testedValue.length > max) return false
  // Length is either check, only when isEither is on. Max is non-strict mode only.
  const checkLengthIsEither = isEither
  if (checkLengthIsEither && strict && min !== null && max !== null && testedValue !== null && testedValue.length !== min && testedValue.length !== max) return false
  if (checkLengthIsEither && max !== null && testedValue !== null && testedValue.length > max) return false
  // Number like check, only when numberLike is on.
  const checkNumberLike = numberLike
  if (checkNumberLike && value !== null && value !== validValue) return false
  // Expression check, only in strict mode.
  const checkExpression = strict
  if (checkExpression && value !== null && expression !== null && !expression.test(value)) return false

  // SUCCESSFUL

  // Length in range.
  if (checkLengthInRange && strict && min !== null && max !== null && testedValue !== null && testedValue.length >= min && testedValue.length <= max) return true
  if (checkLengthInRange && strict && min !== null && max === null && testedValue !== null && testedValue.length >= min) return true
  if (checkLengthInRange && strict && min === null && max !== null && testedValue !== null && testedValue.length <= max) return true
  // Length is either.
  if (checkLengthIsEither && strict && min !== null && max !== null && testedValue !== null && (testedValue.length === min || testedValue.length === max)) return true
  // Expression check.
  if (checkExpression && strict && value !== null && expression !== null && expression.test(value)) return true
  // Unconstrained value.
  if (strict && min === null && max === null) return true
  
  // PASS
  return null
}

export function placeholderInputText(input: InputText): string {
  const min = input.minLength
  const max = input.maxLength
  const isEither = input.rangeIsEither
  if (!isEither && min !== null && max !== null) return i18n.tc('validations.text.range', undefined, { min: min, max: max })
  if (!isEither && min !== null && max === null) return i18n.tc('validations.text.min', undefined, { min: min, max: max })
  if (!isEither && min === null && max !== null) return i18n.tc('validations.text.max', undefined, { min: min, max: max })
  if (!isEither && min !== null && min === max) return i18n.tc('validations.text.exact', undefined, { min: min, max: max })
  if (isEither) return i18n.tc('validations.text.either', undefined, { min: min, max: max })
  return ''
}

export function validInputNumber(input: InputNumber, strict: boolean): boolean | null {
  const min = input.minValue
  const max = input.maxValue
  const { value, required } = input
  const stringValue = value?.toString()
  const isLength = input.rangeIsLength
  const isEither = input.rangeIsEither
      
  // console.log(`validInputNumber: value="${value}" min="${min}", max="${max}", isLength="${isLength}", isEither="${isEither}"`)

  // FAILED

  // Required check, only in strict mode
  const checkRequired = strict && required
  if (checkRequired && value === null) return false
  // Value in range, only when isLength and isEither is off. Min is strict mode only.
  const checkValueInRange = !isLength && !isEither
  if (checkValueInRange && strict && min !== null && value !== null && value < min) return false
  if (checkValueInRange && max !== null && value !== null && value > max) return false
  // Value is either, only when isLength is off and isEither is on.
  const checkValueIsEither = !isLength && isEither
  if (checkValueIsEither && strict && min !== null && max !== null && value !== null && value !== min && value !== max) return false
  // Length in range, only when isLength is on and isEither is off. Min is strict mode only.
  const checkLengthInRange = isLength && !isEither
  if (checkLengthInRange && strict && min !== null && stringValue !== undefined && stringValue.length < min) return false
  if (checkLengthInRange && max !== null && stringValue !== undefined && stringValue.length > max) return false
  // Length is either, only when isLength and isEither is on.
  const checkLengthIsEither = isLength && isEither
  if (checkLengthIsEither && strict && min !== null && max !== null && stringValue !== undefined && stringValue.length !== min && stringValue.length !== max) return false

  // SUCCESSFUL

  // Value in range.
  if (checkValueInRange && strict && min !== null && max !== null && value !== null && value >= min && value <= max) return true
  if (checkValueInRange && strict && min !== null && max === null && value !== null && value >= min) return true
  if (checkValueInRange && strict && min === null && max !== null && value !== null && value <= max) return true
  // Value is either.
  if (checkValueIsEither && strict && min !== null && max !== null && value !== null && (value === min || value === max)) return true
  // Length in range.
  if (checkLengthInRange && strict && min !== null && max !== null && stringValue !== undefined && stringValue.length >= min && stringValue.length <= max) return true
  // Length is either.
  if (checkLengthIsEither && strict && min !== null && max !== null && stringValue !== undefined && (stringValue.length === min || stringValue.length === max)) return true
  // Unconstrained value.
  if (strict && min === null && max === null) return true
  
  // PASS
  return null
}

export function placeholderInputNumber(input: InputNumber): string {
  const min = input.minValue
  const max = input.maxValue
  const isLength = input.rangeIsLength
  const isEither = input.rangeIsEither
  if (min !== null && max !== null && !isLength) return i18n.tc('validations.number.range', undefined, { min: min, max: max })
  if (min !== null && max === null && !isLength) return i18n.tc('validations.number.min', undefined, { min: min, max: max })
  if (min === null && max !== null && !isLength) return i18n.tc('validations.number.max', undefined, { min: min, max: max })
  if (min !== null && max !== null && isLength && !isEither) {
    if (min === max) return i18n.tc('validations.number.length.exact', undefined, { min: min, max: max })
    return i18n.tc('validations.number.length.range', undefined, { min: min, max: max })
  }
  if (min !== null && max !== null && isLength && isEither) return i18n.tc('validations.number.length.either', undefined, { min: min, max: max })
  return i18n.tc('validations.number.unconstrained')
}

export function validInputDate(input: InputDate, strict: boolean): boolean | null {
  const min = input.minValue
  const max = input.maxValue
  const { value, required, additional } = input
      
  // console.log(`validInputDate: value="${value}" min="${min}", max="${max}"`)

  // FAILED

  // Required check, only in strict mode
  const checkRequired = strict && required
  if (checkRequired && value === null) return false
  // Value in range check. Min is strict mode only.
  const checkValueInRange = true
  if (checkValueInRange && strict && min !== null && value !== null && value < min) return false
  if (checkValueInRange && max !== null && value !== null && value > max) return false
  // Additional check, only in strict mode
  const checkAdditional = strict
  if (checkAdditional && value !== null && additional !== null && !additional(value)) return false

  // SUCCESSFUL

  // Value in range check
  if (checkValueInRange && strict && min !== null && max !== null && value !== null && value >= min && value <= max) return true
  if (checkValueInRange && strict && min !== null && max === null && value !== null && value >= min) return true
  if (checkValueInRange && strict && min === null && max !== null && value !== null && value <= max) return true
  // Additional check
  if (checkAdditional && strict && value !== null && additional !== null && additional(value)) return true
  // Unconstrained value
  if (strict && min === null && max === null) return true

  // PASS
  return null
}

export function placeholderInputDate(input: InputDate): string {
  return i18n.tc('common.emptydate')
}

export function validInputFile(input: InputFile, strict: boolean): boolean | null {
  if (strict === true && input.required && input.value === null) return false
  if (strict === false && input.value === null) return null
  return true
}

export function validInputCheckbox(input: InputCheckbox, strict: boolean): boolean | null {
  // FAILED
  if (strict && input.requiredTrue && input.value !== true) return false

  // SUCCESSFUL
  if (strict && input.requiredTrue && input.value !== null) return true

  // PASS
  return null
}

export function validInputSelect(input: InputSelect, strict: boolean): boolean | null {
  // FAILED

  // SUCCESSFUL
  if (strict && input.selected !== undefined) return true

  // PASS
  return null
}
