
import Vue from 'vue'
import router from '@/router'
import Component from 'vue-class-component'

@Component
export default class OnboardingSignupSuccessView extends Vue {
  next() {
    router.push('/cases-overviews')
  }
}
