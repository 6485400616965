import { Pagination, PageModel } from '@/store/modules/creditorsAPI'
import { CaseDocumentsGetModel, CaseDocumentsDetailsModel } from '@/models/documents'

export function mockCaseDocumentsDetailsModel(caseDocumentId: number): CaseDocumentsDetailsModel {
  return {
    url_path: 'https://docs.google.com/document/d/15Xl_igXVDMOWIwlSGBZ4rUlnybHGREsJQqYcF_BIKwk/edit?usp=sharing',
    filename: 'test.txt',
  }
}

export function mockCaseDocumentsGetModel(pagination: Pagination): PageModel<CaseDocumentsGetModel> {
  const items: CaseDocumentsGetModel[] = [
  ]

  // No filtering
  let results = items
  const total = results.length

  // Paging
  const start = (pagination.page - 1) * pagination.maxResults
  const end = start + pagination.maxResults
  results = results.slice(start, end)

  return {
    _total: total,
    _max_results: pagination.maxResults,
    _page: pagination.page,
    extra: undefined,
    _items: results,
  }
}
