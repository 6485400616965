import i18n from '@/plugins/i18n'

export type CostType = 'ADVICE' | 'REMINDER' | 'COLLECTION'
export function translateCostType(cost: CostType): string {
  switch (cost) {
    case 'ADVICE': return i18n.tc('models.costs.ADVICE')
    case 'REMINDER': return i18n.tc('models.costs.REMINDER')
    case 'COLLECTION': return i18n.tc('models.costs.COLLECTION')
    default: return ''
  }
}

export interface CaseCostsGetModel {
  created: string; // Date when cost applied
  type: CostType; // Type of cost
  remaining_capital: number; // Remaining capital on cost
  remaining_interest: number; // Remaining interest on cost
}

export interface CaseCostsDetailsModel {
  created: Date; // Date when cost applied
  type: CostType; // Type of cost
  paid_capital: number; // Paid capital on cost
  paid_interest: number; // Paid interest on cost
  remaining_capital: number; // Remaining capital on cost
  remaining_interest: number; // Remaining interest on cost
  interest_rate: number; // Percentage calculated interest with
  interest_type: string; // Type of interest calculated
  interest_from: Date; // Date when interest is calculated from
  interest_to: Date; // Date when interest is calculated to
}

export interface CaseCostsPostModel {
  type: CostType; // What kind of cost
  created: string; // Date when cost applied
  initial_capital: number; // Initial capital
  paid_capital: number; // Paid capital on cost
  paid_interest: number; // Paid interest on cost
  remaining_capital: number; // Remaining capital on cost
  remaining_interest: number; // Remaining interest on cost
  interest_rate: number; // Percentage calculated interest with
  interest_type: string; // Type of interest calculated
  interest_from: string; // Date when interest is calculated from
  interest_to: string; // Date when interest is calculated to
}
