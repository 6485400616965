
import Vue from 'vue'
import router from '@/router'
import Component from 'vue-class-component'
import { namespace } from 'vuex-class'
import { State2Way } from 'vuex-class-state2way'
import { Alert } from '@/models/alerts'
import { InputSelect, InputText, validInputs } from '@/models/validations'
import { MenuBar } from '@/models/menus'
import { APIError } from '@/store/modules/creditorsAPI'

const Authentications = namespace('authentications')
const Cases = namespace('cases')
const CaseDebtors = namespace('caseDebtors')
const CreditorDepartments = namespace('creditorDepartments')
const Navigation = namespace('navigation')

@Component
export default class RegisterManuallyStartView extends Vue {
  @Navigation.Getter getMenuBar!: MenuBar

  @Authentications.Action switchCurrentCreditor!: (creditorId: number) => Promise<true | APIError>
  @Cases.Action startCreatingReminder!: () => void

  @State2Way('cases/setAlert', (state) => state.cases.alert) alert!: Alert | undefined
  @State2Way('cases/setOrganisationId', (state) => state.cases.organisationId) organisationId!: InputText
  @State2Way('caseDebtors/setDebtorOption', (state) => state.caseDebtors.debtorOption) debtorOption!: InputSelect
  @State2Way('creditorDepartments/setCreditorDepartment', (state) => state.creditorDepartments.creditorDepartment) creditorDepartment!: InputSelect

  strict = false

  async reset() {
    this.strict = false
  }

  get hasDepartments(): boolean { return this.creditorDepartment.optionCount > 1 }

  async switchCreditor(creditorId: number) {
    if (await this.switchCurrentCreditor(creditorId) === true) router.push('/cases-overviews')
  }

  next() {
    const inputs = [this.creditorDepartment, this.debtorOption, this.organisationId]
    this.strict = true
    if (validInputs(inputs, this.strict)) {
      router.push('/register-manually-debtor')
    }
  }

  async mounted() {
    this.startCreatingReminder()
  }
}
