
import Vue from 'vue'
import router from '@/router'
import Component from 'vue-class-component'
import { namespace } from 'vuex-class'
import { State2Way } from 'vuex-class-state2way'
import { Alert } from '@/models/alerts'
import { InputText, InputCheckbox, validInputs } from '@/models/validations'
import { APIError } from '@/store/modules/creditorsAPI'

const Creditors = namespace('creditors')

@Component
export default class OnboardingSignupContactView extends Vue {
  @Creditors.Action createCreditor!: () => Promise<true | APIError>

  @State2Way('creditors/setAlert', (state) => state.creditors.alert) alert!: Alert | undefined
  @State2Way('creditors/setPersonalNumber', (state) => state.creditors.personalNumber) personalNumber!: InputText
  @State2Way('creditors/setFirstName', (state) => state.creditors.firstName) firstName!: InputText
  @State2Way('creditors/setLastName', (state) => state.creditors.lastName) lastName!: InputText
  @State2Way('creditors/setMobilePhone', (state) => state.creditors.mobilePhone) mobilePhone!: InputText
  @State2Way('creditors/setEmail', (state) => state.creditors.email) email!: InputText
  @State2Way('creditors/setAgree', (state) => state.creditors.agree) agree!: InputCheckbox

  strict = false

  async reset() {
    this.strict = false
  }

  async next() {
    const inputs = [this.personalNumber, this.firstName, this.lastName, this.mobilePhone, this.email, this.agree]
    this.strict = true
    if (validInputs(inputs, this.strict)) {
      if (await this.createCreditor() === true) router.push('/signup-success')
    } else {
      console.log('Invalid')
    }
  }
}
