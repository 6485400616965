
import Vue from 'vue'
import router from '@/router'
import Component from 'vue-class-component'
import { namespace } from 'vuex-class'
import { State2Way } from 'vuex-class-state2way'
import { Alert } from '@/models/alerts'
import { Dashboard, DashboardsProgress } from '@/store/modules/dashboards'
import { MenuBar } from '@/models/menus'
import { Page, APIError, missingError } from '@/store/modules/creditorsAPI'
import { StatusType } from '@/models/cases'
import { UserToken } from '@/store/modules/authentications'

const Authentications = namespace('authentications')
const Dashboards = namespace('dashboards')
const Navigation = namespace('navigation')

@Component
export default class CasesOverviewsView extends Vue {
  @Authentications.Getter getFullName!: string
  @Authentications.Getter getCurrentUserToken!: UserToken | undefined
  @Dashboards.Getter getProgress!: DashboardsProgress
  @Dashboards.Getter getDashboards!: Dashboard[]
  @Dashboards.Getter getActiveCount!: string
  @Dashboards.Getter getActiveRemainingTotal!: string
  @Dashboards.Getter getActiveCaseMessagesUnread!: number
  @Navigation.Getter getMenuBar!: MenuBar

  @Dashboards.Action refreshDashboards!: () => Promise<true | APIError>
  @Authentications.Action switchCurrentCreditor!: (creditorId: number) => Promise<true | APIError>

  @State2Way('cases/setAlert', (state) => state.cases.alert) alert!: Alert | undefined

  busy = true

  get isEmpty(): boolean {
    return this.getProgress === 'EMPTY'
  }

  get creditorName(): string {
    return this.getCurrentUserToken?.creditorName ?? ''
  }

  get hasUnreadMessages(): boolean {
    return this.getActiveCaseMessagesUnread > 0
  }

  dashboard(statusType: StatusType): Dashboard | undefined {
    return this.getDashboards.filter((it) => it.matches[0] === statusType)[0]
  }

  async showDashboard(statusType: StatusType) {
    router.push({ name: 'CasesOngoingView', params: { status: statusType } })
  }

  async switchCreditor(creditorId: number) {
    this.busy = true
    if (await this.switchCurrentCreditor(creditorId) === true) {
      await this.refreshDashboards()
      this.busy = false
    }
  }

  async mounted() {
    await this.refreshDashboards()
    this.busy = false
  }
}
