
import Vue from 'vue'
import router from '@/router'
import Component from 'vue-class-component'
import { namespace } from 'vuex-class'
import { Page, APIError, isPage } from '@/store/modules/creditorsAPI'
import { MenuBar } from '@/models/menus'
import { CreditorUserMessage } from '@/store/modules/creditorUserMessages'

const Authentications = namespace('authentications')
const Cases = namespace('cases')
const CreditorUserMessages = namespace('creditorUserMessages')
const Navigation = namespace('navigation')

@Component
export default class MessagesArchivedView extends Vue {
  @Navigation.Getter getMenuBar!: MenuBar

  @Authentications.Action switchCurrentCreditor!: (creditorId: number) => Promise<true | APIError>
  @Cases.Action switchCurrentCaseId!: (caseId: number) => Promise<true | APIError>
  @CreditorUserMessages.Action refreshArchived!: (page: number) => Promise<Page<CreditorUserMessage> | APIError>

  busy = false

  archivedPage: Page<CreditorUserMessage> = new Page<CreditorUserMessage>([], 0, 10, 1)

  async switchCreditor(creditorId: number) {
    if (await this.switchCurrentCreditor(creditorId) === true) router.push('/cases-overviews')
  }

  caseDetails(data: {item: CreditorUserMessage}) {
    this.switchCurrentCaseId(data.item.caseId)
    router.push({ name: 'CaseDetailsView', params: { source: 'archived' } })
  }

  caseMessages(data: {item: CreditorUserMessage}) {
    this.switchCurrentCaseId(data.item.caseId)
    router.push({ name: 'CaseDetailsView', params: { source: 'archived' } })
  }

  archivedProvider(ctx: { currentPage: number; perPage: number }, callback: (items: any[]) => void): any[] | null {
    this.refreshArchived(ctx.currentPage)
      .then((page) => {
        if (isPage(page)) {
          this.archivedPage = page ?? this.archivedPage
          callback(page?.items ?? [])
        } else {
          callback([])
        }
      })
      .catch(() => {
        callback([])
      })
    return null
  }

  get fields(): { key: string; label: string; sortable: boolean; tdClass: string }[] {
    return [
      {
        key: 'subject', label: 'Ärende', sortable: true, tdClass: '',
      },
      {
        key: 'caseId', label: 'Akt.nr', sortable: false, tdClass: '',
      },
      {
        key: 'faktNr', label: 'Fakt.nr', sortable: false, tdClass: '',
      },
      {
        key: 'groupMessageId', label: 'Grupp', sortable: true, tdClass: '',
      },
      {
        key: 'clientId', label: 'Avd.', sortable: false, tdClass: '',
      },
      {
        key: 'createDate', label: 'Datum', sortable: false, tdClass: '',
      },
      {
        key: 'showDetails', label: '', sortable: false, tdClass: '',
      },
    ]
  }
}

