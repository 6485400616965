import { ActionTree, GetterTree, Module, MutationTree } from 'vuex'
import { RootState } from '@/store/index'
import { creditorsAPI, APIRequest, Pagination, PageModel, Page, APIUpload, APIError, missingError } from '@/store/modules/creditorsAPI'
import { CaseDocumentsGetModel, DocumentType, translateDocumentType, CaseDocumentsPostResponseModel, CaseDocumentsDetailsModel } from '@/models/documents'
import { Alert, apiError } from '@/models/alerts'
import { Case } from '@/store/modules/cases'
import { InputText, InputFile, InputSelect, InputOption } from '@/models/validations'
import * as mock from '@/mockdata/caseDocuments'
import { Formatting } from '@/models/formatting'

export interface DocumentOption {
  id: number;
  title: string;
  matches: DocumentType[];
}

const allDocumentOptions: DocumentOption[] = [
  { id: 1, title: translateDocumentType('INVOICE'), matches: ['INVOICE'] },
  /*  { id: 2, title: translateDocumentType('REMINDER'), matches: ['REMINDER'] },
  { id: 3, title: translateDocumentType('COLLECTION'), matches: ['COLLECTION'] },
  { id: 4, title: translateDocumentType('RECEIPT'), matches: ['RECEIPT'] },
  { id: 5, title: translateDocumentType('CONTEST'), matches: ['CONTEST'] },
  { id: 6, title: translateDocumentType('PRINT'), matches: ['PRINT'] }, */
  { id: 7, title: translateDocumentType('UNSPECIFIED'), matches: ['UNSPECIFIED'] },
]

export interface CaseDocument {
  caseId: number;
  caseDocumentId: number;
  description: string;
  filename: string;
  type: string;
  documentType: DocumentType;
  createFormatted: string;
  createDate: Date;
}

export interface CaseDocumentsState {
  alert: Alert | undefined;
  caseDocuments?: Page<CaseDocument>;
  documentOptions: DocumentOption[];

  currentFile: InputFile;
  currentDocumentOption: InputSelect;
  currentDescription: InputText;

  currentDocumentUrl?: string;
}

function resetDocumentOption(value: number | null = allDocumentOptions[0].id): InputSelect {
  const options = allDocumentOptions.map((it) => <InputOption> { value: it.id, text: it.title, data: it })
  return InputSelect.standard({ value: value, options: options })
}
function resetFile(): InputFile {
  return InputFile.standard({ value: null, required: true })
}
function resetDescription(): InputText {
  return InputText.standard({ value: '', minLength: null, maxLength: 50, required: true })
}

export const state: CaseDocumentsState = {
  alert: undefined,
  caseDocuments: undefined,
  documentOptions: allDocumentOptions,

  currentFile: resetFile(),
  currentDocumentOption: resetDocumentOption(),
  currentDescription: resetDescription(),

  currentDocumentUrl: undefined,
}

const namespaced = true

export const getters: GetterTree<CaseDocumentsState, RootState> = {
  getAlert(state): Alert | undefined {
    return state.alert
  },
  getCaseDocuments(state): Page<CaseDocument> | undefined {
    return state.caseDocuments
  },
  getAllDocumentOptions(state): DocumentOption[] {
    return state.documentOptions
  },
  getCurrentFile(state): InputFile {
    return state.currentFile
  },
  getCurrentDocumentOption(state): InputSelect {
    return state.currentDocumentOption
  },
  getCurrentDescription(state): InputText {
    return state.currentDescription
  },
  getCurrentDocumentUrl(state): string | undefined {
    return state.currentDocumentUrl
  },
}

export const mutations: MutationTree<CaseDocumentsState> = {
  setAlert(state, alert: Alert | undefined) {
    state.alert = alert
  },
  setCaseDocuments(state, caseDocuments: Page<CaseDocument>) {
    state.caseDocuments = caseDocuments
  },
  setCurrentFile(state, currentFile: InputFile) {
    state.currentFile = currentFile
  },
  setCurrentDocumentOption(state, documentOption: InputSelect) {
    state.currentDocumentOption = documentOption
  },
  setCurrentDocumentOptionId(state, documentOptionId: number) {
    const documentOption = state.documentOptions.find((it) => it.id === documentOptionId)
    if (documentOption !== undefined) state.currentDocumentOption = resetDocumentOption(documentOption.id)
  },
  setCurrentDocumentType(state, documentType: DocumentType) {
    const documentOption = state.documentOptions.find((it) => it.matches.includes(documentType))
    if (documentOption !== undefined) state.currentDocumentOption = resetDocumentOption(documentOption.id)
  },
  setCurrentDescription(state, currentDescription: InputText) {
    state.currentDescription = currentDescription
  },
  setCurrentDocumentUrl(state, currentDocumentUrl: string | undefined) {
    state.currentDocumentUrl = currentDocumentUrl
  },
}

export const actions: ActionTree<CaseDocumentsState, RootState> = {
  /**
   * Refreshes the store with the latest case documents
   *
   * @param store The vuex store.
   * @param store The page number to refresh.
   */
  async refreshCaseDocuments(store, page: number): Promise<Page<CaseDocument> | APIError> {
    console.log(`REFRESHING CASE DOCUMENTS ${page} START`)
    const currentCase: Case | undefined = store.rootGetters['cases/getCurrentCase']

    if (currentCase === undefined) return missingError('currentCase')

    // Build pagination from requested page
    const pagination = new Pagination(10, page)

    const request = <APIRequest> {
      method: 'get',
      path: `/case-documents?case_id=${currentCase.caseId}`,
      extra: currentCase.caseId,
      action: 'caseDocuments/recieveCaseDocumentsGetModel',
      mock: mock.mockCaseDocumentsGetModel(pagination),
      paging: pagination,
    }
    const result: true | APIError = await store.dispatch('creditorsAPI/call', request)
    if (result !== true) {
      apiError(store, result)
      return result
    }
    return store.getters.getCaseDocuments
  },

  /**
   * Recieves a list of case document models and imports them into the store
   *
   * @param store The vuex store.
   * @param store The models to import.
   */
  async recieveCaseDocumentsGetModel(store, page: PageModel<CaseDocumentsGetModel>) {
    const caseId = page.extra as number

    // Transform each case to a case overview
    const caseDocuments: Page<CaseDocument> = Page.transform(page, ((it) => <CaseDocument> {
      caseId,
      caseDocumentId: it.case_document_id,
      description: it.description,
      filename: it.filename,
      type: translateDocumentType(it.document_type),
      documentType: it.document_type,
      createFormatted: Formatting.formatDate(new Date(it.create_date)),
      createDate: new Date(it.create_date),
    }))

    store.commit('setCaseDocuments', caseDocuments)
  },

  /**
   * Uploads a case document
   *
   * @param store The vuex store.
   * @param registerId If set, uploads to a register id instead of a case.
   */
  async uploadCaseDocument(store, registerId: number | null = null): Promise<true | APIError> {
    console.log('UPLOADING CASE DOCUMENT START')
    const currentCase: Case | undefined = store.rootGetters['cases/getCurrentCase']
    const file: InputFile = store.getters.getCurrentFile
    const documentOption: InputSelect = store.getters.getCurrentDocumentOption
    const type = allDocumentOptions.find((it) => it.id === documentOption.value)
    const description = store.getters.getCurrentDescription.toString()

    if (type === undefined) return missingError('documentOption missing')

    let path = ''
    if (registerId !== null) {
      path = `/case-documents?register_id=${registerId}&document_type=${type.matches[0]}&description=${description}`
    } else {
      if (currentCase === undefined) return missingError('currentCase')
      path = `/case-documents?case_id=${currentCase.caseId}&document_type=${type.matches[0]}&description=${description}`
    }

    const request = <APIUpload> {
      method: 'post',
      path,
      file: file.value,
      action: 'caseDocuments/recieveCaseDocumentsPostResponseModel',
      mock: [],
    }
    const result: true | APIError = await store.dispatch('creditorsAPI/upload', request)
    if (result !== true) {
      apiError(store, result)
      return result
    }
    await store.dispatch('startUploadCaseDocument')
    return result
  },

  /**
   * Recieves a list of case document models and imports them into the store
   *
   * @param store The vuex store.
   * @param store The models to import.
   */
  async recieveCaseDocumentsPostResponseModel(store, model: CaseDocumentsPostResponseModel) {
    console.log('UPLOADING CASE DOCUMENT RECIEVED:', model)
  },

  /**
   * Uploads a case document
   *
   * @param store The vuex store.
   * @param registerId If set, uploads to a register id instead of a case.
   */
  async queryCaseDocumentUrl(store, caseDocumentId: number): Promise<string | APIError> {
    console.log('QUERYING CASE DOCUMENT URL START')

    const request = <APIRequest> {
      method: 'get',
      path: `/case-documents/${caseDocumentId}`,
      extra: caseDocumentId,
      action: 'caseDocuments/recieveCaseDocumentsDetailsModel',
      mock: mock.mockCaseDocumentsDetailsModel(caseDocumentId),
    }
    const result: true | APIError = await store.dispatch('creditorsAPI/call', request)
    if (result !== true) {
      apiError(store, result)
      return result
    }
    return store.getters.getCurrentDocumentUrl
  },

  /**
   * Recieve a detailed model to extract
   *
   * @param store The vuex store.
   * @param store The model to import.
   */
  async recieveCaseDocumentsDetailsModel(store, model: CaseDocumentsDetailsModel) {
    store.commit('setCurrentDocumentUrl', model.url_path)
  },

  /**
   * Prepare the store for a new upload
   *
   * @param store The vuex store.
   */
  async startUploadCaseDocument(store) {
    store.commit('setCurrentFile', resetFile())
    store.commit('setCurrentDocumentOption', resetDocumentOption())
    store.commit('setCurrentDescription', resetDescription())
  },
}

export const caseDocuments: Module<CaseDocumentsState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations,
  modules: {
    creditorsAPI,
  },
}
