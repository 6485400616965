import i18n from '@/plugins/i18n'

export type SentToType = 'TO_VISMA' | 'TO_CREDITOR'
export function translateSentToType(sentTo: SentToType): string {
  switch (sentTo) {
    case 'TO_VISMA': return i18n.tc('models.sent.TO_VISMA')
    case 'TO_CREDITOR': return i18n.tc('models.sent.TO_CREDITOR')
    default: return ''
  }
}

export interface CaseMessagesGetModel {
  case_message_id: number; // Message identifier
  create_date: string; // Date when message initial created
  subject: string; // File name of the message, max_length=400
  body: string; // Description of what the message contain
  sent_to: SentToType; // Who the message sent to
  is_read: boolean; // Is the message read
  label: string; // Filter key free formatted. max_length=100
  client_id: number; // Client identifier
  user_id: number; // User identifier
  group_message_id: number; // Group message identifier
  _etag: string; // Current value of _etag
}

export interface CaseMessagesPostModel {
  subject: string; // File name of the message
  body: string; // Description of what the message contain
  label: string; // Is the message read
  creditor_id: string; // Creditor identifier. Must be provided and valid for case_id
  user_id: number; // User identifier.
  group_message_id: number; // Group Message identifier.
}

export interface CaseMessagesPostResponseModel {
  case_message_id: number; // Message identifier
  _etag: string; // Key to validate that nothing has changed
}

export interface CaseMessagesPatchModel {
  is_read?: boolean; // Is the message read
  label?: string; //
  user_id?: number; // Message identifier
  group_message_id?: number; // Message identifier
}

export interface CaseMessagesPatchResponseModel {
  _etag: string; // New _etag value after update
}
