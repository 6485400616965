
import Vue from 'vue'
import router from '@/router'
import { namespace } from 'vuex-class'
import { State2Way } from 'vuex-class-state2way'
import Component from 'vue-class-component'
import { MenuBar } from '@/models/menus'
import { Alert } from '@/models/alerts'
import { FieldType } from '@/components/NordicCoolTable.vue'
import { InputText, InputDate, InputNumber, InputCheckbox } from '@/models/validations'
import { APIError } from '@/store/modules/creditorsAPI'

const Authentications = namespace('authentications')
const CaseActions = namespace('caseActions')
const Navigation = namespace('navigation')

@Component
export default class CaseActionStartView extends Vue {
  @Navigation.Getter getMenuBar!: MenuBar

  @Authentications.Action switchCurrentCreditor!: (creditorId: number) => Promise<true | APIError>
  @CaseActions.Action createCaseAction!: () => Promise<true | APIError>

  @State2Way('caseActions/setAlert', (state) => state.casePayments.alert) alert!: Alert | undefined

  strict = false

  async switchCreditor(creditorId: number) {
    if (await this.switchCurrentCreditor(creditorId) === true) router.push('/cases-overviews')
  }

  async next() {
    if (await this.createCaseAction() === true) router.push('/case-action-success')
  }
}
