import { Pagination, PageModel } from '@/store/modules/creditorsAPI'
import { CreditorUserMessageGroupsGetModel } from '@/models/creditors'

export function mockCreditorUserMessageGroupsGetModel(pagination: Pagination): PageModel<CreditorUserMessageGroupsGetModel> {
  let results = mockCreditorUserMessageGroupsGetModels
  const total = results.length

  // Paging
  const start = (pagination.page - 1) * pagination.maxResults
  const end = start + pagination.maxResults
  results = results.slice(start, end)

  return {
    _total: total,
    _max_results: pagination.maxResults,
    _page: pagination.page,
    extra: undefined,
    _items: results,
  }
}

const mockCreditorUserMessageGroupsGetModels: CreditorUserMessageGroupsGetModel[] = [
]
