
import Vue from 'vue'
import router from '@/router'
import Component from 'vue-class-component'
import { namespace } from 'vuex-class'
import { State2Way } from 'vuex-class-state2way'
import { Alert } from '@/models/alerts'
import { InputFile } from '@/models/validations'
import { MenuBar } from '@/models/menus'
import { DebtorOption } from '@/store/modules/caseDebtors'
import { CreditorDepartment } from '@/store/modules/creditorDepartments'
import { APIError } from '@/store/modules/creditorsAPI'
import { CaseImport } from '@/store/modules/cases'

const Authentications = namespace('authentications')
const Cases = namespace('cases')
const Navigation = namespace('navigation')

@Component
export default class RegisterImportPreviewView extends Vue {
  @Cases.Getter getImportCases!: CaseImport[]
  @Navigation.Getter getMenuBar!: MenuBar

  @Authentications.Action switchCurrentCreditor!: (creditorId: number) => Promise<true | APIError>
  @Cases.Action previewCSV!: () => void
  @Cases.Action importCSV!: () => Promise<boolean | APIError>

  @State2Way('cases/setAlert', (state) => state.cases.alert) alert!: Alert | undefined

  busy = true
  strict = true

  isDisabled(c: CaseImport): boolean { return !c.selected.value }

  async switchCreditor(creditorId: number) {
    if (await this.switchCurrentCreditor(creditorId) === true) router.push('/cases-overviews')
  }

  get fields(): { key: string, label: string }[] {
    return [
      { key: 'selected', label: '' },
      { key: 'invoiceNumber', label: this.$i18n.tc('common.fields.invoiceNumber') },
      { key: 'debtorType', label: this.$i18n.tc('register.manually.start.debtorType') },
      { key: 'organisationId', label: this.$i18n.tc('common.fields.orgPersonId') },
      { key: 'name', label: this.$i18n.tc('common.fields.name') },
      { key: 'address', label: this.$i18n.tc('common.fields.address') },
      { key: 'postalCode', label: this.$i18n.tc('common.fields.postalCode') },
      { key: 'city', label: this.$i18n.tc('common.fields.city') },
      { key: 'invoiceDescription', label: this.$i18n.tc('common.fields.description') },
      { key: 'invoiceDate', label: this.$i18n.tc('common.fields.invoiceDate') },
      { key: 'invoiceDueDate', label: this.$i18n.tc('common.fields.invoiceDueDate') },
      { key: 'remainingCapital', label: this.$i18n.tc('common.fields.remainingCapital') },
    ]
  }

  async next() {
    if (await this.importCSV() === true) {
      router.push('/register-import-success')
    }
  }

  async mounted() {
    await this.previewCSV()
    this.busy = false
  }

  previous() {
    router.push('/register-import-start')
  }
}
