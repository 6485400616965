import {
  ActionTree, GetterTree, Module, MutationTree,
} from 'vuex'
import { RootState } from '@/store/index'
import { Alert, apiError, taxCodeRequired, taxCodeUnavailable } from '@/models/alerts'
import { CreditorSelfRegistrationsPostModel, CreditorSelfRegistrationsPostResponseModel, CreditorDetailsModel } from '@/models/creditors'
import { CreditSafeCompanyResponseModel, CreditSafePersonalResponseModel } from '@/models/creditsafe'
import { InputText, InputNumber, InputCheckbox } from '@/models/validations'
import { APIRequest, creditorsAPI, APIError, isAPIError } from '@/store/modules/creditorsAPI'
import * as mock from '@/mockdata/creditors'
import { CreditorUser } from './creditorUsers'

export interface Creditor {
  creditorId: number;
  name: string;
  organisationId: string;
}

export type TaxCode = 'yes' | 'no' | 'unavailable'

export interface CreditorsState {
  alert: Alert | undefined;
  currentCreditor?: Creditor;

  taxCode: TaxCode;

  organisationId: InputText;

  name: InputText;
  address: InputText;
  postalCode: InputNumber;
  city: InputText;

  bankgiro: InputText;

  personalNumber: InputText;
  firstName: InputText;
  lastName: InputText;
  mobilePhone: InputText;
  email: InputText;

  agree: InputCheckbox;
}

function resetOrganisationId(value: string | null = null): InputText {
  return InputText.eitherNumberLike({ value: value, minLength: 10, maxLength: 12, digitsOnly: true, required: true })
}

function resetName(value: string | null = null): InputText {
  return InputText.standard({ value: value, minLength: null, maxLength: 50, required: true })
}
function resetAddress(value: string | null = null): InputText {
  return InputText.standard({ value: value, minLength: null, maxLength: 35, required: true })
}
function resetPostalCode(value: number | null = null): InputNumber {
  return InputNumber.lengthInRange({ value: value, minLength: 5, maxLength: 5, required: true })
}
function resetCity(value: string | null = null): InputText {
  return InputText.standard({ value: value, minLength: null, maxLength: 50, required: true })
}

function resetBankgiro(): InputText {
  return InputText.standard({ value: null, minLength: 7, maxLength: null, required: true })
}

function resetPersonalNumber(value: string | null = null): InputText {
  return InputText.eitherNumberLike({ value: value, minLength: 10, maxLength: 12, digitsOnly: true, required: true })
}
function resetFirstName(value: string | null = null): InputText {
  return InputText.standard({ value, minLength: 1, maxLength: 50, required: true })
}
function resetLastName(value: string | null = null): InputText {
  return InputText.standard({ value, minLength: 1, maxLength: 50, required: true })
}
function resetMobilePhone(value: string | null = null): InputText {
  return InputText.numberLike({ value: value, minLength: 8, maxLength: 20, digitsOnly: false, required: true })
}
function resetEmail(value: string | null = null): InputText {
  return InputText.email({ value: value, required: true })
}

function resetAgree(): InputCheckbox {
  return InputCheckbox.standard({ value: null, requiredTrue: true })
}

export const state: CreditorsState = {
  alert: undefined,
  currentCreditor: undefined,

  taxCode: 'unavailable',
  organisationId: resetOrganisationId(),

  name: resetName(),
  address: resetAddress(),
  postalCode: resetPostalCode(),
  city: resetCity(),

  bankgiro: resetBankgiro(),

  personalNumber: resetPersonalNumber(),
  firstName: resetFirstName(),
  lastName: resetLastName(),
  mobilePhone: resetMobilePhone(),
  email: resetEmail(),

  agree: resetAgree(),
}

const namespaced = true

export const getters: GetterTree<CreditorsState, RootState> = {
  getAlert(state): Alert | undefined {
    return state.alert
  },
  getCurrentCreditor(state): Creditor | undefined {
    return state.currentCreditor
  },
  getTaxCode(state): TaxCode {
    return state.taxCode
  },
  getOrganisationId(state): InputText {
    return state.organisationId
  },
  getName(state): InputText {
    return state.name
  },
  getAddress(state): InputText {
    return state.address
  },
  getPostalCode(state): InputNumber {
    return state.postalCode
  },
  getCity(state): InputText {
    return state.city
  },
  getBankgiro(state): InputText {
    return state.bankgiro
  },
  getPersonalNumber(state): InputText {
    return state.personalNumber
  },
  getFirstName(state): InputText {
    return state.firstName
  },
  getLastName(state): InputText {
    return state.lastName
  },
  getMobilePhone(state): InputText {
    return state.mobilePhone
  },
  getEmail(state): InputText {
    return state.email
  },
  getAgree(state): InputCheckbox {
    return state.agree
  },
}

export const mutations: MutationTree<CreditorsState> = {
  setAlert(state, alert: Alert | undefined) {
    state.alert = alert
  },
  setCurrentCreditor(state, currentCreditor: Creditor | undefined) {
    state.currentCreditor = currentCreditor
  },
  setOrganisationId(state, organisationId: InputText) {
    state.organisationId = organisationId
  },
  setTaxCode(state, taxCode: TaxCode) {
    state.taxCode = taxCode
  },
  setName(state, name: InputText) {
    state.name = name
  },
  setAddress(state, address: InputText) {
    state.address = address
  },
  setPostalCode(state, postalCode: InputNumber) {
    state.postalCode = postalCode
  },
  setCity(state, city: InputText) {
    state.city = city
  },
  setBankgiro(state, bankgiro: InputText) {
    state.bankgiro = bankgiro
  },
  setPersonalNumber(state, personalNumber: InputText) {
    state.personalNumber = personalNumber
  },
  setFirstName(state, firstName: InputText) {
    state.firstName = firstName
  },
  setLastName(state, lastName: InputText) {
    state.lastName = lastName
  },
  setMobilePhone(state, mobilePhone: InputText) {
    state.mobilePhone = mobilePhone
  },
  setEmail(state, email: InputText) {
    state.email = email
  },
  setAgree(state, agree: InputCheckbox) {
    state.agree = agree
  },
}

export const actions: ActionTree<CreditorsState, RootState> = {
  async refreshCurrentCreditorDetails(store): Promise<true | APIError> {
    console.log('REFRESHING CURRENT CREDITOR')
    const currentCreditorId = store.rootGetters.getCurrentCreditorId

    const request = <APIRequest> {
      method: 'get',
      path: `/creditors/${currentCreditorId}`,
      action: 'creditors/recieveCreditorDetailsModel',
      mock: mock.mockCreditorDetailsModel(),
    }
    const result: true | APIError = await store.dispatch('creditorsAPI/call', request)
    if (result !== true) {
      apiError(store, result)
      return result
    }
    return true
  },

  async recieveCreditorDetailsModel(store, details: CreditorDetailsModel) {
    const creditor: Creditor = {
      creditorId: details.creditor_id,
      name: details.name,
      organisationId: details.organisation_id,
    }
    store.commit('setCurrentCreditor', creditor)
  },

  /*
   * Lookup a creditors address.
   *
   * @param store The vuex store
   */
  async lookupAddress(store): Promise<true | APIError> {
    const organisationId = state.organisationId.toOrganisationNumber()
    console.log(`LOOKUP ADDRESS -> ${organisationId}`)

    store.commit('setTaxCode', 'unavailable')

    const request: APIRequest = {
      method: 'get',
      path: `/company-registration-number-lookup/${organisationId}`,
      action: 'creditors/recieveCreditSafeCompanyResponseModel',
      mock: mock.mockCreditSafeCompanyResponseModel(organisationId),
    }

    const result: true | APIError = await store.dispatch('creditorsAPI/call', request)
    if (result !== true) {
      apiError(store, result, '/signup-start')
      return result
    }

    switch (state.taxCode) {
      case 'yes':
        return true
      case 'no':
        store.commit('setAlert', taxCodeRequired('/signup-start'))
        return <APIError> {}
      default:
        store.commit('setAlert', taxCodeUnavailable('/signup-start'))
        return <APIError> {}
    }
  },

  async recieveCreditSafeCompanyResponseModel(store, model: CreditSafeCompanyResponseModel) {
    store.commit('setName', resetName(model.company_name))
    store.commit('setAddress', resetAddress(model.address))
    store.commit('setPostalCode', resetPostalCode(parseInt(model.zip_code, 10)))
    store.commit('setCity', resetCity(model.city))
    switch (model.f_tax?.toLowerCase()) {
      case 'yes':
        store.commit('setTaxCode', 'yes')
        break
      case 'no':
        store.commit('setTaxCode', 'no')
        break
      default:
        store.commit('setTaxCode', 'unavailable')
        break
    }
  },

  async recieveCreditSafePersonalResponseModel(store, model: CreditSafePersonalResponseModel) {
    store.commit('setName', resetName(`${model.first_name ?? ''} ${model.last_name ?? ''}`.trim()))
    store.commit('setAddress', resetAddress(model.address))
    store.commit('setPostalCode', resetPostalCode(parseInt(model.zip_code, 10)))
    store.commit('setCity', resetCity(model.city))
    store.commit('setTaxCode', 'unavailable')
  },

  /**
   * Registers a new creditor, i.e. sign-up to service
   *
   * @param store The vuex store.
   */
  async createCreditor(store): Promise<true | APIError> {
    console.log('CREATE CREDITOR START')

    const data: CreditorSelfRegistrationsPostModel = {
      organisation_id: state.organisationId.toOrganisationNumber(),

      name: state.name.toString(),
      address: state.address.toString(),
      co_address: '',
      postal_code: state.postalCode.toNumber(),
      city: state.city.toString(),
      country_code: 'SE',

      plusgiro: '',
      bankgiro: state.bankgiro.toString(),
      iban: '',
      bic: '',
      currency: 'SEK',

      unique_identifier: '',
      user_messages_by_rest_api: true,
      creditor_user: {
        personal_id_mobile_bankid: state.personalNumber.toString(),
        full_name: `${state.firstName.toString()} ${state.lastName.toString()}`,
        first_name: state.firstName.toString(),
        last_name: state.lastName.toString(),
        mobile_phone: state.mobilePhone.toString(),
        email: state.email.toString(),
        description: '',
        no_messages: false,
        unique_identifier: '',
        administration_rights: ['full_administration_rights'],
      },
    }

    const request: APIRequest = {
      method: 'post',
      path: '/creditors-self-registrations',
      data,
      action: 'creditors/recieveCreditorSelfRegistrationsPostResponseModel',
      mock: mock.mockCreditorSelfRegistrationsPostResponseModel(data),
    }
    const result: true | APIError = await store.dispatch('creditorsAPI/call', request)
    if (result !== true) {
      apiError(store, result)
      return result
    }

    // Refresh token after sign-up to exchange an unauthorized token with an authorized or
    // to renew the authentication in the case of enroll
    const unauthorizedToken: string | undefined = store.rootGetters['authentications/getUnauthorizedToken']
    if (unauthorizedToken !== undefined) {
      return store.dispatch('authentications/refreshUnauthorizedToken', null, { root: true })
    }
    return store.dispatch('authentications/renewAuthentication', null, { root: true })
  },

  /**
   * Recieves a response for registering a new creditor
   *
   * @param store The vuex store.
   * @param response The model for case creation.
   */
  async recieveCreditorSelfRegistrationsPostResponseModel(store, model: CreditorSelfRegistrationsPostResponseModel) {
    // After a sign-up we need to switch to the new creditor, in the case of enroll we leave the current creditor id as it is
    const unauthorizedToken: string | undefined = store.rootGetters['authentications/getUnauthorizedToken']
    if (unauthorizedToken !== undefined) {
      store.commit('setCurrentCreditorId', model.creditor_id, { root: true })
    }
  },

  /**
   * Prepares the store for starting a new creditor sign up. The difference between startCreditorSignup
   * and startCreditorEnroll are which data that is pre-populated
   *
   * @param store The vuex store.
   */
  async startCreditorSignup(store) {
    // Look-up dependent data
    const personalNumber: InputText = store.rootGetters['authentications/getPersonalNumber']
    const firstName: string | undefined = store.rootGetters['authentications/getFirstName']
    const lastName: string | undefined = store.rootGetters['authentications/getLastName']

    store.commit('setOrganisationId', resetOrganisationId())

    store.commit('setName', resetName())
    store.commit('setAddress', resetAddress())
    store.commit('setPostalCode', resetPostalCode())
    store.commit('setCity', resetCity())

    store.commit('setBankgiro', resetBankgiro())

    store.commit('setPersonalNumber', resetPersonalNumber(personalNumber.value))
    store.commit('setFirstName', resetFirstName(firstName ?? null))
    store.commit('setLastName', resetLastName(lastName ?? null))
    store.commit('setMobilePhone', resetMobilePhone())
    store.commit('setEmail', resetEmail())
  },

  /**
   * Prepares the store for starting a new creditor enroll. The difference between startCreditorSignup
   * and startCreditorEnroll are which data that is pre-populated
   *
   * @param store The vuex store.
   */
  async startCreditorEnroll(store) {
    // Look-up dependent data
    const creditorUserId: number | undefined = store.rootGetters['authentications/getCreditorUserId']
    const creditorUser: CreditorUser | APIError = await store.dispatch('creditorUsers/retrieveCreditorUserDetails', creditorUserId, { root: true })

    if (isAPIError(creditorUser)) {
      apiError(store, creditorUser)
      return
    }

    store.commit('setOrganisationId', resetOrganisationId())

    store.commit('setName', resetName())
    store.commit('setAddress', resetAddress())
    store.commit('setPostalCode', resetPostalCode())
    store.commit('setCity', resetCity())

    store.commit('setBankgiro', resetBankgiro())

    store.commit('setPersonalNumber', resetPersonalNumber(creditorUser.personalNumber))
    store.commit('setFirstName', resetFirstName(creditorUser.firstName))
    store.commit('setLastName', resetLastName(creditorUser.lastName))
    store.commit('setMobilePhone', resetMobilePhone(creditorUser.mobilePhone))
    store.commit('setEmail', resetEmail(creditorUser.email))
  },
}

export const creditors: Module<CreditorsState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations,
  modules: {
    creditorsAPI,
  },
}
