import VueGtm from '@gtm-support/vue2-gtm'
import Vue from 'vue'
import router from '@/router'

Vue.use(VueGtm, {
  id: 'GTM-WWZFNL9',
  enabled: true,
  debug: false,
  vueRouter: router,
})
