import { ActionContext } from 'vuex'
import { RootState } from '@/store/index'
import { TokenModel, UserListTokenModel } from '@/models/authenticates'
import { MOCK_SIMPLIFIED_TOKEN, MOCK_OTHER_TOKEN, MOCK_UNAUTHORIZED_TOKEN } from '@/store/modules/creditorsAPI'
import { AuthenticationState } from '@/store/modules/authentications'

export function mockTokenModel(store: ActionContext<AuthenticationState, RootState>): TokenModel {
  const token = store.getters['creditorsAPI/getToken']
  return {
  }
}

export function mockUserListTokenModelCancelled(store: ActionContext<AuthenticationState, RootState>): UserListTokenModel {
  return {
  }
}

export function mockUserListTokenModelComplete(store: ActionContext<AuthenticationState, RootState>): UserListTokenModel {
  const token = store.getters['creditorsAPI/getToken']
  if (token === MOCK_UNAUTHORIZED_TOKEN) {
    return {
    }
  }
  return {
  }
}
