
import Vue from 'vue'
import router from '@/router'
import Component from 'vue-class-component'
import { namespace } from 'vuex-class'
import { State2Way } from 'vuex-class-state2way'
import { Alert } from '@/models/alerts'
import { InputText, validInputs } from '@/models/validations'
import { APIError } from '@/store/modules/creditorsAPI'

const Creditors = namespace('creditors')

@Component
export default class OnboardingSignupStartView extends Vue {
  @Creditors.Mutation setCurrentCreditorOptionId!: (creditorOptionId: number) => void

  @Creditors.Action startCreditorSignup!: () => void

  @State2Way('creditors/setAlert', (state) => state.creditors.alert) alert!: Alert | undefined
  @State2Way('creditors/setOrganisationId', (state) => state.creditors.organisationId) organisationId!: InputText

  strict = false

  async reset() {
    this.strict = false
  }

  next() {
    const inputs = [this.organisationId]
    this.strict = true
    if (validInputs(inputs, this.strict)) {
      router.push('/signup-creditor')
    }
  }

  async mounted() {
    this.startCreditorSignup()
  }
}
