
import Vue from 'vue'
import router from '@/router'
import { namespace } from 'vuex-class'
import { State2Way } from 'vuex-class-state2way'
import { Alert } from '@/models/alerts'
import Component from 'vue-class-component'
import { Creditor } from '@/store/modules/creditors'
import { CreditorDepartment } from '@/store/modules/creditorDepartments'
import { CreditorUser } from '@/store/modules/creditorUsers'
import { FieldSymbol, FieldType } from '@/components/NordicCoolTable.vue'
import { MenuBar } from '@/models/menus'
import { Page, APIError } from '@/store/modules/creditorsAPI'

const Authentications = namespace('authentications')
const Creditors = namespace('creditors')
const CreditorDepartments = namespace('creditorDepartments')
const CreditorUsers = namespace('creditorUsers')
const Navigation = namespace('navigation')

@Component
export default class CreditorOverviewView extends Vue {
  @Creditors.Getter getCurrentCreditor!: Creditor | undefined
  @Navigation.Getter getMenuBar!: MenuBar

  @Authentications.Action switchCurrentCreditor!: (creditorId: number) => Promise<true | APIError>
  @Creditors.Action refreshCurrentCreditorDetails!: () => Promise<true | APIError>
  @CreditorDepartments.Action refreshCreditorDepartments!: (page: number) => Promise<Page<CreditorDepartment> | APIError>
  @CreditorDepartments.Action switchCurrentCreditorDepartmentId!: (creditorDepartmentId?: number) => Promise<true | APIError>
  @CreditorUsers.Action refreshCreditorUsers!: (page: number) => Promise<Page<CreditorUser> | APIError>
  @CreditorUsers.Action startCreatingCreditorUser!: () => void
  @CreditorUsers.Action switchCurrentCreditorUserId!: (creditorUserId?: number) => Promise<true | APIError>

  @State2Way('creditors/setAlert', (state) => state.creditors.alert) alert!: Alert | undefined

  isBusy = true

  currentSymbol(item: CreditorUser): FieldSymbol {
    return item.isCurrent ? 'lock' : 'none'
  }

  get getCurrentCreditorName(): string { return this.getCurrentCreditor?.name ?? '' }
  get getCurrentCreditorOrganisationId(): string { return this.getCurrentCreditor?.organisationId ?? '' }

  async switchCreditor(creditorId: number) {
    if (await this.switchCurrentCreditor(creditorId) === true) router.push('/cases-overviews')
  }

  editCreditorDepartment(data: {item: CreditorDepartment}) {
    this.switchCurrentCreditorDepartmentId(data.item.creditorDepartmentId)
    router.push({ name: 'CreditorDepartmentEditView', params: { mode: 'edit', source: 'overview' } })
  }

  creditorDepartmentItems(page: number): Promise<Page<CreditorDepartment> | APIError> {
    return this.refreshCreditorDepartments(page)
  }

  newCreditorUser() {
    this.switchCurrentCreditorUserId(undefined)
    this.startCreatingCreditorUser()
    router.push({ name: 'CreditorUserEditView', params: { mode: 'create', source: 'overview' } })
  }

  editCreditorUser(data: {item: CreditorUser}) {
    this.switchCurrentCreditorUserId(data.item.creditorUserId)
    router.push({ name: 'CreditorUserEditView', params: { mode: 'edit', source: 'overview' } })
  }

  get creditorDepartmentFields(): FieldType[] {
    return [
      {
        key: 'currency', label: this.$i18n.tc('common.fields.currency'), sortable: false, size: 'small', alignment: 'left',
      },
      {
        key: 'name', label: this.$i18n.tc('common.fields.name'), sortable: false, size: 'flex', alignment: 'left',
      },
      {
        key: 'actions', label: '', sortable: false, size: 'actions', alignment: 'right',
      },
    ]
  }

  creditorUserItems(page: number): Promise<Page<CreditorUser> | APIError> {
    return this.refreshCreditorUsers(page)
  }

  get creditorUserFields(): FieldType[] {
    return [
      {
        key: 'symbol', label: '', sortable: false, size: 'symbol', alignment: 'left',
      },
      {
        key: 'personalNumber', label: this.$i18n.tc('common.fields.personalNumber'), sortable: false, size: 'small', alignment: 'left',
      },
      {
        key: 'firstName', label: this.$i18n.tc('common.fields.firstName'), sortable: false, size: 'medium', alignment: 'left',
      },
      {
        key: 'lastName', label: this.$i18n.tc('common.fields.lastName'), sortable: false, size: 'medium', alignment: 'left',
      },
      {
        key: 'email', label: this.$i18n.tc('common.fields.email'), sortable: false, size: 'flex', alignment: 'left',
      },
      {
        key: 'actions', label: '', sortable: false, size: 'actions', alignment: 'right',
      },
    ]
  }

  async mounted() {
    await this.refreshCurrentCreditorDetails()
    this.isBusy = false
  }
}
