
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { Alert, Severity } from '@/models/alerts'
import router from '@/router'

@Component
export default class NordicCoolAlert extends Vue {
  @Prop(Object) readonly value: Alert | undefined

  get title(): string {
    return this.value?.title ?? ''
  }

  get message(): string {
    return this.value?.message ?? ''
  }

  get warning(): boolean {
    return this.value?.severity === Severity.Warning
  }

  get error(): boolean {
    return this.value?.severity === Severity.Error
  }

  get show(): boolean {
    return this.value !== undefined
  }

  close() {
    this.$emit('input', undefined)
    const route = this.value?.cancel
    if (route != null) {
      router.push(route)
    }
  }
}
