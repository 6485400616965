import {
  ActionTree, GetterTree, Module, MutationTree,
} from 'vuex'
import { RootState } from '@/store/index'
import {
  creditorsAPI, APIRequest, Pagination, PageModel, Page, APIError,
} from '@/store/modules/creditorsAPI'
import { SentToType, translateSentToType } from '@/models/messages'
import { CreditorUserMessagesGetModel } from '@/models/creditors'
import { Alert, apiError } from '@/models/alerts'
import * as mock from '@/mockdata/creditorUserMessages'

export interface CreditorUserMessage {
  caseId: number;
  caseMessageId: number;
  createDate: string;
  subject: string;
  body: string;
  sentTo: string;
  sentToType: SentToType;
  isRead: boolean;
  clientId: number;
  userId: number;
  groupMessageId: number;
  expanded: boolean;
}

export interface CreditorUserMessageState {
  alert: Alert | undefined;
  inbox?: Page<CreditorUserMessage>;
  sent?: Page<CreditorUserMessage>;
  archived?: Page<CreditorUserMessage>;
}

export const state: CreditorUserMessageState = {
  alert: undefined,
  inbox: undefined,
  sent: undefined,
  archived: undefined,
}

const namespaced = true

export const getters: GetterTree<CreditorUserMessageState, RootState> = {
  getAlert(state): Alert | undefined {
    return state.alert
  },
  getInbox(state, getters, rootState, rootGetters): Page<CreditorUserMessage> | undefined {
    return state.inbox
  },
  getSent(state, getters, rootState, rootGetters): Page<CreditorUserMessage> | undefined {
    return state.sent
  },
  getArchived(state, getters, rootState, rootGetters): Page<CreditorUserMessage> | undefined {
    return state.archived
  },
}

export const mutations: MutationTree<CreditorUserMessageState> = {
  setAlert(state, alert: Alert | undefined) {
    state.alert = alert
  },
  setInbox(state, inbox: Page<CreditorUserMessage>) {
    state.inbox = inbox
  },
  setSent(state, sent: Page<CreditorUserMessage>) {
    state.sent = sent
  },
  setArchived(state, archived: Page<CreditorUserMessage>) {
    state.archived = archived
  },
}

export const actions: ActionTree<CreditorUserMessageState, RootState> = {
  async refreshInbox(store, page: number): Promise<Page<CreditorUserMessage> | APIError> {
    console.log(`REFRESHING CREDITOR USER MESSAGES ${page} START`)

    // Build pagination from requested page
    const pagination = new Pagination(10, page)

    const extra = <{ isRead?: boolean; sentTo: SentToType }> { isRead: false, sentTo: 'TO_CREDITOR' }
    const request = <APIRequest> {
      method: 'get',
      path: `/creditor-user-messages?lookup={"is_read":"${extra.isRead}","sent_to":"${extra.sentTo}"}`,
      extra,
      action: 'creditorUserMessages/recieveCreditorUserMessagesGetModel',
      mock: mock.mockCreditorUserMessagesGetModel(pagination, extra),
      paging: pagination,
    }
    const result: true | APIError = await store.dispatch('creditorsAPI/call', request)
    if (result !== true) {
      apiError(store, result)
      return result
    }
    return store.getters.getInbox
  },

  async refreshSent(store, page: number): Promise<Page<CreditorUserMessage> | APIError> {
    console.log(`REFRESHING CREDITOR USER MESSAGES ${page} START`)

    // Build pagination from requested page
    const pagination = new Pagination(10, page)

    const extra = <{ isRead?: boolean; sentTo: SentToType }> { isRead: undefined, sentTo: 'TO_VISMA' }
    const request = <APIRequest> {
      method: 'get',
      path: `/creditor-user-messages?lookup={"sent_to":"${extra.sentTo}"}`,
      extra,
      action: 'creditorUserMessages/recieveCreditorUserMessagesGetModel',
      mock: mock.mockCreditorUserMessagesGetModel(pagination, extra),
      paging: pagination,
    }
    const result: true | APIError = await store.dispatch('creditorsAPI/call', request)
    if (result !== true) {
      apiError(store, result)
      return result
    }
    return store.getters.getSent
  },

  async refreshArchived(store, page: number): Promise<Page<CreditorUserMessage> | APIError> {
    console.log(`REFRESHING CREDITOR USER MESSAGES ${page} START`)

    // Build pagination from requested page
    const pagination = new Pagination(10, page)

    const extra = <{ isRead?: boolean; sentTo: SentToType }> { isRead: true, sentTo: 'TO_CREDITOR' }
    const request = <APIRequest> {
      method: 'get',
      path: `/creditor-user-messages?lookup={"is_read":"${extra.isRead}","sent_to":"${extra.sentTo}"}`,
      extra,
      action: 'creditorUserMessages/recieveCreditorUserMessagesGetModel',
      mock: mock.mockCreditorUserMessagesGetModel(pagination, extra),
      paging: pagination,
    }
    const result: true | APIError = await store.dispatch('creditorsAPI/call', request)
    if (result !== true) {
      apiError(store, result)
      return result
    }
    return store.getters.getArchived
  },

  async recieveCreditorUserMessagesGetModel(store, page: PageModel<CreditorUserMessagesGetModel>) {
    const { isRead, sentTo } = page.extra as { isRead?: boolean; sentTo: SentToType }

    // Transform
    const creditorUserMessages: Page<CreditorUserMessage> = Page.transform(page, ((it) => ({
      caseId: it.case_id,
      caseMessageId: it.case_message_id,
      createDate: it.create_date,
      subject: it.subject,
      body: it.body,
      sentTo: translateSentToType(it.sent_to),
      sentToType: it.sent_to,
      isRead: it.is_read,
      clientId: it.client_id,
      userId: it.user_id,
      groupMessageId: it.group_message_id,
      expanded: false,
    })))
    //    const unreadCount = caseMessages?.filter(it => active.includes(it.matches)).reduce(((acc, it) => acc + it.count), 0)
    //    store.commit('setActiveCount', activeCount ?? 0)

    switch (sentTo) {
      case 'TO_CREDITOR':
        if (isRead === false) store.commit('setInbox', creditorUserMessages)
        else store.commit('setArchived', creditorUserMessages)
        break
      case 'TO_VISMA':
        store.commit('setSent', creditorUserMessages)
        break
    }

    console.log('REFRESHING CREDITOR USER MESSAGES END')
  },
}

export const creditorUserMessages: Module<CreditorUserMessageState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations,
  modules: {
    creditorsAPI,
  },
}
