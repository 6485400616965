import { Pagination, PageModel } from '@/store/modules/creditorsAPI'
import { CaseHistoryLogsGetModel } from '@/models/history'

export function mockCaseHistoryLogsGetModel(pagination: Pagination): PageModel<CaseHistoryLogsGetModel> {
  const items: CaseHistoryLogsGetModel[] = [
  ]

  // No filtering
  let results = items
  const total = results.length

  // Paging
  const start = (pagination.page - 1) * pagination.maxResults
  const end = start + pagination.maxResults
  results = results.slice(start, end)

  return {
    _total: total,
    _max_results: pagination.maxResults,
    _page: pagination.page,
    extra: undefined,
    _items: results,
  }
}
