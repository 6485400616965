
import Vue from 'vue'
import router from '@/router'
import Component from 'vue-class-component'
import { namespace } from 'vuex-class'
import { State2Way } from 'vuex-class-state2way'
import { Alert } from '@/models/alerts'
import { Status } from '@/models/authenticates'
import { UserToken } from '@/store/modules/authentications'
import { APIError } from '@/store/modules/creditorsAPI'

const Authentications = namespace('authentications')
const CreditorsAPI = namespace('creditorsAPI')

const sleep = (milliseconds: number) => new Promise((resolve) => setTimeout(resolve, milliseconds))

@Component
export default class OnboardingBankIDView extends Vue {
  @Authentications.State status!: Status
  @Authentications.State userTokens!: UserToken[]

  @CreditorsAPI.Mutation setToken!: (token?: string) => void

  @Authentications.Action cancelAuthentication!: () => Promise<true | APIError>
  @Authentications.Action waitAuthentication!: () => Promise<true | APIError>

  @State2Way('authentications/setAlert', (state) => state.authentications.alert) alert!: Alert | undefined

  cancelled = false

  async cancel() {
    if (await this.cancelAuthentication() === true) {
      this.setToken(undefined)
      this.cancelled = true
      router.push('/')
    }
  }

  async mounted() {
    let result = await this.waitAuthentication()

    while (result === true && this.status === 'pending' && !this.cancelled) {
      await sleep(3000)
      result = await this.waitAuthentication()
    }

    if (result === true && this.status === 'complete' && !this.cancelled) {
      if (this.userTokens.length === 0) router.push('/signup-start')
      else router.push('/cases-overviews')
    }
  }
}
