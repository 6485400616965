
import Vue from 'vue'
import router from '@/router'
import { namespace } from 'vuex-class'
import { State2Way } from 'vuex-class-state2way'
import { Alert } from '@/models/alerts'
import Component from 'vue-class-component'
import { FieldType } from '@/components/NordicCoolTable.vue'
import { MenuBar } from '@/models/menus'
import { Creditor } from '@/store/modules/creditors'
import { CreditorDepartment } from '@/store/modules/creditorDepartments'
import { Page, APIError } from '@/store/modules/creditorsAPI'

const Authentications = namespace('authentications')
const Creditors = namespace('creditors')
const CreditorDepartments = namespace('creditorDepartments')
const Navigation = namespace('navigation')

@Component
export default class CreditorDepartmentsView extends Vue {
  @Creditors.Getter getCurrentCreditor!: Creditor
  @Navigation.Getter getMenuBar!: MenuBar

  @Authentications.Action switchCurrentCreditor!: (creditorId: number) => Promise<true | APIError>
  @CreditorDepartments.Action refreshCreditorDepartments!: (page: number) => Promise<Page<CreditorDepartment> | APIError>
  @CreditorDepartments.Action switchCurrentCreditorDepartmentId!: (creditorDepartmentId?: number) => Promise<true | APIError>

  @State2Way('creditors/setAlert', (state) => state.creditors.alert) alert!: Alert | undefined

  async switchCreditor(creditorId: number) {
    if (await this.switchCurrentCreditor(creditorId) === true) router.push('/cases-overviews')
  }

  newCreditorDepartment() {
    this.switchCurrentCreditorDepartmentId(undefined)
    router.push({ name: 'CreditorDepartmentView', params: { mode: 'create', source: 'departments' } })
  }

  editCreditorDepartment(data: {item: CreditorDepartment}) {
    this.switchCurrentCreditorDepartmentId(data.item.creditorDepartmentId)
    router.push({ name: 'CreditorDepartmentView', params: { mode: 'edit', source: 'departments' } })
  }

  creditorDepartmentItems(page: number): Promise<Page<CreditorDepartment> | APIError> {
    return this.refreshCreditorDepartments(page)
  }

  get creditorDepartmentFields(): FieldType[] {
    return [
      {
        key: 'currency', label: 'Valuta', sortable: false, size: 'small', alignment: 'left',
      },
      {
        key: 'name', label: 'Namn', sortable: false, size: 'flex', alignment: 'left',
      },
      {
        key: 'actions', label: '', sortable: false, size: 'actions', alignment: 'right',
      },
    ]
  }
}
