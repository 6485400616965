import { ActionTree, GetterTree, Module, MutationTree } from 'vuex'
import { RootState } from '@/store/index'
import { creditorsAPI, APIRequest, Pagination, Page, PageModel, APIError, missingError } from '@/store/modules/creditorsAPI'
import { CaseGetModel, CaseDetailsModel, StatusType, ClosureCodeType, translateStatusType, translateClosureCodeType, ActionType, CasePostModel, CasePostResponseModel, translateActionType, CaseGetInvoicesModel } from '@/models/cases'
import { Alert, applicationError, apiError, importValidationFailed } from '@/models/alerts'
import { CreditorDepartment, creditorDepartments } from '@/store/modules/creditorDepartments'
import { DebtorOption, debtorTypeFromInput, resetDebtorOption } from '@/store/modules/caseDebtors'
import { InputText, InputNumber, InputDate, InputFile, InputSelect, InputCheckbox, validInputs, InputOption } from '@/models/validations'
import { CreditSafeCompanyResponseModel, CreditSafePersonalResponseModel } from '@/models/creditsafe'
import * as mock from '@/mockdata/cases'
import { Formatting } from '@/models/formatting'
import { DebtorType } from '@/models/debtors'

export interface Case {
  creditorId: number;
  registerId: number;
  caseId: number;
  status: string;
  statusType: StatusType;
  creditorSystemId?: number;
  departmentSystemId?: number;
  jointCaseId: number;
  registrationDate: string;
  closureDate?: Date;
  formattedClosureDate: string;
  closureCode: string;
  closureCodeType?: ClosureCodeType;
  creditorCaseReferenceId: string;
  currencyCode: string;
  ocr?: string;
  creditorDepartmentId: number;
  remainingCapital: number;
  savedRemainingInterest?: number;
  savedRemainingInterestDate?: string;
  accruedInterest?: number;
  remainingCost?: number;
  remainingFee?: number;
  remainingTotal: number;
  formattedTotal: string;
  caseMessages: number;
  caseMessagesUnread: number;
  nextAction: string;
  nextActionType?: ActionType;
  nextActionDate: string;
  invoiceNumbers?: string[];
  invoiceDatesFormatted?: string[];
  invoiceInitialCapitalFormatted?: string[];
  mainDebtorName?: string;
}

export interface CaseDetails {
  caseId: number;
  status: string;
  statusType: StatusType;
  registrationDate: string;
  nextAction: string;
  nextActionDate: string;
  ocr?: string;
  clientId?: number;
  department?: number;
  closureDate?: Date;
  formattedClosureDate: string;
  closureCode?: string;
  closureCodeType?: ClosureCodeType;
  remainingTotal?: number;
  remainingTotalFormatted: string;
  currencyCode?: string;
}

export interface CaseCSV {
  isPrivate: string;
  organisationId: string;
  invoiceNumber: string;
  invoiceDescription: string;
  invoiceDate: string;
  invoiceDueDate: string;
  remainingCapital: string;
}

export interface CaseImport {
  row: number;
  containsErrors: boolean;
  selected: InputCheckbox;
  debtorType: InputSelect;
  organisationId: InputText;
  name: InputText;
  address: InputText;
  postalCode: InputNumber;
  city: InputText;
  invoiceNumber: InputText;
  invoiceDescription: InputText;
  invoiceDates: [InputDate, InputDate];
  remainingCapital: InputNumber;
}

export interface StatusOption {
  id: number;
  title: string;
  matches: StatusType[];
}

const allStatusOptions: StatusOption[] = [
  {
    id: 1,
    title: 'Alla',
    matches: ['REMINDER', 'COLLECTION', 'SURVEILLANCE', 'VERDICT_WARNING', 'VERDICT', 'ENFORCEMENT', 'AMORTISATION', 'OTHER'],
    // matches: ['INVOICE', 'REMINDER', 'COLLECTION', 'SURVEILLANCE', 'VERDICT_WARNING', 'VERDICT', 'ENFORCEMENT', 'AMORTISATION', 'OTHER'],
  },
  // { id: 2, title: translateStatusType('INVOICE'), matches: ['INVOICE'] },
  { id: 3, title: translateStatusType('REMINDER'), matches: ['REMINDER'] },
  { id: 4, title: translateStatusType('COLLECTION'), matches: ['COLLECTION'] },
  { id: 5, title: translateStatusType('AMORTISATION'), matches: ['AMORTISATION'] },
  { id: 6, title: translateStatusType('SURVEILLANCE'), matches: ['SURVEILLANCE'] },
  { id: 7, title: translateStatusType('ENFORCEMENT'), matches: ['ENFORCEMENT'] },
  { id: 8, title: translateStatusType('VERDICT'), matches: ['VERDICT'] },
  { id: 9, title: translateStatusType('VERDICT_WARNING'), matches: ['VERDICT_WARNING'] },
  { id: 10, title: translateStatusType('OTHER'), matches: ['OTHER'] },
]

export interface CasesState {
  alert: Alert | undefined;
  ongoingCases?: Page<Case>;
  closedCases?: Page<Case>;
  currentCase?: Case;

  activeCount: string;
  activeRemainingTotal: string;
  activeCaseMessagesUnread: number;
  currentCaseMessagesUnread: number;

  statusOption: InputSelect;

  searchCaseId: InputNumber;
  searchReferenceId: InputText;
  searchInvoiceNumber: InputText;

  organisationId: InputText;
  name: InputText;
  address: InputText;
  postalCode: InputNumber;
  city: InputText;
  invoiceNumber: InputText;
  invoiceDescription: InputText;
  invoiceDates: [InputDate, InputDate];
  remainingCapital: InputNumber;
  currentRegisterId: number;

  importCSV?: CaseCSV[];
  importCases?: CaseImport[];
}

function resetStatusOption(statusOption: StatusOption | null = null): InputSelect {
  const value = statusOption?.id ?? null
  const options = allStatusOptions.map((it) => <InputOption> { value: it.id, text: it.title, data: it })
  return InputSelect.standard({ value: value, options: options })
}
function resetSelected(value: boolean | null = null): InputCheckbox {
  return InputCheckbox.minimum({ value: value })
}
function resetSearchCaseId(value: number | null = null): InputNumber {
  return InputNumber.zeroIsBlank({ value: value })
}
function resetSearchReferenceId(value: string | null = null): InputText {
  return InputText.standard({ value: value, minLength: null, maxLength: 20, required: false })
}
function resetSearchInvoiceNumber(value: string | null = null): InputText {
  return InputText.standard({ value: value, minLength: null, maxLength: 20, required: false })
}
function resetOrganisationId(value: string | null = null): InputText {
  return InputText.eitherNumberLike({ value: value, minLength: 10, maxLength: 12, digitsOnly: true, required: true })
}
function resetName(value: string | null = null): InputText {
  return InputText.standard({ value: value, minLength: null, maxLength: 50, required: true })
}
function resetAddress(value: string | null = null): InputText {
  return InputText.standard({ value: value, minLength: null, maxLength: 35, required: true })
}
function resetPostalCode(value: number | null = null): InputNumber {
  return InputNumber.lengthInRange({ value: value, minLength: 5, maxLength: 5, required: true })
}
function resetCity(value: string | null = null): InputText {
  return InputText.standard({ value: value, minLength: null, maxLength: 50, required: true })
}
function resetInvoiceNumber(value: string | null = null): InputText {
  return InputText.standard({ value: value, minLength: null, maxLength: 20, required: true })
}
function resetInvoiceDescription(value: string | null = null): InputText {
  return InputText.standard({ value: value, minLength: 3, maxLength: 150, required: true })
}
function resetInvoiceDates(value1: Date | null = null, value2: Date | null = null): [InputDate, InputDate] {
  const invoiceDate = InputDate.standard({ value: value1, minValue: null, maxValue: new Date(), required: true })
  const additional: (value: Date) => boolean = ((it) => {
    const date = invoiceDate.value
    if (date === null) return false
    return it > date
  })
  const invoiceDueDate = InputDate.additional({ value: value2, minValue: null, maxValue: new Date(), required: true, additional: additional })
  return [invoiceDate, invoiceDueDate]
}
function resetRemainingCapital(value: number | null = null): InputNumber {
  return InputNumber.amountInRange({ value: value, minValue: 25, maxValue: null, required: true })
}

export const state: CasesState = {
  alert: undefined,
  ongoingCases: undefined,
  closedCases: undefined,
  currentCase: undefined,

  activeCount: '',
  activeRemainingTotal: '',
  activeCaseMessagesUnread: 0,
  currentCaseMessagesUnread: 0,

  statusOption: resetStatusOption(),

  searchCaseId: resetSearchCaseId(),
  searchReferenceId: resetSearchReferenceId(),
  searchInvoiceNumber: resetSearchInvoiceNumber(),

  organisationId: resetOrganisationId(),
  name: resetName(),
  address: resetAddress(),
  postalCode: resetPostalCode(),
  city: resetCity(),
  invoiceNumber: resetInvoiceNumber(),
  invoiceDescription: resetInvoiceDescription(),
  invoiceDates: resetInvoiceDates(),
  remainingCapital: resetRemainingCapital(),
  currentRegisterId: 0,

  importCSV: undefined,
  importCases: undefined,
}

const namespaced = true

export const getters: GetterTree<CasesState, RootState> = {
  getAlert(state): Alert | undefined {
    return state.alert
  },
  getOngoingCases(state): Page<Case> | undefined {
    return state.ongoingCases
  },
  getClosedCases(state): Page<Case> | undefined {
    return state.closedCases
  },
  getCurrentCase(state): Case | undefined {
    return state.currentCase
  },
  getActiveCount(state): string {
    return state.activeCount
  },
  getActiveRemainingTotal(state): string {
    return state.activeRemainingTotal
  },
  getActiveCaseMessagesUnread(state): number {
    return state.activeCaseMessagesUnread
  },
  getCurrentCaseDetails(state, getters, rootState, rootGetters): CaseDetails {
    const currentCase: Case | undefined = getters.getCurrentCase
    return {
      caseId: currentCase?.caseId ?? 0,
      status: translateStatusType(currentCase?.statusType),
      statusType: currentCase?.statusType ?? 'OTHER',
      registrationDate: currentCase?.registrationDate ?? '',
      nextAction: translateActionType(currentCase?.nextActionType),
      nextActionDate: currentCase?.nextActionDate ?? '',
      ocr: currentCase?.ocr,
      clientId: currentCase?.creditorSystemId,
      department: currentCase?.departmentSystemId,
      closureDate: currentCase?.closureDate,
      formattedClosureDate: Formatting.formatDate(currentCase?.closureDate),
      closureCode: currentCase?.closureCode,
      closureCodeType: currentCase?.closureCodeType,
      remainingTotal: currentCase?.remainingTotal,
      remainingTotalFormatted: Formatting.formatAmount(currentCase?.remainingTotal ?? 0),
      currencyCode: currentCase?.currencyCode,
    }
  },
  getStatusOption(state): InputSelect {
    return state.statusOption
  },
  getCurrentCaseMessagesUnread(state): number {
    return state.currentCaseMessagesUnread
  },
  getOrganisationId(state): InputText {
    return state.organisationId
  },
  getSearchCaseId(state): InputNumber {
    return state.searchCaseId
  },
  getSearchReferenceId(state): InputText {
    return state.searchReferenceId
  },
  getSearchInvoiceNumber(state): InputText {
    return state.searchInvoiceNumber
  },
  getName(state): InputText {
    return state.name
  },
  getAddress(state): InputText {
    return state.address
  },
  getPostalCode(state): InputNumber {
    return state.postalCode
  },
  getCity(state): InputText {
    return state.city
  },
  getInvoiceNumber(state): InputText {
    return state.invoiceNumber
  },
  getInvoiceDescription(state): InputText {
    return state.invoiceDescription
  },
  getInvoiceDates(state): [InputDate, InputDate] {
    return state.invoiceDates
  },
  getRemainingCapital(state): InputNumber {
    return state.remainingCapital
  },
  getCurrentRegisterId(state): number {
    return state.currentRegisterId
  },
  getImportCSV(state): CaseCSV[] | undefined {
    return state.importCSV
  },
  getImportCases(state): CaseImport[] | undefined {
    return state.importCases
  },
}

export const mutations: MutationTree<CasesState> = {
  setAlert(state, alert: Alert | undefined) {
    state.alert = alert
  },
  setOngoingCases(state, ongoingCases: Page<Case> | undefined) {
    state.ongoingCases = ongoingCases
  },
  setClosedCases(state, closedCases: Page<Case> | undefined) {
    state.closedCases = closedCases
  },
  setCurrentCase(state, c?: Case) {
    state.currentCase = c
  },
  setActiveCount(state, activeCount: number) {
    state.activeCount = activeCount.toString()
  },
  setActiveRemainingTotal(state, activeRemainingTotal: string) {
    state.activeRemainingTotal = activeRemainingTotal
  },
  setActiveCaseMessagesUnread(state, activeCaseMessagesUnread: number) {
    state.activeCaseMessagesUnread = activeCaseMessagesUnread
  },
  setCurrentCaseMessagesUnread(state, caseMessagesUnread: number) {
    state.currentCaseMessagesUnread = caseMessagesUnread
  },
  setStatusOption(state, statusOption: InputSelect) {
    state.statusOption = statusOption
  },
  setOrganisationId(state, organisationId: InputText) {
    state.organisationId = organisationId
  },
  setSearchCaseId(state, searchCaseId: InputNumber) {
    state.searchCaseId = searchCaseId
  },
  setSearchReferenceId(state, searchReferenceId: InputText) {
    state.searchReferenceId = searchReferenceId
  },
  setSearchInvoiceNumber(state, searchInvoiceNumber: InputText) {
    state.searchInvoiceNumber = searchInvoiceNumber
  },
  setName(state, name: InputText) {
    state.name = name
  },
  setPostalCode(state, postalCode: InputNumber) {
    state.postalCode = postalCode
  },
  setAddress(state, address: InputText) {
    state.address = address
  },
  setCity(state, city: InputText) {
    state.city = city
  },
  setInvoiceNumber(state, invoiceNumber: InputText) {
    state.invoiceNumber = invoiceNumber
  },
  setInvoiceDescription(state, invoiceDescription: InputText) {
    state.invoiceDescription = invoiceDescription
  },
  setInvoiceDates(state, invoiceDates: [InputDate, InputDate]) {
    state.invoiceDates = invoiceDates
  },
  setRemainingCapital(state, remainingCapital: InputNumber) {
    state.remainingCapital = remainingCapital
  },
  setCurrentRegisterId(state, currentRegisterId: number) {
    state.currentRegisterId = currentRegisterId
  },
  setImportCSV(state, importCSV: CaseCSV[] | undefined) {
    state.importCSV = importCSV
  },
  setImportCases(state, importCases: CaseImport[] | undefined) {
    state.importCases = importCases
  },
}

export const actions: ActionTree<CasesState, RootState> = {
  /**
   * 
   *
   * @param store The vuex store.
   */
  async refreshOngoingCases(store, page: number): Promise<Page<Case> | APIError> {
    console.log(`REFRESHING ONGOING CASES PAGES ${page}`)

    // Build pagination from requested page
    const pagination = new Pagination(10, page)

    // Fetch lookup options
    const creditorDepartment = (store.rootGetters['creditorDepartments/getSearchCreditorDepartment'] as InputSelect).value
    const caseId = store.state.searchCaseId.value
    const referenceId = store.state.searchReferenceId.value
    const invoiceNumber = store.state.searchInvoiceNumber.value

    const statusOption: StatusOption | undefined = store.state.statusOption.selected?.data
    const matches = statusOption?.matches ?? []
    const match = matches.length === 1 ? matches[0] : undefined

    let lookup = '"closure_date":"IS NULL"'
    if (match !== undefined) {
      lookup += `,"status":"${match}"`
    }
    if (creditorDepartment !== 0) {
      lookup += `,"creditor_department_id":"${creditorDepartment}"`
    }
    if (caseId !== null && caseId !== 0) {
      lookup += `,"joint_case_id":${caseId}`
    }
    if (referenceId !== null && referenceId !== '') {
      lookup += `,"creditor_case_reference_id":"${referenceId}"`
    }

    const request = <APIRequest> {
      method: 'get',
      path: `/cases?lookup={${lookup}}`,
      action: 'cases/recieveCaseGetModel',
      extra: matches,
      mock: mock.mockCaseGetModel(pagination, false, matches, creditorDepartment, caseId, referenceId),
      paging: pagination,
    }
    const result: true | APIError = await store.dispatch('creditorsAPI/call', request)
    if (result !== true) {
      apiError(store, result)
      return result
    }
    return store.getters.getOngoingCases
  },

  /**
   * 
   *
   * @param store The vuex store.
   */
  async refreshClosedCases(store, page: number): Promise<Page<Case> | APIError> {
    console.log(`REFRESHING CLOSED CASES PAGES ${page}`)

    // Build pagination from requested page
    const pagination = new Pagination(10, page)

    const today = new Date()
    const to = Formatting.formatDate(today)
    today.setFullYear(today.getFullYear() - 3)
    const from = Formatting.formatDate(today)
    
    const request = <APIRequest> {
      method: 'get',
      path: `/cases?lookup={"closure_date_from": "${from}", "closure_date_to":"${to}"}`,
      action: 'cases/recieveCaseGetModel',
      mock: mock.mockCaseGetModel(pagination, true, null, null, null, null),
      paging: pagination,
    }
    const result: true | APIError = await store.dispatch('creditorsAPI/call', request)
    if (result !== true) {
      apiError(store, result)
      return result
    }
    return store.getters.getClosedCases
  },

  /**
   * 
   *
   * @param store The vuex store.
   */
  async recieveCaseGetModel(store, page: PageModel<CaseGetModel>) {
    const currentId: number = store.rootGetters.getCurrentCreditorId
    const matches: StatusType[] | undefined = page.extra as (StatusType[] | undefined)

    const cases: Page<Case> = Page.transform(page, ((it) => {
      const closure_date = it.closure_date === null ? undefined : new Date(it.closure_date)
      return <Case> {
        creditorId: currentId,
        registerId: it.register_id,
        caseId: it.case_id,
        status: translateStatusType(it.status ?? 'OTHER'),
        statusType: it.status ?? 'OTHER',
        jointCaseId: it.joint_case_id,
        registrationDate: it.registration_date,
        closureDate: closure_date,
        formattedClosureDate: Formatting.formatDate(closure_date),
        closureCode: translateClosureCodeType(it.closure_code),
        closureCodeType: it.closure_code === null ? undefined : it.closure_code,
        creditorCaseReferenceId: it.creditor_case_reference_id,
        currencyCode: it.currency_code,
        creditorDepartmentId: it.creditor_department_id,
        remainingCapital: it.remaining_capital,
        remainingTotal: it.remaining_total,
        formattedTotal: Formatting.formatAmount(it.remaining_total),
        caseMessages: it.case_messages,
        caseMessagesUnread: it.case_messages_unread,
        nextAction: it.next_action,
        nextActionType: it.next_action_type === null ? undefined : it.next_action_type,
        nextActionDate: it.next_action_date,
        invoiceNumbers: it.invoices.map((it) => it.invoice_number),
        invoiceDatesFormatted: it.invoices.map((it) => it.invoice_date),
        invoiceInitialCapitalFormatted: it.invoices.map((it) => Formatting.formatAmount(it.initial_capital)),
        mainDebtorName: it.main_debtor_name,
      }
    }))

    if (matches !== undefined) {
      store.commit('setOngoingCases', cases)
    } else {
      store.commit('setClosedCases', cases)
    }
  },

  /**
   * 
   *
   * @param store The vuex store.
   */
  async refreshCurrentCaseDetails(store): Promise<true | APIError> {
    console.log('REFRESHING CASE')
    const currentCase: Case | undefined = store.getters.getCurrentCase

    if (currentCase === undefined) return missingError('currentCase')

    const request = <APIRequest> {
      method: 'get',
      path: `/cases/${currentCase?.caseId}`,
      action: 'cases/recieveCaseDetailsModel',
      mock: mock.mockCaseDetailsModel(currentCase),
    }
    const result: true | APIError = await store.dispatch('creditorsAPI/call', request)
    if (result !== true) {
      apiError(store, result)
      return result
    }
    return true
  },

  /**
   * 
   *
   * @param store The vuex store.
   */
  async recieveCaseDetailsModel(store, details: CaseDetailsModel) {
    const currentId: number = store.rootGetters.getCurrentCreditorId

    const closure_date = details.closure_date === null ? undefined : new Date(details.closure_date)
    const c: Case = {
      creditorId: currentId,
      registerId: details.register_id,
      caseId: details.case_id,
      status: translateStatusType(details.status ?? 'OTHER'),
      statusType: details.status ?? 'OTHER',
      creditorSystemId: details.creditor_system_id,
      departmentSystemId: details.department_system_id,
      jointCaseId: details.joint_case_id,
      registrationDate: details.registration_date,
      closureDate: closure_date,
      formattedClosureDate: Formatting.formatDate(closure_date),
      closureCode: translateClosureCodeType(details.closure_code),
      closureCodeType: details.closure_code === null ? undefined : details.closure_code,
      creditorCaseReferenceId: details.creditor_case_reference_id,
      currencyCode: details.currency_code,
      ocr: details.ocr,
      creditorDepartmentId: details.creditor_department_id,
      remainingCapital: details.remaining_capital,
      savedRemainingInterest: details.saved_remaining_interest,
      savedRemainingInterestDate: details.saved_remaining_interest_date,
      accruedInterest: details.accrued_interest,
      remainingCost: details.remaining_cost,
      remainingFee: details.remaining_fee,
      remainingTotal: details.remaining_total,
      formattedTotal: Formatting.formatAmount(details.remaining_total),
      caseMessages: details.case_messages,
      caseMessagesUnread: details.case_messages_unread,
      nextAction: details.next_action,
      nextActionType: details.next_action_type === null ? undefined : details.next_action_type,
      nextActionDate: details.next_action_date,
      invoiceNumbers: details.invoices.map((it) => it.invoice_number),
      invoiceDatesFormatted: details.invoices.map((it) => it.invoice_date),
      invoiceInitialCapitalFormatted: details.invoices.map((it) => Formatting.formatAmount(it.initial_capital)),
      mainDebtorName: details.main_debtor_name,
    }

    store.commit('setCurrentCase', c)
    store.commit('setCurrentCaseMessagesUnread', c.caseMessagesUnread)
  },

  /**
   * Registers a new reminder, i.e. a new case at the start of the process
   *
   * @param store The vuex store.
   */
  async createReminder(store): Promise<true | APIError> {
    console.log('CREATE CASE')

    // Look-up dependent data
    const creditorDepartment: CreditorDepartment = store.rootGetters['creditorDepartments/getCurrentCreditorDepartment']
    const debtorOption: DebtorOption = store.rootGetters['caseDebtors/getCurrentDebtorOption']

    const data: CasePostModel[] = [{
      creditor_department_id: creditorDepartment.creditorDepartmentId,
      currency: 'SEK',
      start_action: 'REMINDER',
      debtor_main: {
        debtor_role: 'MAIN_DEBTOR',
        debtor_type: debtorOption.matches[0],
        organisation_id: state.organisationId.toOrganisationNumber(),
        name: state.name.toString(),
        address_line_main: state.address.toString(),
        postal_code: state.postalCode.toNumber(),
        city: state.city.toString(),
        country_code: 'SE',
      },
      debts: [{
        debt_type: 'DEBIT',
        invoice_number: state.invoiceNumber.toString(),
        invoice_description: state.invoiceDescription.toString(),
        invoice_date: Formatting.formatDate(state.invoiceDates[0].toDate()),
        invoice_due_date: Formatting.formatDate(state.invoiceDates[1].toDate()),
        interest_from: Formatting.formatDate(state.invoiceDates[1].toDate()),
        interest_rate: 8,
        interest_type: 'LATE_PAYMENT_INTEREST',
        remaining_capital: state.remainingCapital.toNumber(),
      }],
    }]

    const request = <APIRequest> {
      method: 'post',
      path: '/cases',
      data,
      action: 'cases/recieveCasePostResponseModels',
      mock: mock.mockCasePostResponseModels(data),
    }
    const result: true | APIError = await store.dispatch('creditorsAPI/call', request)
    if (result !== true) {
      apiError(store, result, '/register-manually-debt')
      return result
    }
    return true
  },

  /**
   * Recieves a response for registering a new case
   *
   * @param store The vuex store.
   * @param models The model for case creation.
   */
  async recieveCasePostResponseModels(store, models: CasePostResponseModel[]) {
    store.commit('setCurrentRegisterId', models[0].register_id)
  },

  /**
   * Transforms the raw imported CSV (must be set previously) to a Case Import.
   * This includes parsing and validating the data in the CSV and doing adress
   * lookup. After the preview has been created the user has the option of updating
   * the preview before doing the actual import
   *
   * @param store The vuex store.
   * @param response The model for case creation.
   */
  async previewCSV(store): Promise<true | APIError> {
    console.log('PREVIEW CSV')

    const csv = store.state.importCSV
    if (csv === undefined) return true

    const importCases = await Promise.all(csv.map(async (value, index) => {
      var { isPrivate, organisationId } = value

      // 1. Start by parsing the isPrivate field. We accept both 'ja' and 'yes' regardless
      // of case. Anything else is a company.
      var debtorType: DebtorType = 'PRIVATE_FIRM'
      if (['ja', 'j', 'x', 'yes', 'y'].includes(isPrivate.toLowerCase())) {
        debtorType = 'PRIVATE_PERSON'
      }

      // 2. Empty the state in preparation for address lookup
      store.commit('setName', resetName())
      store.commit('setAddress', resetAddress())
      store.commit('setPostalCode', resetPostalCode())
      store.commit('setCity', resetCity())

      // 3. Then parse, validate and format the organisationId. All organisation numbers are 10 digits,
      // but we allow input of 12
      let addressFound = false
      store.commit('setOrganisationId', resetOrganisationId(organisationId))
      if (validInputs([store.state.organisationId], true)) {
        // Update organisation id twice to make sure that automatic formatting is applied (i.e. 10 digits)
        organisationId = store.state.organisationId.toOrganisationNumber()
        store.commit('setOrganisationId', resetOrganisationId(organisationId))

        // Inspect the organisationId and update debtor type
        if (store.state.organisationId.isCompany()) {
          debtorType = 'COMPANY'
        }

        // Commit the debtor option
        store.commit('caseDebtors/setCurrentDebtorType', debtorType, { root: true })
        // Call address lookup
        addressFound = await store.dispatch('lookupAddress', { reportError: false }) === true
      }

      // 4. Extract the result of the address lookup
      const name = addressFound ? store.state.name.value : ''
      const address = addressFound ? store.state.address.value : ''
      const postalCode = addressFound ? store.state.postalCode.value : 0
      const city = addressFound ? store.state.city.value : ''

      // 5. If address wasn't found empty organisationId to signal that something went wrong
      if (!addressFound) {
        organisationId = ''
      }

      // 6. Construct a Case Import
      const caseImport = <CaseImport> {
        row: index,
        containsErrors: false,
        selected: resetSelected(true),
        debtorType: resetDebtorOption(debtorType),
        organisationId: resetOrganisationId(organisationId),
        name: resetName(name),
        address: resetAddress(address),
        postalCode: resetPostalCode(postalCode),
        city: resetCity(city),
        invoiceNumber: resetInvoiceNumber(value.invoiceNumber),
        invoiceDescription: resetInvoiceDescription(value.invoiceDescription),
        invoiceDates: resetInvoiceDates(Formatting.parseDate(value.invoiceDate), Formatting.parseDate(value.invoiceDueDate)),
        remainingCapital: resetRemainingCapital(Formatting.parseNumber(value.remainingCapital)),
      }
    
      // 7. Validate Case Import
      const inputs = [
        caseImport.debtorType,
        caseImport.organisationId,
        caseImport.name,
        caseImport.address,
        caseImport.postalCode,
        caseImport.city,
        caseImport.invoiceNumber,
        caseImport.invoiceDescription,
        caseImport.invoiceDates[0],
        caseImport.invoiceDates[1],
        caseImport.remainingCapital,
      ]
      const valid = validInputs(inputs, true)
      if (valid !== null) {
        caseImport.containsErrors = !valid
      }

      // 8. Return a Case Import
      return caseImport
    }))

    const sortedCases = importCases.sort((lhs, rhs) => {
      if (lhs.containsErrors && !rhs.containsErrors) return -1
      if (!lhs.containsErrors && rhs.containsErrors) return 1
      return lhs.row - rhs.row
    })

    store.commit('setImportCases', sortedCases)

    return true
  },

  /**
   * 
   *
   * @param store The vuex store.
   */
  async importCSV(store): Promise<boolean | APIError> {
    console.log('IMPORT CASES')

    // Fetch and filter keeping selected cases
    const csv = store.state.importCases?.filter((it) => it.selected.value)
    if (csv === undefined) return true

    // Validate data
    const failures = csv.map((it) => {
      const inputs = [it.organisationId, it.name, it.address, it.postalCode, it.city, it.invoiceNumber, it.invoiceDescription, it.invoiceDates[0], it.invoiceDates[1], it.remainingCapital]
      return validInputs(inputs, true) ? '' : it.invoiceNumber.value ?? '?'
    }).filter((it) => it.length > 0)
    if (failures.length > 0) {
      store.commit('setAlert', importValidationFailed(failures[0]))
      return false
    }

    // Look-up dependent data
    const creditorDepartment: CreditorDepartment = store.rootGetters['creditorDepartments/getCurrentCreditorDepartment']

    const data: CasePostModel[] = csv.map((it) => <CasePostModel> {
      creditor_department_id: creditorDepartment.creditorDepartmentId,
      currency: 'SEK',
      start_action: 'REMINDER',
      debtor_main: {
        debtor_role: 'MAIN_DEBTOR',
        debtor_type: debtorTypeFromInput(it.debtorType),
        organisation_id: it.organisationId.toOrganisationNumber(),
        name: it.name.toString(),
        address_line_main: it.address.toString(),
        postal_code: it.postalCode.toNumber(),
        city: it.city.toString(),
        country_code: 'SE',
      },
      debts: [{
        debt_type: 'DEBIT',
        invoice_number: it.invoiceNumber.toString(),
        invoice_description: it.invoiceDescription.toString(),
        invoice_date: Formatting.formatDate(it.invoiceDates[0].toDate()),
        invoice_due_date: Formatting.formatDate(it.invoiceDates[1].toDate()),
        interest_from: Formatting.formatDate(it.invoiceDates[1].toDate()),
        interest_rate: 8,
        interest_type: 'LATE_PAYMENT_INTEREST',
        remaining_capital: it.remainingCapital.toNumber(),
      }],
    })

    const request = <APIRequest> {
      method: 'post',
      path: '/cases',
      data,
      action: 'cases/recieveImportCasePostResponseModels',
      mock: mock.mockCasePostResponseModels(data),
    }
    const result: true | APIError = await store.dispatch('creditorsAPI/call', request)
    if (result !== true) {
      apiError(store, result)
      return result
    }
    return true
  },

  /**
   * Recieves a responses for importing cases
   *
   * @param store The vuex store.
   * @param models The models for case import.
   */
  async recieveImportCasePostResponseModels(store, models: CasePostResponseModel[]) {
  },

  /**
   * Prepares the store for starting a new reminder registration
   *
   * @param store The vuex store.
   */
  async startCreatingReminder(store) {
    store.commit('setOrganisationId', resetOrganisationId())
    store.commit('setName', resetName())
    store.commit('setAddress', resetAddress())
    store.commit('setPostalCode', resetPostalCode())
    store.commit('setCity', resetCity())
    store.commit('setInvoiceNumber', resetInvoiceNumber())
    store.commit('setInvoiceDescription', resetInvoiceDescription())
    store.commit('setInvoiceDates', resetInvoiceDates())
    store.commit('setRemainingCapital', resetRemainingCapital())
  },

  async switchCurrentCaseId(store, caseId: number): Promise<boolean> {
    const cases: Case[] | undefined = store.state.ongoingCases?.items
    const c: Case | undefined = cases?.find((it) => it.caseId === caseId)
    if (c !== undefined) {
      store.commit('setCurrentCase', c)
      store.commit('setCurrentCaseMessagesUnread', c?.caseMessagesUnread ?? 0)
      return true
    }
    return applicationError(store, `Kan inte byta aktuellt ärende eftersom det efterfrågade ärendet, ${caseId}, inte kan hittas`)
  },

  /*
   * Lookup a debtors address.
   *
   * @param store The vuex store
   */
  async lookupAddress(store, { reportError }: { reportError: boolean }): Promise<true | APIError> {
    const organisationId = state.organisationId.toOrganisationNumber()
    const debtorOption: DebtorOption = store.rootGetters['caseDebtors/getCurrentDebtorOption']

    console.log(`LOOKUP ADDRESS -> ${debtorOption.title}: ${organisationId}`)

    // Empty fields before address lookup
    store.commit('setName', resetName())
    store.commit('setAddress', resetAddress())
    store.commit('setPostalCode', resetPostalCode())
    store.commit('setCity', resetCity())

    const personal = debtorOption.matches.includes('PRIVATE_PERSON')

    let request: APIRequest
    if (personal) {
      request = {
        method: 'get',
        path: `/personal-identity-number-lookup/${organisationId}`,
        action: 'cases/recieveCreditSafePersonalResponseModel',
        mock: mock.mockCreditSafePersonalResponseModel(organisationId),
      }
    } else {
      request = {
        method: 'get',
        path: `/company-registration-number-lookup/${organisationId}`,
        action: 'cases/recieveCreditSafeCompanyResponseModel',
        mock: mock.mockCreditSafeCompanyResponseModel(organisationId),
      }
    }

    console.log('REQUEST:', request)

    const result: true | APIError = await store.dispatch('creditorsAPI/call', request)
    if (result !== true) {
      if (reportError === true) {
        apiError(store, result)
      }
      return result
    }
    return true
  },

  async recieveCreditSafeCompanyResponseModel(store, model: CreditSafeCompanyResponseModel) {
    store.commit('setName', resetName(model.company_name))
    store.commit('setAddress', resetAddress(model.address))
    store.commit('setPostalCode', resetPostalCode(parseInt(model.zip_code, 10)))
    store.commit('setCity', resetCity(model.city))
  },

  async recieveCreditSafePersonalResponseModel(store, model: CreditSafePersonalResponseModel) {
    store.commit('setName', resetName(`${model.first_name ?? ''} ${model.last_name ?? ''}`.trim()))
    store.commit('setAddress', resetAddress(model.address))
    store.commit('setPostalCode', resetPostalCode(parseInt(model.zip_code, 10)))
    store.commit('setCity', resetCity(model.city))
  },

  async decrementUnreadCaseMessages(store): Promise<true | APIError> {
    console.log('DECREMENT UNREAD CASE MESSAGES')
    const currentCase: Case | undefined = store.getters.getCurrentCase

    if (currentCase === undefined) return missingError('currentCase')

    // Decrement the current unread messages count
    const currentCaseMessagesUnread: number = store.getters.getCurrentCaseMessagesUnread
    store.commit('setCurrentCaseMessagesUnread', currentCaseMessagesUnread - 1)

    // Update currently loaded cases
    const items = store.state.ongoingCases?.items ?? []
    items.forEach((it) => {
      if (it?.caseId === currentCase.caseId) {
        it.caseMessagesUnread -= 1
      }
    })

    return true
  },
}

export const cases: Module<CasesState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations,
  modules: {
    creditorsAPI,
  },
}
