import Vue from 'vue'
import store from '@/store'
import VueRouter from 'vue-router'
import DesignView from '@/views/Onboarding/DesignView.vue'
import OnboardingBankIDView from '@/views/Onboarding/OnboardingBankIDView.vue'
import OnboardingCookiesView from '@/views/Onboarding/OnboardingCookiesView.vue'
import OnboardingLoginView from '@/views/Onboarding/OnboardingLoginView.vue'
import OnboardingLogoutView from '@/views/Onboarding/OnboardingLogoutView.vue'
import OnboardingSignupSuccessView from '@/views/Onboarding/OnboardingSignupSuccessView.vue'
import OnboardingSignupContactView from '@/views/Onboarding/OnboardingSignupContactView.vue'
import OnboardingSignupCreditorView from '@/views/Onboarding/OnboardingSignupCreditorView.vue'
import OnboardingSignupStartView from '@/views/Onboarding/OnboardingSignupStartView.vue'
import OnboardingStartView from '@/views/Onboarding/OnboardingStartView.vue'
import CaseActionStartView from '@/views/Cases/CaseActionStartView.vue'
import CaseActionSuccessView from '@/views/Cases/CaseActionSuccessView.vue'
import CaseDebtorsView from '@/views/Cases/CaseDebtorsView.vue'
import CaseDebtsView from '@/views/Cases/CaseDebtsView.vue'
import CaseHistoryView from '@/views/Cases/CaseHistoryView.vue'
import CaseMessagesView from '@/views/Cases/CaseMessagesView.vue'
import CaseDetailsView from '@/views/Cases/CaseDetailsView.vue'
import CaseDocumentsView from '@/views/Cases/CaseDocumentsView.vue'
import CasePaymentStartView from '@/views/Cases/CasePaymentStartView.vue'
import CasePaymentSuccessView from '@/views/Cases/CasePaymentSuccessView.vue'
import CasesClosedView from '@/views/Cases/CasesClosedView.vue'
import CasesOngoingView from '@/views/Cases/CasesOngoingView.vue'
import CasesOverviewsView from '@/views/Cases/CasesOverviewsView.vue'
import CreditorDepartmentsView from '@/views/Creditors/CreditorDepartmentsView.vue'
import CreditorDepartmentEditView from '@/views/Creditors/CreditorDepartmentEditView.vue'
import CreditorDepartmentSuccessView from '@/views/Creditors/CreditorDepartmentSuccessView.vue'
import CreditorOverviewView from '@/views/Creditors/CreditorOverviewView.vue'
import CreditorUserDepartmentAccessesView from '@/views/Creditors/CreditorUserDepartmentAccessesView.vue'
import CreditorUsersView from '@/views/Creditors/CreditorUsersView.vue'
import CreditorUserEditView from '@/views/Creditors/CreditorUserEditView.vue'
import CreditorUserSuccessView from '@/views/Creditors/CreditorUserSuccessView.vue'
import EnrollCreditorView from '@/views/Enroll/EnrollCreditorView.vue'
import EnrollStartView from '@/views/Enroll/EnrollStartView.vue'
import EnrollSuccessView from '@/views/Enroll/EnrollSuccessView.vue'
import MessagesArchivedView from '@/views/Messages/MessagesArchivedView.vue'
import MessagesInboxView from '@/views/Messages/MessagesInboxView.vue'
import MessagesSentView from '@/views/Messages/MessagesSentView.vue'
import RegisterImportPreviewView from '@/views/Register/RegisterImportPreviewView.vue'
import RegisterImportStartView from '@/views/Register/RegisterImportStartView.vue'
import RegisterImportSuccessView from '@/views/Register/RegisterImportSuccessView.vue'
import RegisterManuallyDebtorView from '@/views/Register/RegisterManuallyDebtorView.vue'
import RegisterManuallyDebtView from '@/views/Register/RegisterManuallyDebtView.vue'
import RegisterManuallyStartView from '@/views/Register/RegisterManuallyStartView.vue'
import RegisterManuallySuccessView from '@/views/Register/RegisterManuallySuccessView.vue'
import SupportContactView from '@/views/Support/SupportContactView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/design',
    name: 'DesignView',
    component: DesignView,
  },
  {
    path: '/start',
    name: 'OnboardingStartView',
    component: OnboardingStartView,
  },
  {
    path: '/logout',
    name: 'OnboardingLogoutView',
    component: OnboardingLogoutView,
  },
  {
    path: '/cookies',
    name: 'OnboardingCookiesView',
    component: OnboardingCookiesView,
  },
  {
    path: '/',
    name: 'OnboardingLoginView',
    component: OnboardingLoginView,
    meta: { requiresToken: true },
  },
  {
    path: '/mbid',
    name: 'OnboardingBankIDView',
    component: OnboardingBankIDView,
    meta: { requiresToken: true },
  },
  {
    path: '/signup-start',
    name: 'OnboardingSignupStartView',
    component: OnboardingSignupStartView,
    meta: { requiresToken: true },
  },
  {
    path: '/signup-creditor',
    name: 'OnboardingSignupCreditorView',
    component: OnboardingSignupCreditorView,
    meta: { requiresToken: true },
  },
  {
    path: '/signup-contact',
    name: 'OnboardingSignupContactView',
    component: OnboardingSignupContactView,
    meta: { requiresToken: true },
  },
  {
    path: '/signup-success',
    name: 'OnboardingSignupSuccessView',
    component: OnboardingSignupSuccessView,
    meta: { requiresToken: true },
  },
  {
    path: '/cases',
    redirect: '/cases-overviews',
  },
  {
    path: '/cases-overviews',
    name: 'CasesOverviewsView',
    component: CasesOverviewsView,
    meta: { requiresToken: true },
  },
  {
    path: '/cases-closed',
    name: 'CasesClosedView',
    component: CasesClosedView,
    meta: { requiresToken: true },
  },
  {
    path: '/cases-ongoing',
    name: 'CasesOngoingView',
    component: CasesOngoingView,
    meta: { requiresToken: true },
  },
  {
    path: '/case-details',
    name: 'CaseDetailsView',
    component: CaseDetailsView,
    meta: { requiresToken: true },
  },
  {
    path: '/case-history',
    name: 'CaseHistoryView',
    component: CaseHistoryView,
    meta: { requiresToken: true },
  },
  {
    path: '/case-messages',
    name: 'CaseMessagesView',
    component: CaseMessagesView,
    meta: { requiresToken: true },
  },
  {
    path: '/case-debts',
    name: 'CaseDebtsView',
    component: CaseDebtsView,
    meta: { requiresToken: true },
  },
  {
    path: '/case-debtors',
    name: 'CaseDebtorsView',
    component: CaseDebtorsView,
    meta: { requiresToken: true },
  },
  {
    path: '/case-documents',
    name: 'CaseDocumentsView',
    component: CaseDocumentsView,
    meta: { requiresToken: true },
  },
  {
    path: '/case-payment-start',
    name: 'CasePaymentStartView',
    component: CasePaymentStartView,
    meta: { requiresToken: true },
  },
  {
    path: '/case-payment-success',
    name: 'CasePaymentSuccessView',
    component: CasePaymentSuccessView,
    meta: { requiresToken: true },
  },
  {
    path: '/case-action-start',
    name: 'CaseActionStartView',
    component: CaseActionStartView,
    meta: { requiresToken: true },
  },
  {
    path: '/case-action-success',
    name: 'CaseActionSuccessView',
    component: CaseActionSuccessView,
    meta: { requiresToken: true },
  },
  {
    path: '/register',
    redirect: '/register-manually-start',
  },
  {
    path: '/register-manually-start',
    name: 'RegisterManuallyStartView',
    component: RegisterManuallyStartView,
    meta: { requiresToken: true },
  },
  {
    path: '/register-manually-debtor',
    name: 'RegisterManuallyDebtorView',
    component: RegisterManuallyDebtorView,
    meta: { requiresToken: true },
  },
  {
    path: '/register-manually-debt',
    name: 'RegisterManuallyDebtView',
    component: RegisterManuallyDebtView,
    meta: { requiresToken: true },
  },
  {
    path: '/register-manually-success',
    name: 'RegisterManuallySuccessView',
    component: RegisterManuallySuccessView,
    meta: { requiresToken: true },
  },
  {
    path: '/register-import-start',
    name: 'RegisterImportStartView',
    component: RegisterImportStartView,
    meta: { requiresToken: true },
  },
  {
    path: '/register-import-preview',
    name: 'RegisterImportPreviewView',
    component: RegisterImportPreviewView,
    meta: { requiresToken: true },
  },
  {
    path: '/register-import-success',
    name: 'RegisterImportSuccessView',
    component: RegisterImportSuccessView,
    meta: { requiresToken: true },
  },
  {
    path: '/creditor',
    redirect: '/creditor-overview',
  },
  {
    path: '/creditor-overview',
    name: 'CreditorOverviewView',
    component: CreditorOverviewView,
    meta: { requiresToken: true },
  },
  {
    path: '/creditor-user-department-accesses',
    name: 'CreditorUserDepartmentAccessesView',
    component: CreditorUserDepartmentAccessesView,
    meta: { requiresToken: true },
  },
  {
    path: '/creditor-departments',
    name: 'CreditorDepartmentsView',
    component: CreditorDepartmentsView,
    meta: { requiresToken: true },
  },
  {
    path: '/creditor-department-edit',
    name: 'CreditorDepartmentEditView',
    component: CreditorDepartmentEditView,
    meta: { requiresToken: true },
  },
  {
    path: '/creditor-department-success',
    name: 'CreditorDepartmentSuccessView',
    component: CreditorDepartmentSuccessView,
    meta: { requiresToken: true },
  },
  {
    path: '/creditor-users',
    name: 'CreditorUsersView',
    component: CreditorUsersView,
    meta: { requiresToken: true },
  },
  {
    path: '/creditor-user-edit',
    name: 'CreditorUserEditView',
    component: CreditorUserEditView,
    meta: { requiresToken: true },
  },
  {
    path: '/creditor-user-success',
    name: 'CreditorUserSuccessView',
    component: CreditorUserSuccessView,
    meta: { requiresToken: true },
  },
  {
    path: '/enroll-start',
    name: 'EnrollStartView',
    component: EnrollStartView,
    meta: { requiresToken: true },
  },
  {
    path: '/enroll-creditor',
    name: 'EnrollCreditorView',
    component: EnrollCreditorView,
    meta: { requiresToken: true },
  },
  {
    path: '/enroll-success',
    name: 'EnrollSuccessView',
    component: EnrollSuccessView,
    meta: { requiresToken: true },
  },
  {
    path: '/messages-inbox',
    name: 'MessagesInboxView',
    component: MessagesInboxView,
    meta: { requiresToken: true },
  },
  {
    path: '/messages-sent',
    name: 'MessagesSentView',
    component: MessagesSentView,
    meta: { requiresToken: true },
  },
  {
    path: '/messages-archived',
    name: 'MessagesArchivedView',
    component: MessagesArchivedView,
    meta: { requiresToken: true },
  },
  {
    path: '/support-contact',
    name: 'SupportContactView',
    component: SupportContactView,
    meta: { requiresToken: true },
  },
]

type Position = { x: number; y: number }
const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior: (to, from, savedPosition) => <Position> { x: 0, y: 0 },
})

router.beforeEach((to, from, next) => {
  const token = store.getters['creditorsAPI/getToken']
  const requiresToken = to.matched.some((record) => record.meta.requiresToken === true)

  if (requiresToken && token === undefined) {
    location.href = `${process.env.VUE_APP_BACKEND_BASE_URL}/authenticates/app-init/${process.env.VUE_APP_APP_ID}`
    next(false)
  } else store.dispatch('navigation/switchActiveRoute', to)
  next()
})

export default router
