import { Pagination, PageModel } from '@/store/modules/creditorsAPI'
import {
  CreditorUsersGetModel,
  CreditorUsersPostModel,
  CreditorUsersPostResponseModel,
  CreditorUsersDetailsModel,
  CreditorUsersPatchResponseModel,
  CreditorUsersPatchModel,
} from '@/models/creditors'
import { CreditorUser } from '@/store/modules/creditorUsers'

export function mockCreditorUsersDetailsModel(current?: CreditorUser): CreditorUsersDetailsModel {
  return {
  }
}

export function mockCreditorUsersPostResponseModel(data: CreditorUsersPostModel[]): CreditorUsersPostResponseModel[] {
  return [{
    creditor_user_id: 28405,
    _etag: 'afkjalsdfj',
  }]
}

export function mockCreditorUsersPatchResponseModel(creditorUserId: number, data: CreditorUsersPatchModel): CreditorUsersPatchResponseModel {
  return {
    _etag: 'xyz',
  }
}

export function mockCreditorUsersGetModel(pagination: Pagination): PageModel<CreditorUsersGetModel> {
  const items: CreditorUsersGetModel[] = [
  ]

  // No filtering
  let results = items
  const total = results.length

  // Paging
  const start = (pagination.page - 1) * pagination.maxResults
  const end = start + pagination.maxResults
  results = results.slice(start, end)

  return {
    _total: total,
    _max_results: pagination.maxResults,
    _page: pagination.page,
    extra: undefined,
    _items: results,
  }
}
