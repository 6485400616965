class NumberParser {
  private _group: RegExp
  private _decimal: RegExp
  private _numeral: RegExp
  private _index: (d: string) => string

  constructor(locale: string) {
    const parts = new Intl.NumberFormat(locale).formatToParts(12345.6)
    const numerals = [...new Intl.NumberFormat(locale, { useGrouping: false }).format(9876543210)].reverse()
    const index = new Map(numerals.map((d, i) => [d, i.toString()]))
    this._group = new RegExp(`[${parts.find((d) => d.type === 'group')?.value}]`, 'g')
    this._decimal = new RegExp(`[${parts.find((d) => d.type === 'decimal')?.value}]`)
    this._numeral = new RegExp(`[${numerals.join('')}]`, 'g')
    this._index = (d) => index.get(d) ?? ''
  }
  parse(value: string): number | undefined {
    // console.log('PARSE: ', this._group, this._decimal, this._numeral)
    const step1 = value.trim()
    // console.log('PARSE, step 1: ', step1)
    const step2 = step1.replace(this._group, '')
    // console.log('PARSE, step 2: ', step2)
    const step3 = step2.replace(this._decimal, '.')
    // console.log('PARSE, step 3: ', step3)
    const step4 = step3.replace(this._numeral, this._index)
    // console.log('PARSE, step 4: ', step4)
    const step5 = Number(step4)
    // console.log('PARSE, step 5: ', step5)
    return isNaN(step5) ? undefined : step5
  }
}

const dateFormatter = new Intl.DateTimeFormat('sv-SE', {
  year: 'numeric',
  month: '2-digit',
  day: '2-digit',
})

const amountFormatter = new Intl.NumberFormat('sv-SE', {
  style: 'currency',
  currency: 'SEK',
  minimumFractionDigits: 2,
})

const numberFormatter = new Intl.NumberFormat('sv-SE', {
  style: 'decimal',
})

const fixedNumberFormatter = new Intl.NumberFormat('sv-SE', {
  style: 'decimal',
  minimumFractionDigits: 2,
})

const numberParser = new NumberParser('sv-SE')

export class Formatting {
  static formatDate(value: Date | undefined): string {
    if (value === undefined) return ''
    return dateFormatter.format(value)
  }

  static formatAmount(value: number | undefined): string {
    if (value === undefined) return ''
    return amountFormatter.format(value)
  }

  static formatNumber(value: number | undefined): string {
    if (value === undefined) return ''
    return numberFormatter.format(value)
  }

  static formatFixedNumber(value: number | undefined): string {
    if (value === undefined) return ''
    return fixedNumberFormatter.format(value)
  }

  static parseDate(value: string | undefined): Date | undefined {
    if (value === undefined) return undefined
    const result = Date.parse(value)
    return isNaN(result) ? undefined : new Date(result)
  }

  static parseNumber(value: string | undefined): number | undefined {
    if (value === undefined) return undefined
    return numberParser.parse(value)
  }
}
