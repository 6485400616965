
import Vue from 'vue'
import Component from 'vue-class-component'
import router from '@/router'
import { namespace } from 'vuex-class'

const CreditorsAPI = namespace('creditorsAPI')

@Component
export default class OnboardingStartView extends Vue {
  @CreditorsAPI.Mutation setToken!: (token?: string) => void

  created() {
    const { token } = this.$route.query
    if (typeof token === 'string') {
      console.log(token)
      this.setToken(token)
    }
    router.push('/')
  }
}
