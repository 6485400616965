
import Vue from 'vue'
import router from '@/router'
import { namespace } from 'vuex-class'
import { State2Way } from 'vuex-class-state2way'
import Component from 'vue-class-component'
import { MenuBar } from '@/models/menus'
import { CaseDebt } from '@/store/modules/caseDebts'
import { Alert } from '@/models/alerts'
import { Page, APIError } from '@/store/modules/creditorsAPI'
import { FieldType } from '@/components/NordicCoolTable.vue'

const Authentications = namespace('authentications')
const CaseDebts = namespace('caseDebts')
const Navigation = namespace('navigation')

@Component
export default class CaseDebtsView extends Vue {
  @Navigation.Getter getMenuBar!: MenuBar

  @Authentications.Action switchCurrentCreditor!: (creditorId: number) => Promise<true | APIError>

  @CaseDebts.Action refreshCaseDebts!: (page: number) => Promise<Page<CaseDebt> | APIError>

  @State2Way('caseDebts/setAlert', (state) => state.caseDebts.alert) alert!: Alert | undefined

  async switchCreditor(creditorId: number) {
    if (await this.switchCurrentCreditor(creditorId) === true) router.push('/cases-overviews')
  }

  caseDebtItems(page: number): Promise<Page<CaseDebt> | APIError> {
    return this.refreshCaseDebts(page)
  }

  get caseDebtFields(): FieldType[] {
    return [
      {
        key: 'debtType', label: this.$i18n.tc('common.fields.type'), sortable: true, size: 'small', alignment: 'left',
      },
      {
        key: 'invoiceNumber', label: this.$i18n.tc('common.fields.invoiceNumber'), sortable: false, size: 'medium', alignment: 'left',
      },
      {
        key: 'invoiceDescription', label: this.$i18n.tc('common.fields.description'), sortable: false, size: 'large', alignment: 'left',
      },
      {
        key: 'invoiceDate', label: this.$i18n.tc('common.fields.invoiceDate'), sortable: false, size: 'small', alignment: 'left',
      },
      {
        key: 'invoiceDueDate', label: this.$i18n.tc('case.details.debts.invoiceDueDate'), sortable: false, size: 'small', alignment: 'left',
      },
      {
        key: 'remainingCapital', label: this.$i18n.tc('common.fields.remainingCapital'), sortable: false, size: 'small', alignment: 'right',
      },
      {
        key: 'remainingInterest', label: this.$i18n.tc('common.fields.remainingInterest'), sortable: false, size: 'small', alignment: 'right',
      },
    ]
  }
}
