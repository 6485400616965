import { ActionTree, GetterTree, Module, MutationTree } from 'vuex'
import { RootState } from '@/store/index'
import { creditorsAPI, APIRequest, Pagination, PageModel, Page, APIError } from '@/store/modules/creditorsAPI'
import { CreditorUserMessageGroupsGetModel } from '@/models/creditors'
import { Alert, apiError } from '@/models/alerts'
import * as mock from '@/mockdata/creditorUserMessageGroups'

export interface CreditorUserMessageGroup {
  groupMessageId: number;
  name: string;
}

export interface CreditorUserMessageGroupState {
  alert: Alert | undefined;
  creditorUserMessageGroups?: Page<CreditorUserMessageGroup>;
}

export const state: CreditorUserMessageGroupState = {
  alert: undefined,
  creditorUserMessageGroups: undefined,
}

const namespaced = true

export const getters: GetterTree<CreditorUserMessageGroupState, RootState> = {
  getAlert(state): Alert | undefined {
    return state.alert
  },
  getAllCreditorUserMessageGroups(state): CreditorUserMessageGroup[] {
    return state.creditorUserMessageGroups?.items ?? []
  },
  getCreditorUserMessageGroups(state): Page<CreditorUserMessageGroup> | undefined {
    return state.creditorUserMessageGroups
  },
}

export const mutations: MutationTree<CreditorUserMessageGroupState> = {
  setAlert(state, alert: Alert | undefined) {
    state.alert = alert
  },
  setCreditorUserMessageGroups(state, creditorUserMessageGroups: Page<CreditorUserMessageGroup>) {
    state.creditorUserMessageGroups = creditorUserMessageGroups
  },
}

export const actions: ActionTree<CreditorUserMessageGroupState, RootState> = {
  async refreshCreditorUserMessageGroups(store, page: number): Promise<Page<CreditorUserMessageGroup> | APIError> {
    console.log(`REFRESHING CREDITOR USER MESSAGE GROUPS ${page} START`)

    // Build pagination from requested page
    const pagination = new Pagination(50, page)

    const request = <APIRequest> {
      method: 'get',
      path: '/creditor-user-message-groups',
      action: 'creditorUserMessageGroups/recieveCreditorUserMessageGroupsGetModel',
      mock: mock.mockCreditorUserMessageGroupsGetModel(pagination),
      paging: pagination,
    }
    const result: true | APIError = await store.dispatch('creditorsAPI/call', request)
    if (result !== true) {
      apiError(store, result)
      return result
    }
    return store.getters.getCreditorUserMessageGroups
  },

  async recieveCreditorUserMessageGroupsGetModel(store, page: PageModel<CreditorUserMessageGroupsGetModel>) {
    // Transform
    const creditorUserMessageGroups: Page<CreditorUserMessageGroup> = Page.transform(page, ((it) => ({
      groupMessageId: it.group_message_id,
      name: it.name,
    })))
    store.commit('setCreditorUserMessageGroups', creditorUserMessageGroups)

    console.log('REFRESHING CREDITOR USER MESSAGE GROUPS END')
  },
}

export const creditorUserMessageGroups: Module<CreditorUserMessageGroupState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations,
  modules: {
    creditorsAPI,
  },
}
