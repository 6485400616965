
import Vue from 'vue'
import router from '@/router'
import { namespace } from 'vuex-class'
import { State2Way } from 'vuex-class-state2way'
import Component from 'vue-class-component'
import { MenuBar } from '@/models/menus'
import { CaseHistoryLog } from '@/store/modules/caseHistoryLogs'
import { Alert } from '@/models/alerts'
import { Page, APIError } from '@/store/modules/creditorsAPI'
import { FieldType } from '@/components/NordicCoolTable.vue'

const Authentications = namespace('authentications')
const CaseHistoryLogs = namespace('caseHistoryLogs')
const Navigation = namespace('navigation')

@Component
export default class CaseHistoryView extends Vue {
  @Navigation.Getter getMenuBar!: MenuBar

  @Authentications.Action switchCurrentCreditor!: (creditorId: number) => Promise<true | APIError>
  @CaseHistoryLogs.Action refreshCaseHistoryLogs!: (page: number) => Promise<Page<CaseHistoryLog> | APIError>

  @State2Way('caseHistoryLogs/setAlert', (state) => state.caseHistoryLogs.alert) alert!: Alert | undefined

  async switchCreditor(creditorId: number) {
    if (await this.switchCurrentCreditor(creditorId) === true) router.push('/cases-overviews')
  }

  caseHistoryLogItems(page: number): Promise<Page<CaseHistoryLog> | APIError> {
    return this.refreshCaseHistoryLogs(page)
  }

  get fields(): FieldType[] {
    return [
      {
        key: 'date', label: this.$i18n.tc('common.fields.date'), sortable: false, size: 'xsmall', alignment: 'left',
      },
      {
        key: 'headline', label: this.$i18n.tc('case.details.history.headline'), sortable: false, size: 'flex', alignment: 'left',
      },
    ]
  }
}
