import { Pagination, PageModel } from '@/store/modules/creditorsAPI'
import { SentToType } from '@/models/messages'
import { CreditorUserMessagesGetModel } from '@/models/creditors'

export function mockCreditorUserMessagesGetModel(pagination: Pagination, { isRead, sentTo }: { isRead?: boolean; sentTo: SentToType }): PageModel<CreditorUserMessagesGetModel> {
  // Filter on is_read and sent_to
  let results = mockCreditorUserMessagesGetModels.filter((it) => {
    if (isRead !== undefined) return it.is_read === isRead && it.sent_to === sentTo
    return it.sent_to === sentTo
  })
  results.sort((lhs, rhs) => (lhs.create_date < rhs.create_date ? 1 : -1))
  const total = results.length

  // Paging
  const start = (pagination.page - 1) * pagination.maxResults
  const end = start + pagination.maxResults
  results = results.slice(start, end)

  return {
    _total: total,
    _max_results: pagination.maxResults,
    _page: pagination.page,
    extra: { isRead, sentTo },
    _items: results,
  }
}

const mockCreditorUserMessagesGetModels: CreditorUserMessagesGetModel[] = [
]
