export class MenuItem {
  title: string
  paths: string[]
  active: boolean
  visible: boolean
  disabled: boolean
  external: boolean
  dot: boolean
  sub?: Menu

  // A MenuItem can be known by many paths, but the first one is the primary
  get path(): string | undefined { return this.paths[0] }

  constructor({ title, active, visible, dot, paths, disabled, external, sub }: { title: string, active: MenuItem[], visible: boolean, dot: boolean, paths: string[], disabled: boolean, external: boolean, sub: Menu | undefined }) {
    this.title = title
    // Check intersection between paths
    this.active = active.filter((it) => it.paths.filter((it) => paths.includes(it)).length > 0).length > 0
    this.visible = visible
    this.paths = paths
    this.disabled = disabled
    this.external = external
    this.dot = dot
    this.sub = sub
  }

  static standard({ title, active, visible, path }: { title: string, active: MenuItem[], visible: boolean, path: string | undefined }): MenuItem {
    return new MenuItem({ title: title, active: active, visible: visible, paths: path !== undefined ? [path] : [], disabled: false, external: false, dot: false, sub: undefined })
  }

  static group({ title, active, visible, paths }: { title: string, active: MenuItem[], visible: boolean, paths: string[] }): MenuItem {
    return new MenuItem({ title: title, active: active, visible: visible, paths: paths, disabled: false, external: false, dot: false, sub: undefined })
  }

  static submenu({ title, active, visible, path, sub }: { title: string, active: MenuItem[], visible: boolean, path: string | undefined, sub: Menu | undefined }): MenuItem {
    return new MenuItem({ title: title, active: active, visible: visible, paths: path !== undefined ? [path] : [], disabled: false, external: false, dot: false, sub: sub })
  }

  static placeholder({ title, active, visible }: { title: string, active: MenuItem[], visible: boolean }): MenuItem {
    return new MenuItem({ title: title, active: active, visible: visible, paths: [], disabled: true, external: false, dot: false, sub: undefined })
  }

  static standardDotted({ title, active, visible, dot, path }: { title: string, active: MenuItem[], visible: boolean, dot: boolean, path: string | undefined }): MenuItem {
    return new MenuItem({ title: title, active: active, visible: visible, paths: path !== undefined ? [path] : [], disabled: false, external: false, dot: dot, sub: undefined })
  }

  static submenuDotted({ title, active, visible, dot, path, sub }: { title: string, active: MenuItem[], visible: boolean, dot: boolean, path: string | undefined, sub: Menu | undefined }): MenuItem {
    return new MenuItem({ title: title, active: active, visible: visible, paths: path !== undefined ? [path] : [], disabled: false, external: false, dot: dot, sub: sub })
  }

  static hidden({ title, active, path }: { title: string, active: MenuItem[], path: string | undefined }): MenuItem {
    return new MenuItem({ title: title, active: active, visible: false, paths: path !== undefined ? [path] : [], disabled: true, external: false, dot: false, sub: undefined })
  }

  static external({ title, active, visible, path }: { title: string, active: MenuItem[], visible: boolean, path: string | undefined }): MenuItem {
    return new MenuItem({ title: title, active: active, visible: visible, paths: path !== undefined ? [path] : [], disabled: false, external: true, dot: false, sub: undefined })
  }

  static divider({ visible }: { visible: boolean }): Divider {
    return new Divider({ visible: visible })
  }

  match(path: string): MenuItem[] {
    // Depth first, find matches as deep as we can
    const matches = this.sub?.match(path) ?? []
    // If a matches were found in one of our children prepend ourselves
    if (matches.length > 0) matches.unshift(this)
    // Check ourselves, we may have a match
    if (matches.length === 0 && this.paths.includes(path)) matches.unshift(this)
    return matches
  }

  activeMenuItems(): MenuItem[] {
    // Depth first, try to find a menu item that is active below us
    const activeMenuItems = this.sub?.activeMenuItems() ?? []
    // If a active menu items were found in one of our children prepend ourselves
    if (activeMenuItems.length > 0) activeMenuItems.unshift(this)
    // Check ourselves, we may have a match
    if (activeMenuItems.length === 0 && this.active) activeMenuItems.unshift(this)
    return activeMenuItems
  }
}

export class Divider {
  visible: boolean

  constructor({ visible }: { visible: boolean }) {
    this.visible = visible
  }
}

export class Back {
  title: string

  path?: string

  visible = true

  constructor(menuItem: MenuItem) {
    this.title = menuItem.title
    this.path = menuItem.path
  }
}

export class Menu {
  items: (MenuItem | Back | Divider)[]

  constructor(items: (MenuItem | Back | Divider)[]) {
    this.items = items
  }

  match(path: string): MenuItem[] {
    return this.items
      // Remove non MenuItems
      .flatMap((it) => (isMenuItem(it) ? [it] : []))
      .flatMap((it) => it.match(path))
  }

  activeMenuItems(): MenuItem[] {
    return this.items
      // Remove non MenuItems
      .flatMap((it) => (isMenuItem(it) ? [it] : []))
      .flatMap((it) => it.activeMenuItems())
  }
}

export class Company {
  id: number
  title: string
  selected: boolean

  constructor(id: number, title: string, selected: boolean) {
    this.id = id
    this.title = title
    this.selected = selected
  }
}

export class ExtendedMenu {
  fullName: string
  companyName: string
  companies: Company[]
  menu: Menu

  constructor(fullName: string, companyName: string, companies: Company[], menu: Menu) {
    this.fullName = fullName
    this.companyName = companyName
    this.companies = companies
    this.menu = menu
  }

  match(path: string): MenuItem[] {
    return this.menu.match(path)
  }

  activeMenuItems(): MenuItem[] {
    return this.menu.activeMenuItems()
  }
}

export class MenuBar {
  appMenuItem: MenuItem
  topMenu: Menu
  extendedMenu: ExtendedMenu

  constructor(appMenuItem: MenuItem, topMenu: Menu, extendedMenu: ExtendedMenu) {
    this.appMenuItem = appMenuItem
    this.topMenu = topMenu
    this.extendedMenu = extendedMenu
  }

  match(path: string): MenuItem[] {
    return this.topMenu.match(path).concat(this.extendedMenu.match(path))
  }

  activeMenuItems(): MenuItem[] {
    return this.topMenu.activeMenuItems().concat(this.extendedMenu.activeMenuItems())
  }

  currentSubMenu(): Menu | undefined {
    const activeMenuItems = this.activeMenuItems()
    if (activeMenuItems === undefined || activeMenuItems.length < 2) return undefined
    // The current sub menu is the one containing the active menu item, i.e the next to last
    return activeMenuItems[activeMenuItems.length - 2]?.sub
  }

  hasSubMenu(): boolean {
    const currentSubMenu = this.currentSubMenu()
    if (currentSubMenu !== undefined) {
      const visibleItems = currentSubMenu.items.filter((it) => it.visible)
      return visibleItems.length > 0
    }
    return false
  }
}

export function isMenuItem(item: MenuItem | Back | Divider): item is MenuItem {
  return item instanceof MenuItem
}

export function isBack(item: MenuItem | Back | Divider): item is Back {
  return item instanceof Back
}

export function isDivider(item: MenuItem | Back | Divider): item is Back {
  return item instanceof Divider
}
