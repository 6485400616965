
import Vue from 'vue'
import router from '@/router'
import Component from 'vue-class-component'
import { namespace } from 'vuex-class'
import { State2Way } from 'vuex-class-state2way'
import { Alert } from '@/models/alerts'
import { InputText, InputNumber, InputDate, validInputs } from '@/models/validations'
import { MenuBar } from '@/models/menus'
import { DebtorOption } from '@/store/modules/caseDebtors'
import { CreditorDepartment } from '@/store/modules/creditorDepartments'
import { APIError } from '@/store/modules/creditorsAPI'

const Authentications = namespace('authentications')
const Cases = namespace('cases')
const Navigation = namespace('navigation')

@Component
export default class RegisterManuallyDebtView extends Vue {
  @Navigation.Getter getMenuBar!: MenuBar

  @Authentications.Action switchCurrentCreditor!: (creditorId: number) => Promise<true | APIError>
  @Cases.Action createReminder!: () => Promise<true | APIError>

  @State2Way('cases/setAlert', (state) => state.cases.alert) alert!: Alert | undefined
  @State2Way('cases/setInvoiceNumber', (state) => state.cases.invoiceNumber) invoiceNumber!: InputText
  @State2Way('cases/setInvoiceDescription', (state) => state.cases.invoiceDescription) invoiceDescription!: InputText
  @State2Way('cases/setInvoiceDates', (state) => state.cases.invoiceDates) invoiceDates!: [InputDate, InputDate]
  @State2Way('cases/setRemainingCapital', (state) => state.cases.remainingCapital) remainingCapital!: InputNumber

  strict = false

  async reset() {
    this.strict = false
  }

  async switchCreditor(creditorId: number) {
    if (await this.switchCurrentCreditor(creditorId) === true) router.push('/cases-overviews')
  }

  async create() {
    const inputs = [this.invoiceNumber, this.invoiceDescription, this.invoiceDates[0], this.invoiceDates[1], this.remainingCapital]
    this.strict = true
    if (validInputs(inputs, this.strict)) {
      if (await this.createReminder()) router.push('/register-manually-success')
    }
  }

  previous() {
    router.push('/register-manually-debtor')
  }
}
