
import { InputOption, InputSelect } from '@/models/validations'
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'

@Component
export default class NordicCoolInputSelect extends Vue {
  @Prop(String) readonly id: string | undefined
  @Prop(String) readonly label: string | undefined
  @Prop(Object) readonly value: InputSelect | undefined
  @Prop(Boolean) readonly disabled: boolean | undefined
  @Prop(Boolean) readonly strict: boolean | undefined
  @Prop({ type: Function, default: undefined }) readonly reset!: () => void

  get options(): InputOption[] {
    let result: InputOption[] = []
    const options = this.value?.options ?? null
    if (options !== null) {
      result = [...result, ...options]
    }
    return result
  }

  get localValue(): number {
    return this.value?.value ?? 0
  }

  set localValue(localValue: number) {
    if (this.reset !== undefined) this.reset()
    if (this.value !== undefined) {
      const input = this.value
      input.value = localValue
      this.$emit('input', input)
    }
  }
}
