
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { MenuBar } from '@/models/menus'

@Component
export default class NordicCoolPage extends Vue {
  @Prop(Object) readonly menuBar!: MenuBar
  @Prop(Boolean) readonly busy: boolean | undefined

  get hasSubMenu(): boolean { return this.menuBar.hasSubMenu() }
  get isBusy(): boolean { return this.busy ?? false }

  get styles(): string {
    var styles = ''
    if (this.hasSubMenu) {
      styles += 'sub-page-padding pl-4 pr-4 pb-4'
    } else {
      styles += 'page-padding pl-4 pr-4 pb-4'
    }
    if (this.isBusy) {
      styles += ' page-busy'
    }
    return styles
  }
}
