
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { InputCheckbox, validInputCheckbox } from '@/models/validations'

@Component
export default class NordicCoolInputCheckbox extends Vue {
  @Prop(String) readonly id: string | undefined
  @Prop(String) readonly label: string | undefined
  @Prop(String) readonly body: string | undefined
  @Prop(String) readonly buttonTitle: string | undefined
  @Prop(String) readonly buttonLink: string | undefined
  @Prop(Object) readonly value: InputCheckbox | undefined
  @Prop(Boolean) readonly disabled: boolean | undefined
  @Prop(Boolean) readonly strict: boolean | undefined
  @Prop({ type: Function, default: undefined }) readonly reset!: () => void

  get state(): true | false | null {
    if (this.disabled === true) return null
    if (this.value === undefined) {
      return null
    }
    return validInputCheckbox(this.value, this.strict ?? false)
  }

  get localValue(): boolean {
    if (this.value === undefined) {
      return false
    }
    return this.value.value ?? false
  }

  set localValue(localValue: boolean) {
    if (this.reset !== undefined) this.reset()
    if (this.value !== undefined) {
      const input = this.value
      input.value = localValue
      this.$emit('input', input)
    }
  }
}
