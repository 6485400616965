import { ActionTree, GetterTree, Module, MutationTree } from 'vuex'
import { RootState } from '@/store/index'
import { creditorsAPI, APIRequest, Pagination, PageModel, Page, APIError } from '@/store/modules/creditorsAPI'
import { CreditorUserDepartmentAccessesGetModel, CreditorUserDepartmentAccessesPostModel, CreditorUserDepartmentAccessesPostResponseModel } from '@/models/creditors'
import { Alert, apiError } from '@/models/alerts'
import * as mock from '@/mockdata/creditorUserDepartmentAccesses'

export interface CreditorUserDepartmentAccess {
  creditorUserId: number;
  creditorDepartmentId: number;
}

export interface CreditorUserDepartmentAccessesState {
  alert: Alert | undefined;
  creditorUserDepartmentAccesses?: Page<CreditorUserDepartmentAccess>;
}

export const state: CreditorUserDepartmentAccessesState = {
  alert: undefined,
  creditorUserDepartmentAccesses: undefined,
}

const namespaced = true

export const getters: GetterTree<CreditorUserDepartmentAccessesState, RootState> = {
  getAlert(state): Alert | undefined {
    return state.alert
  },
  getAllCreditorUserDepartmentAccesses(state): CreditorUserDepartmentAccess[] {
    return state.creditorUserDepartmentAccesses?.items ?? []
  },
  getCreditorUserDepartmentAccesses(state): Page<CreditorUserDepartmentAccess> | undefined {
    return state.creditorUserDepartmentAccesses
  },
}

export const mutations: MutationTree<CreditorUserDepartmentAccessesState> = {
  setAlert(state, alert: Alert | undefined) {
    state.alert = alert
  },
  setCreditorUserDepartmentAccesses(state, creditorUserDepartmentAccesses: Page<CreditorUserDepartmentAccess>) {
    state.creditorUserDepartmentAccesses = creditorUserDepartmentAccesses
  },
}

export const actions: ActionTree<CreditorUserDepartmentAccessesState, RootState> = {
  async refreshCreditorUserDepartmentAccesses(store, page: number): Promise<Page<CreditorUserDepartmentAccess> | APIError> {
    console.log('REFRESHING CREDITOR USER DEPARTMENT ACCESSES')

    // Build pagination from requested page
    const pagination = new Pagination(50, page)

    const request = <APIRequest> {
      method: 'get',
      path: '/creditor-user-department-accesses',
      action: 'creditorUserDepartmentAccesses/recieveCreditorUserDepartmentAccessesGetModel',
      mock: mock.mockCreditorUserDepartmentAccessesGetModel(pagination),
      paging: pagination,
    }
    const result: true | APIError = await store.dispatch('creditorsAPI/call', request)
    if (result !== true) {
      apiError(store, result)
      return result
    }
    return store.getters.getCreditorUserMessageGroups
  },

  async recieveCreditorUserDepartmentAccessesGetModel(store, page: PageModel<CreditorUserDepartmentAccessesGetModel>) {
    // Transform
    const creditorUserDepartmentAccess: Page<CreditorUserDepartmentAccess> = Page.transform(page, ((it) => ({
      creditorUserId: it.creditor_user.creditor_id,
      creditorDepartmentId: it.creditor_department.creditor_department_id,
    })))
    store.commit('setCreditorUserDepartmentAccesses', creditorUserDepartmentAccess)
  },

  /**
   * Creates a new creditor user department access
   *
   * @param store The vuex store.
   */
  async createCreditorUserDepartmentAccesses(store, { creditorUserId, creditorDepartmentIds }: { creditorUserId: number, creditorDepartmentIds: number[] }): Promise<true | APIError> {
    console.log('CREATE CREDITOR USER DEPARTMENT ACCESS')

    const data: CreditorUserDepartmentAccessesPostModel[] = creditorDepartmentIds.map((it) => ({
      creditor_user_id: creditorUserId,
      creditor_department_id: it,
    }))

    const request: APIRequest = {
      method: 'post',
      path: '/creditor-user-department-accesses',
      data,
      action: 'creditorUserDepartmentAccesses/recieveCreditorUserDepartmentAccessesPostResponseModel',
      mock: mock.mockCreditorUserDepartmentAccessesPostResponseModel(data),
    }
    const result: true | APIError = await store.dispatch('creditorsAPI/call', request)
    if (result !== true) {
      apiError(store, result)
      return result
    }
    return true
  },

  /**
   * Recieves a response for creating a new creditor user department access
   *
   * @param store The vuex store.
   * @param response The model for creditor user department access creation.
   */
  async recieveCreditorUserDepartmentAccessesPostResponseModel(store, model: CreditorUserDepartmentAccessesPostResponseModel[]) {
  },
}

export const creditorUserDepartmentAccesses: Module<CreditorUserDepartmentAccessesState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations,
  modules: {
    creditorsAPI,
  },
}
