
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'

@Component
export default class NordicCoolConfirm extends Vue {
  @Prop(Boolean) readonly value: boolean | undefined
  @Prop(String) readonly message: string | undefined
  @Prop(String) readonly confirm: string | undefined
  @Prop({ type: Function, default: undefined }) readonly next: Function | undefined

  get show(): boolean {
    return this.value === true
  }

  proceed() {
    this.$emit('input', false)
    if (this.next !== undefined) this.next()
  }

  close() {
    this.$emit('input', false)
  }
}
