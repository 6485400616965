import { Pagination, PageModel } from '@/store/modules/creditorsAPI'
import {
  CaseMessagesGetModel, CaseMessagesPostModel, CaseMessagesPostResponseModel, CaseMessagesPatchModel, CaseMessagesPatchResponseModel, SentToType,
} from '@/models/messages'

function randomIntFromInterval(min: number, max: number) { // min and max included
  return Math.floor(Math.random() * (max - min + 1) + min)
}

export function mockCaseMessagesPostModel(data: CaseMessagesPostModel): CaseMessagesPostResponseModel {
  const caseMessage = <CaseMessagesGetModel> {
  }
  mockCaseMessages.push(caseMessage)
  return {
  }
}

export function mockCaseMessagesPatchModel(caseMessageId: number, data: CaseMessagesPatchModel): CaseMessagesPatchResponseModel {
  const message = mockCaseMessages.find((it) => it.case_message_id === caseMessageId)
  if (message !== undefined) {
    message.is_read = data.is_read ?? message.is_read
  }
  return {
    _etag: 'xyz',
  }
}

export function mockCaseMessagesGetModel(pagination: Pagination, { caseId, sentTo }: { caseId: number; sentTo?: SentToType }): PageModel<CaseMessagesGetModel> {
  // Filter on sent_to
  let results = sentTo !== undefined ? mockCaseMessages.filter((it) => it.sent_to === sentTo) : mockCaseMessages
  results.sort((lhs, rhs) => (lhs.create_date < rhs.create_date ? 1 : -1))
  const total = results.length

  // Paging
  const start = (pagination.page - 1) * pagination.maxResults
  const end = start + pagination.maxResults
  results = results.slice(start, end)

  return {
    _total: total,
    _max_results: pagination.maxResults,
    _page: pagination.page,
    extra: { caseId, sentTo },
    _items: results,
  }
}

const mockCaseMessages: CaseMessagesGetModel[] = [
  {
    case_message_id: 166,
    create_date: '2020-03-27',
    subject: 'Övrigt',
    body: 'Innehållet',
    sent_to: 'TO_VISMA',
    is_read: false,
    label: '',
    client_id: 100000,
    user_id: 28405,
    group_message_id: 0,
    _etag: 'xyz',
  },
  {
    case_message_id: 167,
    create_date: '2020-03-22',
    subject: 'Övrigt',
    body: 'Starta om datorn',
    sent_to: 'TO_CREDITOR',
    is_read: true,
    label: '',
    client_id: 100000,
    user_id: 28405,
    group_message_id: 0,
    _etag: 'xyz',
  },
  {
    case_message_id: 168,
    create_date: '2020-04-07',
    subject: 'Övrigt',
    body: 'Skall test makuleras?',
    sent_to: 'TO_VISMA',
    is_read: false,
    label: '',
    client_id: 100000,
    user_id: 28405,
    group_message_id: 0,
    _etag: 'xyz',
  },
  {
    case_message_id: 168,
    create_date: '2020-04-07',
    subject: 'Övrigt',
    body: 'Det går bra att makulera! Hör av dig om det skulle vara några problem',
    sent_to: 'TO_CREDITOR',
    is_read: false,
    label: '',
    client_id: 100000,
    user_id: 28405,
    group_message_id: 0,
    _etag: 'xyz',
  },
]
