import i18n from '@/plugins/i18n'
import { DebtorType } from '@/models/debtors'

export type DebtType = 'DEBIT' | 'CREDIT'
export function translateDebtType(debt: DebtType): string {
  switch (debt.toLocaleUpperCase()) {
    case 'DEBIT': return i18n.tc('models.debt.DEBIT')
    case 'CREDIT': return i18n.tc('models.debt.CREDIT')
    default: return ''
  }
}

export type InterestType = 'FIXED' | 'LATE_PAYMENT_INTEREST' | 'MONTHLY'
export function translateInterestType(debt: InterestType): string {
  switch (debt) {
    case 'FIXED': return i18n.tc('models.interest.FIXED')
    case 'LATE_PAYMENT_INTEREST': return i18n.tc('models.interest.LATE_PAYMENT_INTEREST')
    case 'MONTHLY': return i18n.tc('models.interest.MONTHLY')
    default: return ''
  }
}

export interface CaseDebtsUtilitySimpleModel {
  address: string | null; // Address
  type: DebtorType | null; // Type of person
  moved: boolean | null; // If person have moved
  delivery_id: string | null; // Id used for the utility
}

export interface CaseDebtsUtilityResidenceModel {
  address: string | null; // Address
  type: DebtorType | null; // Type of person
  date_of_moved: string | null; // Date when moving out
  moved: boolean | null; // If person have moved
  apartment_number: string | null; // Apartment number
  size: string | null; // Apartment size
  rooms: string | null; // Number of rooms
}

export interface CaseDebtsUtilityPremisesModel {
  address: string | null; // Address
  type: DebtorType | null; // Type of person
  date_of_moved: string | null; // Date when moving out
  moved: boolean | null; // If person have moved
  facility_number: string | null; // Apartment number
  size: string | null; // Apartment size
}

export interface CaseDebtsUtilityModel {
  electricity: CaseDebtsUtilitySimpleModel;
  electricity_net: CaseDebtsUtilitySimpleModel;
  gas: CaseDebtsUtilitySimpleModel;
  water: CaseDebtsUtilitySimpleModel;
  heating: CaseDebtsUtilitySimpleModel;
  refuse: CaseDebtsUtilitySimpleModel;
  rent_residence: CaseDebtsUtilityResidenceModel;
  rent_premises: CaseDebtsUtilityPremisesModel;
  broad_band: CaseDebtsUtilitySimpleModel;
}

export interface CaseDebtsGetModel {
    debt_type: DebtType; // Type of debt - Debit or Credit
    invoice_number: string; // Invoice number. Max length: 20
    invoice_description: string; // Description of invoice
    invoice_date: string; // Date of invoice
    invoice_due_date: string | null; // Due date of invoice
    remaining_capital: number; // Remaining capital remains on debt
    remaining_interest: number; // Remaining interest remains on debt
    utility: CaseDebtsUtilityModel;
}

export interface CaseDebtsRowPostModel {
  line_number: number; // Row number
  article_number: string; // Article number, max_length=10
  article_description: string; // Article description, max_length=80
  unit: string; // Unit for article, max_length=10
  unit_price: number; // Unit price for article row
  vat_rate: number; // VAT rate for article row
  vat_amount: number; // VAT amount for article row
  quantity: number; // Quantity for article row
  discount: number; // Discount for article row
  discount_type: string; // Discount type for article row
  chargeable: boolean; // Debit: 1 - whole row. 0 - only article name
}

export interface CaseDebtsPostModel {
    debt_type: DebtType; // Type of debt
    invoice_number: string; // Invoice number, max_length=20
    debt_reference_number?: string; // Other information as debt reference, max_length=90
    invoice_number_to_credit?: string; // Invoice number to be credited
    invoice_description: string; // Description of invoice, max_length=150
    invoice_date: string; // Date of invoice
    invoice_due_date: string; // Due date of invoice
    interest_from: string; // Calculate interest from date
    interest_stop?: string; // Stop interest at this date
    interest_rate: number; // Percentage calculated interest with
    interest_type: InterestType; // Type of interest calculated
    initial_capital?: number; // Initial capital
    initial_interest?: number; // Initial interest
    initial_fee?: number; // Initial fee
    paid_capital?: number; // Paid capital on debt
    paid_interest?: number; // Paid interest on debt
    paid_fee?: number; // Paid fee on debt
    remaining_capital: number; // Remaining capital on debt
    remaining_interest?: number; // Remaining interest on debt
    remaining_fee?: number; // Remaining fee on debt
    our_reference?: string; // Sales man reference, max_length=199
    your_reference?: string; // Invoice receiver, max_length=199
    debt_rows?: CaseDebtsRowPostModel[];
    utility?: CaseDebtsUtilityModel;
}
