
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { Page } from '@/store/modules/creditorsAPI'

export type FieldSize = 'flex' | 'xsmall' | 'small' | 'medium' | 'large' | 'actions' | 'symbol' | 'text'
export type FieldAlignment = 'left' | 'right'
export type FieldSymbol = 'none' | 'dot' | 'lock'
export interface FieldType {
  key: string;
  label: string;
  sortable: boolean;
  size: FieldSize;
  alignment: FieldAlignment;
}

@Component
export default class NordicCoolTable extends Vue {
  @Prop(String) readonly id: string | undefined
  @Prop(Array) readonly fields: FieldType[] | undefined
  @Prop(Object) readonly extra: any | undefined
  @Prop({ type: Function, default: undefined }) readonly items!: (page: number, extra: any | undefined) => Promise<Page<any> | undefined>
  @Prop({ type: Function, default: undefined }) readonly viewClick: Function | undefined
  @Prop({ type: Function, default: undefined }) readonly editClick: Function | undefined
  @Prop({ type: Function, default: undefined }) readonly downloadClick: Function | undefined
  @Prop({ type: Function, default: undefined }) readonly hasSymbol: Function | undefined

  isBusy = false
  currentPage = 1
  perPage = 10
  total = 0

  get hasView(): boolean { return this.viewClick !== undefined }
  get hasEdit(): boolean { return this.editClick !== undefined }
  get hasDownload(): boolean { return this.downloadClick !== undefined }
  get hasPagination(): boolean { return this.isBusy === false && this.total > this.perPage }

  checkSymbol(data: any): FieldSymbol { return this.hasSymbol !== undefined ? this.hasSymbol(data.item) : 'none' }
  isDotSymbol(data: any): boolean { return this.checkSymbol(data) === 'dot' }
  isLockSymbol(data: any): boolean { return this.checkSymbol(data) === 'lock' }

  renderData(data: any): string {
    var html = ''
    if (Array.isArray(data.value)) {
      html = data.value.join('<br>')
    } else {
      html = data.value
    }
    return html
  }

  get styledFields(): { key: string; label: string; sortable: boolean; tdClass: string }[] {
    return this.fields?.map((it) => {
      switch (it.size) {
        case 'flex':
          return {
            key: it.key, label: it.label, sortable: it.sortable, tdClass: `table-${it.alignment}`, thClass: `table-flex table-${it.alignment}`,
          }
        case 'xsmall':
          return {
            key: it.key, label: it.label, sortable: it.sortable, tdClass: `table-${it.alignment}`, thClass: `table-xsmall table-${it.alignment}`,
          }
        case 'small':
          return {
            key: it.key, label: it.label, sortable: it.sortable, tdClass: `table-${it.alignment}`, thClass: `table-small table-${it.alignment}`,
          }
        case 'medium':
          return {
            key: it.key, label: it.label, sortable: it.sortable, tdClass: `table-${it.alignment}`, thClass: `table-medium table-${it.alignment}`,
          }
        case 'large':
          return {
            key: it.key, label: it.label, sortable: it.sortable, tdClass: `table-${it.alignment}`, thClass: `table-large table-${it.alignment}`,
          }
        case 'actions':
          return {
            key: it.key, label: it.label, sortable: it.sortable, tdClass: `table-${it.alignment} sticky-right`, thClass: `table-actions table-${it.alignment} sticky-right`,
          }
        case 'symbol':
          return {
            key: it.key, label: it.label, sortable: it.sortable, tdClass: `table-${it.alignment} pr-0 pl-1`, thClass: `table-symbol table-${it.alignment}`,
          }
        case 'text':
          return {
            key: it.key, label: it.label, sortable: it.sortable, tdClass: `table-${it.alignment}`, thClass: `table-text table-${it.alignment}`,
          }
        default:
          return {
            key: it.key, label: it.label, sortable: it.sortable, tdClass: '',
          }
      }
    }) ?? []
  }

  itemsProvider(ctx: { currentPage: number; perPage: number}, callback: (items: any[]) => void): any[] | null {
    if (this.items === undefined) {
      callback([])
    } else {
      this.items(ctx.currentPage, this.extra)
        .then((page) => {
          this.currentPage = page?.page ?? 1
          this.perPage = page?.maxResults ?? 10
          this.total = page?.total ?? 0
          callback(page?.items ?? [])
        })
        .catch(() => {
          callback([])
        })
    }
    return null
  }
}
