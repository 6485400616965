import {
  ActionTree, GetterTree, Module, MutationTree,
} from 'vuex'
import { RootState } from '@/store/index'
import {
  creditorsAPI, APIRequest, Pagination, PageModel, Page, APIError, missingError,
} from '@/store/modules/creditorsAPI'
import { CaseDebtsGetModel, DebtType, translateDebtType } from '@/models/debts'
import { Alert, apiError } from '@/models/alerts'
import { Case } from '@/store/modules/cases'
import * as mock from '@/mockdata/caseDebts'
import { Formatting } from '@/models/formatting'

export interface CaseDebt {
  caseId: number;
  debt: string;
  debtType: DebtType;
  invoiceNumber: string;
  invoiceDescription: string;
  invoiceDate: Date;
  invoiceDateFormatted: string;
  invoiceDueDate?: Date;
  invoiceDueDateFormatted: string;
  remainingCapital: number;
  remainingCapitalFormatted: string;
  remainingInterest: number;
  remainingInterestFormatted: string;
}

export interface CaseDebtsState {
  alert: Alert | undefined;
  caseDebts?: Page<CaseDebt>;
}

export const state: CaseDebtsState = {
  alert: undefined,
  caseDebts: undefined,
}

const namespaced = true

export const getters: GetterTree<CaseDebtsState, RootState> = {
  getAlert(state): Alert | undefined {
    return state.alert
  },
  getCaseDebts(state, getters, rootState, rootGetters): Page<CaseDebt> | undefined {
    return state.caseDebts
  },
}

export const mutations: MutationTree<CaseDebtsState> = {
  setAlert(state, alert: Alert | undefined) {
    state.alert = alert
  },
  setCaseDebts(state, caseDebts: Page<CaseDebt>) {
    state.caseDebts = caseDebts
  },
}

export const actions: ActionTree<CaseDebtsState, RootState> = {
  async refreshCaseDebts(store, page: number): Promise<Page<CaseDebt> | APIError> {
    console.log(`REFRESHING CASE DEBTS ${page} START`)
    const currentCase: Case | undefined = store.rootGetters['cases/getCurrentCase']

    if (currentCase === undefined) return missingError('currentCase')

    // Build pagination from requested page
    const pagination = new Pagination(10, page)

    const request = <APIRequest> {
      method: 'get',
      path: `/case-debts?case_id=${currentCase.caseId}`,
      extra: currentCase.caseId,
      action: 'caseDebts/recieveCaseDebtsGetModel',
      mock: mock.mockCaseDebtsGetModel(pagination),
      paging: pagination,
    }
    const result: true | APIError = await store.dispatch('creditorsAPI/call', request)
    if (result !== true) {
      apiError(store, result)
      return result
    }
    return store.getters.getCaseDebts
  },

  async recieveCaseDebtsGetModel(store, page: PageModel<CaseDebtsGetModel>) {
    const caseId = page.extra as number

    const caseDebts: Page<CaseDebt> = Page.transform(page, ((it) => {
      const invoice_date = new Date(it.invoice_date)
      const invoice_due_date = it.invoice_due_date != null ? new Date(it.invoice_due_date) : undefined
      return <CaseDebt> {
        caseId,
        debt: translateDebtType(it.debt_type),
        debtType: it.debt_type,
        invoiceNumber: it.invoice_number,
        invoiceDescription: it.invoice_description,
        invoiceDate: invoice_date,
        invoiceDateFormatted: Formatting.formatDate(invoice_date),
        invoiceDueDate: invoice_due_date,
        invoiceDueDateFormatted: Formatting.formatDate(invoice_due_date),
        remainingCapital: it.remaining_capital,
        remainingCapitalFormatted: Formatting.formatAmount(it.remaining_capital),
        remainingInterest: it.remaining_interest,
        remainingInterestFormatted: Formatting.formatAmount(it.remaining_interest),
      }
    }))

    store.commit('setCaseDebts', caseDebts)
    console.log('REFRESHING CASE DEBTS END')
  },
}

export const caseDebts: Module<CaseDebtsState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations,
  modules: {
    creditorsAPI,
  },
}
