
import Vue from 'vue'
import router from '@/router'
import Component from 'vue-class-component'
import { namespace } from 'vuex-class'
import { State2Way } from 'vuex-class-state2way'
import { MOCK_SIMPLIFIED_TOKEN, MOCK_UNAUTHORIZED_TOKEN, APIError } from '@/store/modules/creditorsAPI'
import { Alert } from '@/models/alerts'
import { InputText, validInputs } from '@/models/validations'

const Authentications = namespace('authentications')
const CreditorsAPI = namespace('creditorsAPI')

@Component
export default class OnboardingLoginView extends Vue {
  @Authentications.Action startLogin!: () => void
  @Authentications.Action startAuthentication!: () => Promise<true | APIError>

  @CreditorsAPI.Mutation setToken!: (token?: string) => void

  @State2Way('authentications/setAlert', (state) => state.authentications.alert) alert!: Alert | undefined
  @State2Way('authentications/setPersonalNumber', (state) => state.authentications.personalNumber) personalNumber!: InputText

  demo_mode = false
  strict = false
  userAcceptedCookies = Vue.$cookies.get('user-accepted-cookies') === 'yes'

  async reset() {
    this.strict = false
  }

  async login() {
    const inputs = [this.personalNumber]
    this.strict = true
    if (validInputs(inputs, this.strict)) {
      if (await this.startAuthentication() === true) router.push('/mbid')
    } else {
      console.log('Invalid')
    }
  }

  async mockLogin() {
    console.log('STARTING MOCKED USER SESSION')
    this.personalNumber = InputText.minimum({ value: '195001182046' })
    await this.setToken(MOCK_SIMPLIFIED_TOKEN)
    await this.login()
  }

  async mockSignup() {
    console.log('STARTING MOCKED UNAUTHORIZED SESSION')
    this.personalNumber = InputText.minimum({ value: '195001182046' })
    await this.setToken(MOCK_UNAUTHORIZED_TOKEN)
    await this.login()
  }

  async acceptCookies() {
    Vue.$cookies.set('user-accepted-cookies', 'yes')
    this.userAcceptedCookies = true
  }

  async mounted() {
    this.demo_mode = process.env.VUE_APP_DEMO_MODE === 'true'
    console.log(`VUE_APP_DEMO_MODE: ${process.env.VUE_APP_DEMO_MODE}`)
    console.log(`demo_mode: ${this.demo_mode}`)
    this.startLogin()
  }
}
