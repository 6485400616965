
import Vue from 'vue'
import router from '@/router'
import { namespace } from 'vuex-class'
import { State2Way } from 'vuex-class-state2way'
import { Alert } from '@/models/alerts'
import Component from 'vue-class-component'
import { MenuBar } from '@/models/menus'
import { CreditorDepartment } from '@/store/modules/creditorDepartments'
import { APIError } from '@/store/modules/creditorsAPI'
import { InputText, InputNumber, validInputs } from '@/models/validations'

const Authentications = namespace('authentications')
const CreditorDepartments = namespace('creditorDepartments')
const Navigation = namespace('navigation')

@Component
export default class CreditorDepartmentView extends Vue {
  @Navigation.Getter getMenuBar!: MenuBar
  @Navigation.Getter getMode!: string

  @Authentications.Action switchCurrentCreditor!: (creditorId: number) => Promise<true | APIError>
  @CreditorDepartments.Action editCreditorDepartment!: () => Promise<true | APIError>
  @CreditorDepartments.Action refreshCurrentCreditorDepartmentDetails!: () => Promise<true | APIError>

  @State2Way('creditorDepartments/setAlert', (state) => state.creditorDepartments.alert) alert!: Alert | undefined
  @State2Way('creditorDepartments/setName', (state) => state.creditorDepartments.name) name!: InputText
  @State2Way('creditorDepartments/setAddress', (state) => state.creditorDepartments.address) address!: InputText
  @State2Way('creditorDepartments/setPostalCode', (state) => state.creditorDepartments.postalCode) postalCode!: InputNumber
  @State2Way('creditorDepartments/setCity', (state) => state.creditorDepartments.city) city!: InputText
  @State2Way('creditorDepartments/setBankgiro', (state) => state.creditorDepartments.bankgiro) bankgiro!: InputText

  isBusy = true
  strict = false

  async reset() {
    this.strict = false
  }

  get isCreate(): boolean { return this.getMode === 'create' }
  get isEdit(): boolean { return this.getMode === 'edit' }

  async switchCreditor(creditorId: number) {
    if (await this.switchCurrentCreditor(creditorId) === true) router.push('/cases-overviews')
  }

  async save() {
    const inputs = [this.name, this.address, this.postalCode, this.city, this.bankgiro]
    this.strict = true
    if (validInputs(inputs, this.strict)) {
      if (this.isCreate) {
        // if (await this.createCreditorDepartment() === true) router.push('/creditor-department-success')
      }
      if (this.isEdit) {
        if (await this.editCreditorDepartment() === true) router.push('/creditor-department-success')
      }
    } else {
      console.log('Invalid')
    }
  }

  async remove() {
    if (this.isEdit) {
      // if (await this.removeCreditorUser() === true) router.push('/creditors')
    }
  }

  async mounted() {
    await this.refreshCurrentCreditorDepartmentDetails()
    this.isBusy = false
  }
}
