
import Vue from 'vue'
import router from '@/router'
import { namespace } from 'vuex-class'
import { State2Way } from 'vuex-class-state2way'
import Component from 'vue-class-component'
import { MenuBar } from '@/models/menus'
import { CaseMessage } from '@/store/modules/caseMessages'
import { Alert } from '@/models/alerts'
import { Page, APIError, isPage } from '@/store/modules/creditorsAPI'
import { InputText, validInputs } from '@/models/validations'

const Authentications = namespace('authentications')
const CaseMessages = namespace('caseMessages')
const Navigation = namespace('navigation')

@Component
export default class CaseMessagesView extends Vue {
  @Navigation.Getter getMenuBar!: MenuBar

  @Authentications.Action switchCurrentCreditor!: (creditorId: number) => Promise<true | APIError>
  @CaseMessages.Action refreshCaseMessages!: (page: number) => Promise<Page<CaseMessage> | APIError>
  @CaseMessages.Action markMessageAsRead!: ({ caseMessageId, _etag }: { caseMessageId: number, _etag: string}) => Promise<true | APIError>
  @CaseMessages.Action sendCaseMessage!: () => Promise<true | APIError>

  @State2Way('caseMessages/setAlert', (state) => state.caseMessages.alert) alert!: Alert | undefined
  @State2Way('caseMessages/setMessage', (state) => state.caseMessages.message) message!: InputText

  strict = false
  isBusy = false
  currentPage = 1
  perPage = 10
  total = 0
  fields = ['content']

  async reset() {
    this.strict = false
  }

  get hasPagination(): boolean { return this.isBusy === false && this.total > this.perPage }

  bubble(data: {item: CaseMessage}): string { return data.item.isRead ? 'message-bubble' : 'message-bubble-open' }

  async switchCreditor(creditorId: number) {
    if (await this.switchCurrentCreditor(creditorId) === true) router.push('/cases-overviews')
  }

  async send() {
    const inputs = [this.message]
    this.strict = true
    if (validInputs(inputs, this.strict)) {
      await this.sendCaseMessage()
      this.strict = false
      this.$root.$emit('bv::refresh::table', 'caseMessages')
    }
  }

  async markAsRead(data: {item: CaseMessage}) {
    await this.markMessageAsRead({ caseMessageId: data.item.caseMessageId, _etag: data.item._etag })
    this.$root.$emit('bv::refresh::table', 'caseMessages')
  }

  itemsProvider(ctx: { currentPage: number; perPage: number}, callback: (items: any[]) => void): any[] | null {
    this.refreshCaseMessages(ctx.currentPage)
      .then((page) => {
        if (isPage(page)) {
          this.currentPage = page?.page ?? 1
          this.perPage = page?.maxResults ?? 10
          this.total = page?.total ?? 0
          callback(page?.items ?? [])
        } else {
          callback([])
        }
      })
      .catch(() => {
        callback([])
      })
    return null
  }
}
