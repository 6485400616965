
import Vue from 'vue'
import router from '@/router'
import { namespace } from 'vuex-class'
import { State2Way } from 'vuex-class-state2way'
import Component from 'vue-class-component'
import { MenuBar } from '@/models/menus'
import { Alert } from '@/models/alerts'
import { FieldType } from '@/components/NordicCoolTable.vue'
import { InputText, InputDate, InputNumber, InputCheckbox, validInputs } from '@/models/validations'
import { APIError } from '@/store/modules/creditorsAPI'

const Authentications = namespace('authentications')
const CasePayments = namespace('casePayments')
const Navigation = namespace('navigation')

@Component
export default class CasePaymentStartView extends Vue {
  @Navigation.Getter getMenuBar!: MenuBar

  @Authentications.Action switchCurrentCreditor!: (creditorId: number) => Promise<true | APIError>
  @CasePayments.Action createCasePayment!: () => Promise<Promise<true | APIError>>
  @CasePayments.Action startCreatingCasePayment!: () => void

  @State2Way('casePayments/setAlert', (state) => state.casePayments.alert) alert!: Alert | undefined
  @State2Way('casePayments/setInvoiceNumber', (state) => state.casePayments.invoiceNumber) invoiceNumber!: InputText
  @State2Way('casePayments/setAccountingDate', (state) => state.casePayments.accountingDate) accountingDate!: InputDate
  @State2Way('casePayments/setAmount', (state) => state.casePayments.amount) amount!: InputNumber
  @State2Way('casePayments/setPaymentMessage', (state) => state.casePayments.paymentMessage) paymentMessage!: InputText

  strict = false

  async reset() {
    this.strict = false
  }

  async switchCreditor(creditorId: number) {
    if (await this.switchCurrentCreditor(creditorId) === true) router.push('/cases-overviews')
  }

  async next() {
    const inputs = [this.invoiceNumber, this.accountingDate, this.amount, this.paymentMessage]
    this.strict = true
    if (validInputs(inputs, this.strict)) {
      if (await this.createCasePayment() === true) router.push('/case-payment-success')
    } else {
      console.log('Invalid')
    }
  }

  async mounted() {
    this.startCreatingCasePayment()
  }
}
