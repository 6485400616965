
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { InputNumber, validInputNumber, placeholderInputNumber } from '@/models/validations'
import { Formatting } from '@/models/formatting'

@Component
export default class NordicCoolInputNumber extends Vue {
  @Prop(String) readonly id: string | undefined
  @Prop(String) readonly label: string | undefined
  @Prop(Object) readonly value: InputNumber | undefined
  @Prop(Boolean) readonly noPlaceholder: boolean | undefined
  @Prop(Boolean) readonly disabled: boolean | undefined
  @Prop(Boolean) readonly strict: boolean | undefined
  @Prop({ type: Function, default: undefined }) readonly reset!: () => void

  get placeholder(): string {
    if (this.disabled === true) return ''
    if (this.value === undefined) return ''
    if (this.noPlaceholder === true) return ''
    return placeholderInputNumber(this.value)
  }

  get state(): true | false | null {
    if (this.disabled === true) return null
    if (this.value === undefined) {
      return null
    }
    return validInputNumber(this.value, this.strict ?? false)
  }

  get localValue(): string {
    if (this.value === undefined) {
      return ''
    }
    if (this.value.value === null) return ''
    if (this.value.value === 0 && this.value.zeroIsBlank) return ''
    if (this.value.numberIsAmount && this.strict === true) return Formatting.formatFixedNumber(this.value.value)
    if (this.value.numberIsAmount) return Formatting.formatNumber(this.value.value)
    return this.value.value.toString()
  }

  set localValue(localValue: string) {
    if (this.reset !== undefined) this.reset()
    if (this.value !== undefined) {
      const input = this.value
      input.value = localValue === '' ? 0 : Formatting.parseNumber(localValue) ?? null
      this.$emit('input', input)
    }
  }
}
