
import Vue from 'vue'
import router from '@/router'
import { namespace } from 'vuex-class'
import { State2Way } from 'vuex-class-state2way'
import { Alert } from '@/models/alerts'
import Component from 'vue-class-component'
import { MenuBar } from '@/models/menus'
import { CreditorUser } from '@/store/modules/creditorUsers'
import { APIError } from '@/store/modules/creditorsAPI'
import { InputText, InputCheckbox, validInputs } from '@/models/validations'

const Authentications = namespace('authentications')
const CreditorUsers = namespace('creditorUsers')
const Navigation = namespace('navigation')

@Component
export default class CreditorUserView extends Vue {
  @CreditorUsers.Getter getCurrentCreditorUser!: CreditorUser | undefined
  @Navigation.Getter getMenuBar!: MenuBar
  @Navigation.Getter getMode!: string

  @Authentications.Action switchCurrentCreditor!: (creditorId: number) => Promise<true | APIError>
  @CreditorUsers.Action createCreditorUser!: () => Promise<true | APIError>
  @CreditorUsers.Action editCreditorUser!: () => Promise<true | APIError>
  @CreditorUsers.Action removeCreditorUser!: () => Promise<true | APIError>
  @CreditorUsers.Action refreshCurrentCreditorUserDetails!: () => Promise<true | APIError>

  @State2Way('creditorUsers/setAlert', (state) => state.creditorUsers.alert) alert!: Alert | undefined
  @State2Way('creditorUsers/setPersonalNumber', (state) => state.creditorUsers.personalNumber) personalNumber!: InputText
  @State2Way('creditorUsers/setFirstName', (state) => state.creditorUsers.firstName) firstName!: InputText
  @State2Way('creditorUsers/setLastName', (state) => state.creditorUsers.lastName) lastName!: InputText
  @State2Way('creditorUsers/setEmail', (state) => state.creditorUsers.email) email!: InputText
  @State2Way('creditorUsers/setMobilePhone', (state) => state.creditorUsers.mobilePhone) mobilePhone!: InputText
  @State2Way('creditorUsers/setRightsAll', (state) => state.creditorUsers.rightsAll) rightsAll!: InputCheckbox
  @State2Way('creditorUsers/setRightsUser', (state) => state.creditorUsers.rightsUser) rightsUser!: InputCheckbox
  @State2Way('creditorUsers/setRightsDepartment', (state) => state.creditorUsers.rightsDepartment) rightsDepartment!: InputCheckbox
  @State2Way('creditorUsers/setRightsCase', (state) => state.creditorUsers.rightsCase) rightsCase!: InputCheckbox
  @State2Way('creditorUsers/setReportSettlement', (state) => state.creditorUsers.reportSettlement) reportSettlement!: InputCheckbox
  @State2Way('creditorUsers/setReportInvoiceSpecification', (state) => state.creditorUsers.reportInvoiceSpecification) reportInvoiceSpecification!: InputCheckbox

  isBusy = true
  isConfirm = false
  strict = false

  async reset() {
    this.strict = false
  }

  get isCreate(): boolean { return this.getMode === 'create' }
  get isEdit(): boolean { return this.getMode === 'edit' }
  get isCurrent(): boolean { return this.getCurrentCreditorUser?.isCurrent ?? false }

  async switchCreditor(creditorId: number) {
    if (await this.switchCurrentCreditor(creditorId) === true) router.push('/cases-overviews')
  }

  async save() {
    const inputs = [this.personalNumber, this.firstName, this.lastName, this.email, this.mobilePhone]
    this.strict = true
    if (validInputs(inputs, this.strict)) {
      if (this.isCreate) {
        if (await this.createCreditorUser() === true) router.push('/creditor-user-success')
      }
      if (this.isEdit) {
        if (await this.editCreditorUser() === true) router.push('/creditor-user-success')
      }
    } else {
      console.log('Invalid')
    }
  }

  async remove() {
    this.isConfirm = true
  }

  async proceedRemove() {
    if (this.isEdit) {
      if (await this.removeCreditorUser() === true) router.push('/creditor-overview')
    }
  }

  async mounted() {
    await this.refreshCurrentCreditorUserDetails()
    this.isBusy = false
  }
}
