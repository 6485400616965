import {
  ActionTree, GetterTree, Module, MutationTree,
} from 'vuex'
import { RootState } from '@/store/index'
import {
  creditorsAPI, APIRequest, Pagination, PageModel, Page, APIError, missingError,
} from '@/store/modules/creditorsAPI'
import {
  CaseMessagesGetModel, CaseMessagesPostModel, CaseMessagesPatchModel, SentToType, translateSentToType, CaseMessagesPostResponseModel,
} from '@/models/messages'
import { Alert, apiError } from '@/models/alerts'
import { Case } from '@/store/modules/cases'
import * as mock from '@/mockdata/caseMessages'
import { InputText } from '@/models/validations'

export interface CaseMessage {
  caseId: number;
  caseMessageId: number;
  createDate: string;
  subject: string;
  body: string;
  sentTo: string;
  sentToType: SentToType;
  isRead: boolean;
  clientId: number;
  userId: number;
  groupMessageId: number;
  _etag: string;
}

export interface CaseMessageState {
  alert: Alert | undefined;
  inbox?: Page<CaseMessage>;
  sent?: Page<CaseMessage>;
  caseMessages?: Page<CaseMessage>;

  message: InputText;
}

function resetMessage(value: string | null = null): InputText {
  return InputText.standard({ value: value, minLength: 10, maxLength: 400, required: true })
}

export const state: CaseMessageState = {
  alert: undefined,
  inbox: undefined,
  sent: undefined,
  caseMessages: undefined,
  message: resetMessage(),
}

const namespaced = true

export const getters: GetterTree<CaseMessageState, RootState> = {
  getAlert(state): Alert | undefined {
    return state.alert
  },
  getInbox(state): Page<CaseMessage> | undefined {
    return state.inbox
  },
  getSent(state): Page<CaseMessage> | undefined {
    return state.sent
  },
  getCaseMessages(state): Page<CaseMessage> | undefined {
    return state.caseMessages
  },
  getMessage(state): InputText {
    return state.message
  },
}

export const mutations: MutationTree<CaseMessageState> = {
  setAlert(state, alert: Alert | undefined) {
    state.alert = alert
  },
  setInbox(state, inbox: Page<CaseMessage>) {
    state.inbox = inbox
  },
  setSent(state, sent: Page<CaseMessage>) {
    state.sent = sent
  },
  setCaseMessages(state, caseMessages: Page<CaseMessage>) {
    state.caseMessages = caseMessages
  },
  setMessage(state, message: InputText) {
    state.message = message
  },
}

export const actions: ActionTree<CaseMessageState, RootState> = {
  async refreshInbox(store, page: number): Promise<Page<CaseMessage> | APIError> {
    console.log(`REFRESHING INBOX ${page} START`)

    // Build pagination from requested page
    const pagination = new Pagination(10, page)

    // TODO: We hard code a specific case id, but really we should be able to list everything for a specific client

    const extra = <{ caseId: number; sentTo?: SentToType }> { caseId: 20746578, sentTo: 'TO_CREDITOR' }
    const request = <APIRequest> {
      method: 'get',
      path: `/case-messages?case_id=${extra.caseId}`,
      extra,
      action: 'caseMessages/recieveCaseMessagesGetModel',
      mock: mock.mockCaseMessagesGetModel(pagination, extra),
      paging: pagination,
    }

    const result: true | APIError = await store.dispatch('creditorsAPI/call', request)
    if (result !== true) {
      apiError(store, result)
      return result
    }
    return store.getters.getInbox
  },

  async refreshSent(store, page: number): Promise<Page<CaseMessage> | APIError> {
    console.log(`REFRESHING SENT ${page} START`)

    // Build pagination from requested page
    const pagination = new Pagination(10, page)

    // TODO: We hard code a specific case id, but really we should be able to list everything for a specific client

    const extra = <{ caseId: number; sentTo?: SentToType }> { caseId: 20746578, sentTo: 'TO_VISMA' }
    const request = <APIRequest> {
      method: 'get',
      path: `/case-messages?case_id=${extra.caseId}`,
      extra,
      action: 'caseMessages/recieveCaseMessagesGetModel',
      mock: mock.mockCaseMessagesGetModel(pagination, extra),
      paging: pagination,
    }

    const result: true | APIError = await store.dispatch('creditorsAPI/call', request)
    if (result !== true) {
      apiError(store, result)
      return result
    }
    return store.getters.getSent
  },

  async refreshCaseMessages(store, page: number): Promise<Page<CaseMessage> | APIError> {
    console.log(`REFRESHING SENT ${page} START`)
    const currentCase: Case | undefined = store.rootGetters['cases/getCurrentCase']

    if (currentCase === undefined) return missingError('currentCase')

    // Build pagination from requested page
    const pagination = new Pagination(10, page)

    const extra = <{ caseId: number; sentTo?: SentToType }> { caseId: currentCase.caseId, sentTo: undefined }
    const request = <APIRequest> {
      method: 'get',
      path: `/case-messages?case_id=${extra.caseId}`,
      extra,
      action: 'caseMessages/recieveCaseMessagesGetModel',
      mock: mock.mockCaseMessagesGetModel(pagination, extra),
      paging: pagination,
    }

    const result: true | APIError = await store.dispatch('creditorsAPI/call', request)
    if (result !== true) {
      apiError(store, result)
      return result
    }
    return store.getters.getCaseMessages
  },

  async recieveCaseMessagesGetModel(store, page: PageModel<CaseMessagesGetModel>) {
    const { caseId, sentTo } = page.extra as { caseId: number; sentTo?: SentToType }

    // Transform each case message model to a case message
    const caseMessages: Page<CaseMessage> = Page.transform(page, ((it) => <CaseMessage> {
      caseId,
      caseMessageId: it.case_message_id,
      createDate: it.create_date,
      subject: it.subject,
      body: it.body,
      sentTo: translateSentToType(it.sent_to),
      sentToType: it.sent_to,
      isRead: it.is_read,
      clientId: it.client_id,
      userId: it.user_id,
      groupMessageId: it.group_message_id,
      _etag: it._etag,
    }))

    //    const unreadCount = caseMessages?.filter(it => active.includes(it.matches)).reduce(((acc, it) => acc + it.count), 0)

    //    store.commit('setActiveCount', activeCount ?? 0)
    switch (sentTo) {
      case 'TO_CREDITOR':
        store.commit('setInbox', caseMessages)
        break
      case 'TO_VISMA':
        store.commit('setSent', caseMessages)
        break
      default:
        store.commit('setCaseMessages', caseMessages)
    }

    console.log('REFRESHING CASE MESSAGES END')
  },

  /**
   * Marks a message as read
   *
   * @param store The vuex store.
   * @param caseMessageId The id of the case message to be updated.
   */
  async markMessageAsRead(store, { caseMessageId, _etag }: { caseMessageId: number, _etag: string}): Promise<true | APIError> {
    console.log('MESSAGE READ')

    // Create a PATCH body
    const data = <CaseMessagesPatchModel> {
      is_read: true,
    }

    // Invoke API
    const request = <APIRequest> {
      method: 'patch',
      path: `/case-messages/${caseMessageId}`,
      data: data,
      _etag: _etag,
      mock: mock.mockCaseMessagesPatchModel(caseMessageId, data),
    }
    const result: true | APIError = await store.dispatch('creditorsAPI/call', request)
    if (result !== true) {
      apiError(store, result)
      return result
    }

    // Decrement unread case message count for specified case
    return store.dispatch('cases/decrementUnreadCaseMessages', null, { root: true })
  },

  /**
   * Sends a message. Note that from the users perspective a message sent is automatically
   * marked as read.
   *
   * @param store The vuex store.
   * @param caseMessageId The id of the case message to be updated.
   */
  async sendCaseMessage(store): Promise<true | APIError> {
    console.log('MESSAGE SEND')
    const currentCase: Case | undefined = store.rootGetters['cases/getCurrentCase']
    const currentCreditorId = store.rootGetters.getCurrentCreditorId
    const message: InputText = store.getters.getMessage

    if (currentCase === undefined) return missingError('currentCase')

    // Create a POST body
    const data = <CaseMessagesPostModel> {
      subject: 'Övrigt',
      body: message.toString(),
      label: '',
      creditor_id: currentCreditorId,
      user_id: 0,
      group_message_id: 0,
    }

    // Invoke API
    const request = <APIRequest> {
      method: 'post',
      path: `/case-messages?case_id=${currentCase.caseId}`,
      data,
      action: 'caseMessages/recieveCaseMessagesPostModel',
      mock: mock.mockCaseMessagesPostModel(data),
    }
    const result: true | APIError = await store.dispatch('creditorsAPI/call', request)
    if (result !== true) {
      apiError(store, result)
      return result
    }

    await store.dispatch('startCreatingMessage', request)

    return result
  },

  async recieveCaseMessagesPostModel(store, model: CaseMessagesPostResponseModel) {
  },

  /**
   * Prepares the store for starting a new case message
   *
   * @param store The vuex store.
   */
  async startCreatingMessage(store) {
    store.commit('setMessage', resetMessage())
  },
}

export const caseMessages: Module<CaseMessageState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations,
  modules: {
    creditorsAPI,
  },
}
