
import Vue from 'vue'
import router from '@/router'
import { namespace } from 'vuex-class'
import { State2Way } from 'vuex-class-state2way'
import Component from 'vue-class-component'
import { MenuBar } from '@/models/menus'
import { CaseCost } from '@/store/modules/caseCosts'
import { CaseDebt } from '@/store/modules/caseDebts'
import { Case, CaseDetails } from '@/store/modules/cases'
import { Alert } from '@/models/alerts'
import { FieldType } from '@/components/NordicCoolTable.vue'
import { InputText } from '@/models/validations'
import { Page, APIError } from '@/store/modules/creditorsAPI'
import { CaseDebtor } from '@/store/modules/caseDebtors'
import { CasePayment } from '@/store/modules/casePayments'

const Authentications = namespace('authentications')
const CaseCosts = namespace('caseCosts')
const CaseDebtors = namespace('caseDebtors')
const CaseDebts = namespace('caseDebts')
const CasePayments = namespace('casePayments')
const Cases = namespace('cases')
const Navigation = namespace('navigation')

@Component
export default class CaseDetailsView extends Vue {
  @Cases.Getter getCurrentCaseDetails!: CaseDetails
  @Navigation.Getter getMenuBar!: MenuBar

  @Authentications.Action switchCurrentCreditor!: (creditorId: number) => Promise<true | APIError>
  @CaseCosts.Action refreshCaseCosts!: (page: number) => Promise<Page<CaseCost> | APIError>
  @CaseDebtors.Action refreshCaseDebtors!: (page: number) => Promise<Page<CaseDebtor> | APIError>
  @CaseDebts.Action refreshCaseDebts!: (page: number) => Promise<Page<CaseDebt> | APIError>
  @CasePayments.Action refreshCasePayments!: (page: number) => Promise<Page<CasePayment> | APIError>
  @Cases.Action refreshCurrentCaseDetails!: () => Promise<true | APIError>

  @State2Way('cases/setAlert', (state) => state.cases.alert) casesAlert!: Alert | undefined
  @State2Way('caseActions/setAlert', (state) => state.caseActions.alert) caseActionsAlert!: Alert | undefined

  busy = true

  get caseIsClosed(): boolean { return this.getCurrentCaseDetails.statusType === 'CLOSED' }

  async switchCreditor(creditorId: number) {
    if (await this.switchCurrentCreditor(creditorId) === true) router.push('/cases-overviews')
  }

  caseDebtItems(page: number): Promise<Page<CaseDebt> | APIError> {
    return this.refreshCaseDebts(page)
  }

  caseDebtorItems(page: number): Promise<Page<CaseDebtor> | APIError> {
    return this.refreshCaseDebtors(page)
  }

  get caseDebtorFields(): FieldType[] {
    return [
      {
        key: 'debtor', label: this.$i18n.tc('common.fields.type'), sortable: false, size: 'xsmall', alignment: 'left',
      },
      {
        key: 'organisationId', label: this.$i18n.tc('common.fields.orgPersonId'), sortable: false, size: 'small', alignment: 'left',
      },
      {
        key: 'name', label: this.$i18n.tc('common.fields.name'), sortable: false, size: 'small', alignment: 'left',
      },
      {
        key: 'role', label: this.$i18n.tc('common.fields.role'), sortable: false, size: 'flex', alignment: 'right',
      },
    ]
  }

  get caseDebtFields(): FieldType[] {
    return [
      {
        key: 'debt', label: this.$i18n.tc('common.fields.type'), sortable: false, size: 'xsmall', alignment: 'left',
      },
      {
        key: 'invoiceNumber', label: this.$i18n.tc('common.fields.invoiceNumber'), sortable: false, size: 'small', alignment: 'left',
      },
      {
        key: 'invoiceDescription', label: this.$i18n.tc('common.fields.description'), sortable: false, size: 'text', alignment: 'left',
      },
      {
        key: 'invoiceDateFormatted', label: this.$i18n.tc('common.fields.invoiceDate'), sortable: false, size: 'flex', alignment: 'right',
      },
      {
        key: 'invoiceDueDateFormatted', label: this.$i18n.tc('case.details.debts.invoiceDueDate'), sortable: false, size: 'xsmall', alignment: 'right',
      },
      {
        key: 'remainingCapitalFormatted', label: this.$i18n.tc('common.fields.remaining'), sortable: false, size: 'xsmall', alignment: 'right',
      },
      {
        key: 'remainingInterestFormatted', label: this.$i18n.tc('common.fields.interest'), sortable: false, size: 'xsmall', alignment: 'right',
      },
    ]
  }

  casePaymentItems(page: number): Promise<Page<CasePayment> | APIError> {
    return this.refreshCasePayments(page)
  }

  get casePaymentFields(): FieldType[] {
    return [
      {
        key: 'payment', label: this.$i18n.tc('common.fields.type'), sortable: false, size: 'xsmall', alignment: 'left',
      },
      {
        key: 'referenceNumber', label: this.$i18n.tc('case.details.payments.referenceNumber'), sortable: false, size: 'small', alignment: 'left',
      },
      {
        key: 'receiver', label: this.$i18n.tc('case.details.payments.receiver'), sortable: false, size: 'flex', alignment: 'left',
      },
      {
        key: 'accountingDateFormatted', label: this.$i18n.tc('common.fields.date'), sortable: false, size: 'xsmall', alignment: 'right',
      },
      {
        key: 'amountFormatted', label: this.$i18n.tc('common.fields.amount'), sortable: false, size: 'xsmall', alignment: 'right',
      },
    ]
  }

  caseCostItems(page: number): Promise<Page<CaseCost> | APIError> {
    return this.refreshCaseCosts(page)
  }

  get caseCostFields(): FieldType[] {
    return [
      {
        key: 'cost', label: this.$i18n.tc('common.fields.type'), sortable: false, size: 'small', alignment: 'left',
      },
      {
        key: 'empty', label: '', sortable: false, size: 'flex', alignment: 'left',
      },
      {
        key: 'createdFormatted', label: this.$i18n.tc('common.fields.date'), sortable: false, size: 'xsmall', alignment: 'right',
      },
      {
        key: 'remainingCapitalFormatted', label: this.$i18n.tc('common.fields.amount'), sortable: false, size: 'xsmall', alignment: 'right',
      },
      {
        key: 'remainingInterestFormatted', label: this.$i18n.tc('common.fields.interest'), sortable: false, size: 'xsmall', alignment: 'right',
      },
    ]
  }

  async mounted() {
    await this.refreshCurrentCaseDetails()
    this.busy = false
  }
}
