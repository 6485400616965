
import Vue from 'vue'
import router from '@/router'
import { namespace } from 'vuex-class'
import { State2Way } from 'vuex-class-state2way'
import { Alert } from '@/models/alerts'
import Component from 'vue-class-component'
import { FieldType } from '@/components/NordicCoolTable.vue'
import { MenuBar } from '@/models/menus'
import { Creditor } from '@/store/modules/creditors'
import { CreditorUser } from '@/store/modules/creditorUsers'
import { Page, APIError } from '@/store/modules/creditorsAPI'

const Authentications = namespace('authentications')
const Creditors = namespace('creditors')
const CreditorUsers = namespace('creditorUsers')
const Navigation = namespace('navigation')

@Component
export default class CreditorUsersView extends Vue {
  @Creditors.Getter getCurrentCreditor!: Creditor
  @Navigation.Getter getMenuBar!: MenuBar

  @Authentications.Action switchCurrentCreditor!: (creditorId: number) => Promise<true | APIError>
  @CreditorUsers.Action refreshCreditorUsers!: (page: number) => Promise<Page<CreditorUser> | APIError>
  @CreditorUsers.Action switchCurrentCreditorUserId!: (creditorUserId?: number) => Promise<true | APIError>

  @State2Way('creditors/setAlert', (state) => state.creditors.alert) alert!: Alert | undefined

  async switchCreditor(creditorId: number) {
    if (await this.switchCurrentCreditor(creditorId) === true) router.push('/cases-overviews')
  }

  newCreditorUser() {
    this.switchCurrentCreditorUserId(undefined)
    router.push({ name: 'CreditorUserView', params: { mode: 'create', source: 'users' } })
  }

  editCreditorUser(data: {item: CreditorUser}) {
    this.switchCurrentCreditorUserId(data.item.creditorUserId)
    router.push({ name: 'CreditorUserView', params: { mode: 'edit', source: 'users' } })
  }

  creditorUserItems(page: number): Promise<Page<CreditorUser> | APIError> {
    return this.refreshCreditorUsers(page)
  }

  get creditorUserFields(): FieldType[] {
    return [
      {
        key: 'personalNumber', label: 'Personnummer', sortable: false, size: 'small', alignment: 'left',
      },
      {
        key: 'firstName', label: 'Förnamn', sortable: false, size: 'medium', alignment: 'left',
      },
      {
        key: 'lastName', label: 'Efternamn', sortable: false, size: 'medium', alignment: 'left',
      },
      {
        key: 'email', label: 'E-post', sortable: false, size: 'flex', alignment: 'left',
      },
      {
        key: 'actions', label: '', sortable: false, size: 'actions', alignment: 'right',
      },
    ]
  }
}
