import i18n from '@/plugins/i18n'
import { CaseDebtorsPostModel } from '@/models/debtors'
import { CaseDebtsPostModel } from '@/models/debts'
import { CaseCostsPostModel } from '@/models/costs'
import { CaseHistoryLogsPostModel } from '@/models/history'
import { CaseExtraFieldsPostModel } from '@/models/extras'

export type StatusType = 'INVOICE' | 'REMINDER' | 'COLLECTION' | 'SURVEILLANCE' | 'VERDICT_WARNING' | 'VERDICT' | 'ENFORCEMENT' | 'AMORTISATION' | 'CLOSED' | 'OTHER'
export function translateStatusType(status: StatusType | undefined): string {
  switch (status) {
    case 'INVOICE': return i18n.tc('models.status.INVOICE')
    case 'REMINDER': return i18n.tc('models.status.REMINDER')
    case 'COLLECTION': return i18n.tc('models.status.COLLECTION')
    case 'SURVEILLANCE': return i18n.tc('models.status.SURVEILLANCE')
    case 'VERDICT_WARNING': return i18n.tc('models.status.VERDICT_WARNING')
    case 'VERDICT': return i18n.tc('models.status.VERDICT')
    case 'ENFORCEMENT': return i18n.tc('models.status.ENFORCEMENT')
    case 'AMORTISATION': return i18n.tc('models.status.AMORTISATION')
    case 'CLOSED': return i18n.tc('models.status.CLOSED')
    case 'OTHER': return i18n.tc('models.status.OTHER')
    case undefined: return i18n.tc('models.status.OTHER')
    default: return ''
  }
}

export type ClosureCodeType = 'DECEASED' | 'BANKRUPTCY' | 'CREDIT' | 'LOW_AMOUNT' | 'FREE_CARD' | 'EU_CARD' | 'UNDERAGE' | 'MIGRATED' | 'SETTLEMENT' | 'FULL_PAID' | 'CANCELLED' | 'OTHER'
export function translateClosureCodeType(closureCode: ClosureCodeType | null): string {
  switch (closureCode) {
    case 'DECEASED': return i18n.tc('models.closureCode.DECEASED')
    case 'BANKRUPTCY': return i18n.tc('models.closureCode.BANKRUPTCY')
    case 'CREDIT': return i18n.tc('models.closureCode.CREDIT')
    case 'LOW_AMOUNT': return i18n.tc('models.closureCode.LOW_AMOUNT')
    case 'FREE_CARD': return i18n.tc('models.closureCode.FREE_CARD')
    case 'EU_CARD': return i18n.tc('models.closureCode.EU_CARD')
    case 'UNDERAGE': return i18n.tc('models.closureCode.UNDERAGE')
    case 'MIGRATED': return i18n.tc('models.closureCode.MIGRATED')
    case 'SETTLEMENT': return i18n.tc('models.closureCode.SETTLEMENT')
    case 'FULL_PAID': return i18n.tc('models.closureCode.FULL_PAID')
    case 'CANCELLED': return i18n.tc('models.closureCode.CANCELLED')
    case 'OTHER': return i18n.tc('models.closureCode.OTHER')
    case null: return ''
    default: return ''
  }
}

export type ActionType = 'LETTER' | 'WAIT' | 'MANUAL' | 'CLOSURE' | 'OTHER'
export function translateActionType(action: ActionType | undefined): string {
  switch (action) {
    case 'LETTER': return i18n.tc('models.action.LETTER')
    case 'MANUAL': return i18n.tc('models.action.MANUAL')
    case 'CLOSURE': return i18n.tc('models.action.CLOSURE')
    case 'OTHER': return i18n.tc('models.action.OTHER')
  }
  return ''
}

export interface CaseGetInvoicesModel {
  initial_capital: number;
  invoice_number: string; // Invoice number
  invoice_date: string; // Invoice date
  invoice_due_date: string; // Invoice due date
}

export interface CaseGetModel {
  register_id: number; // Register identifier
  case_id: number; // Case identifier
  main_debtor_name: string; // Name of the main debtor
  status: StatusType | null; // Case status
  joint_case_id: number; // Joint case identifier
  registration_date: string; // Date when case is created - ISO 8601 format
  closure_date: string | null; // Date when case is closed - ISO 8601 format
  closure_code: ClosureCodeType | null; // Reason code for closure
  creditor_case_reference_id: string; // Creditor reference number used at creditor
  currency_code: string; // Case currency - ISO 4217:2001
  creditor_department_id: number; // Creditor identification used from Visma against creditor
  remaining_capital: number; // Total remaining capital on case
  remaining_total: number; // Total remaining on case
  invoices: CaseGetInvoicesModel[]; // List of invoices on case
  case_messages: number; // Number of messages on case
  case_messages_unread: number; // Number of unread messages on case
  next_action: string; // Action to be processed
  next_action_type: ActionType | null; // Type of action
  next_action_date: string; // Date when action should be processed
}

export interface CaseDetailsModel {
  register_id: number; // Register identifier
  case_id: number; // Case identifier
  main_debtor_name: string; // Name of the main debtor
  status: StatusType | null; // Case status
  creditor_system_id: number; // Creditor identifier in internal system
  department_system_id: number; // Creditor department identifier in internal system
  joint_case_id: number; // Joint case identifier
  registration_date: string; // Date when case is created - ISO 8601 format
  closure_date: string | null;// Date when case is closed - ISO 8601 format
  closure_code: ClosureCodeType | null; // Reason code for closure
  creditor_case_reference_id: string; // Creditor reference number used at creditor
  currency_code: string; // Case currency - ISO 4217:2001
  ocr: string; // Reference number used for connecting payments on case
  creditor_department_id: number; // Creditor identification used from Visma against creditor
  remaining_capital: number; // Total remaining capital on case
  saved_remaining_interest: number; // Total saved remaining interest on case
  saved_remaining_interest_date: string; // Last date when interest is saved - ISO 8601 format
  accrued_interest: number; // Total calculated remaining interest on case
  remaining_cost: number; // Total remaining cost on case
  remaining_fee: number; // Total remaining cost on case
  remaining_total: number; // Total remaining on case
  invoices: CaseGetInvoicesModel[]; // List of invoices on case
  case_messages: number; // Number of messages on case
  case_messages_unread: number; // Number of unread messages on case
  next_action: string; // Action to be processed
  next_action_type: ActionType | null; // Type of action
  next_action_date: string; // Date when action should be processed
}

export type StartType = 'INVOICE' | 'REMINDER' | 'COLLECTION' | 'SURVEILLANCE'

export interface CasePostModel {
  creditor_department_id: number; // Creditor identification used from Visma against creditor
  email_for_error_response?: string;
  ocr?: string; // Reference number used for connecting payments on case. Only for partners with ocr payment agreement
  reference_number?: string; // Your internal reference number
  currency: string; // Case currency - ISO 4217:2001, min_length=3, max_length=3
  start_action: StartType;
  debtor_main: CaseDebtorsPostModel;
  debtor_applicants?: CaseDebtorsPostModel[];
  debts: CaseDebtsPostModel[];
  costs?: CaseCostsPostModel[];
  extra_fields?: CaseExtraFieldsPostModel[];
  history_logs?: CaseHistoryLogsPostModel[];
}

export interface CasePostResponseModel {
  register_id: number; // Temporary id to use when register sub entities to Cases
  _etag: string; // New _etag value after update
}
