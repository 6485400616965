
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { InputFile, validInputFile } from '@/models/validations'

@Component
export default class NordicCoolInputFile extends Vue {
  @Prop(String) readonly id: string | undefined
  @Prop(String) readonly label: string | undefined
  @Prop(String) readonly placeholder: string | undefined
  @Prop(String) readonly browsetext: string | undefined
  @Prop(String) readonly dropplaceholder: string | undefined
  @Prop(Object) readonly value: InputFile | undefined
  @Prop(Boolean) readonly disabled: boolean | undefined
  @Prop(Boolean) readonly strict: boolean | undefined
  @Prop({ type: Function, default: undefined }) readonly reset!: () => void

  get state(): true | false | null {
    if (this.value === undefined) return null
    return validInputFile(this.value, this.strict ?? false)
  }

  get localValue(): File | null {
    if (this.value === undefined) return null
    return this.value.value
  }

  set localValue(localValue: File | null) {
    if (this.reset !== undefined) this.reset()
    if (this.value === undefined) {
      /**/
    } else {
      const input = this.value
      input.value = localValue
      this.$emit('input', input)
    }
  }
}
