
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { Menu, MenuItem, MenuBar, isMenuItem, isDivider, Back, isBack, Divider } from '@/models/menus'

@Component
export default class NordicCoolMenu extends Vue {
  @Prop(Object) readonly menuBar!: MenuBar

  @Prop({ type: Function, default: undefined }) readonly select!: (id: number) => void | undefined

  get navbarStyles(): string {
    if (this.menuBar.hasSubMenu()) {
      return 'navbar-no-shadow'
    }
    return 'navbar-shadow'
  }

  subMenuItemStyles(item: (MenuItem | Back | Divider)): string {
    if (isMenuItem(item) && item.active) return 'sub-meny-active'
    return 'sub-meny-inactive'
  }

  checkMenuItem(item: (MenuItem | Back | Divider)): boolean {
    return isMenuItem(item)
  }

  checkBack(item: (MenuItem | Back | Divider)): boolean {
    return isBack(item)
  }

  checkDivider(item: (MenuItem | Back | Divider)): boolean {
    return isDivider(item)
  }

  get appMenuItem(): MenuItem {
    return this.menuBar.appMenuItem
  }

  get topItems(): (MenuItem | Back | Divider)[] {
    return this.menuBar.topMenu.items
  }

  get subItems(): (MenuItem | Back | Divider)[] {
    return this.menuBar.currentSubMenu()?.items ?? []
  }

  get extendedItems(): (MenuItem | Back | Divider)[] {
    return this.menuBar.extendedMenu.menu.items
  }

  get fullName(): string {
    return this.menuBar.extendedMenu.fullName ?? ''
  }

  get companyName(): string {
    return this.menuBar.extendedMenu.companyName ?? ''
  }

  get selected(): number {
    const { companies } = this.menuBar.extendedMenu
    return companies.find((it) => it.selected)?.id ?? 0
  }

  set selected(v: number) {
    this.select(v)
  }

  get options(): { value: number; text: string }[] {
    const { companies } = this.menuBar.extendedMenu
    return companies.map((it) => ({ value: it.id, text: it.title }))
  }

  mounted() {
    const element = document.querySelector('.sub-meny-active')
    if (element != null) {
      this.$nextTick(() => element.scrollIntoView({ behavior: 'smooth', inline: 'center' }))
    }
  }
}
