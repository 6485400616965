
import Vue from 'vue'
import router from '@/router'
import Component from 'vue-class-component'
import { namespace } from 'vuex-class'
import { MenuBar } from '@/models/menus'
import { APIError } from '@/store/modules/creditorsAPI'

const Authentications = namespace('authentications')
const Navigation = namespace('navigation')

@Component
export default class EnrollSuccessView extends Vue {
  @Navigation.Getter getMenuBar!: MenuBar

  @Authentications.Action switchCurrentCreditor!: (creditorId: number) => Promise<true | APIError>

  async switchCreditor(creditorId: number) {
    if (await this.switchCurrentCreditor(creditorId) === true) router.push('/cases-overviews')
  }

  next() {
    router.push('/cases')
  }
}
