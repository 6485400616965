
import Vue from 'vue'
import router from '@/router'
import Component from 'vue-class-component'
import { namespace } from 'vuex-class'
import { State2Way } from 'vuex-class-state2way'
import { Alert } from '@/models/alerts'
import { InputText, InputNumber, validInputs } from '@/models/validations'
import { MenuBar } from '@/models/menus'
import { APIError } from '@/store/modules/creditorsAPI'

const Authentications = namespace('authentications')
const Creditors = namespace('creditors')
const Navigation = namespace('navigation')

@Component
export default class EnrollCreditorView extends Vue {
  @Navigation.Getter getMenuBar!: MenuBar

  @Creditors.Action createCreditor!: () => Promise<true | APIError>
  @Creditors.Action lookupAddress!: () => Promise<true | APIError>
  @Authentications.Action switchCurrentCreditor!: (creditorId: number) => Promise<true | APIError>

  @State2Way('creditors/setAlert', (state) => state.creditors.alert) alert!: Alert | undefined
  @State2Way('creditors/setName', (state) => state.creditors.name) name!: InputText
  @State2Way('creditors/setAddress', (state) => state.creditors.address) address!: InputText
  @State2Way('creditors/setPostalCode', (state) => state.creditors.postalCode) postalCode!: InputNumber
  @State2Way('creditors/setCity', (state) => state.creditors.city) city!: InputText
  @State2Way('creditors/setBankgiro', (state) => state.creditors.bankgiro) bankgiro!: InputText

  address_strict = false
  bank_strict = false
  busy = true

  async switchCreditor(creditorId: number) {
    if (await this.switchCurrentCreditor(creditorId) === true) router.push('/cases-overviews')
  }

  async mounted() {
    if (await this.lookupAddress() === true) {
      this.address_strict = true
    }
    this.busy = false
  }

  async next() {
    const inputs = [this.name, this.address, this.postalCode, this.city, this.bankgiro]
    this.address_strict = true
    this.bank_strict = true
    if (validInputs(inputs, true)) {
      if (await this.createCreditor() === true) router.push('/enroll-success')
    }
  }
}
