import {
  ActionTree, GetterTree, Module, MutationTree,
} from 'vuex'
import { RootState } from '@/store/index'
import {
  creditorsAPI, APIRequest, Pagination, PageModel, Page, APIError, missingError,
} from '@/store/modules/creditorsAPI'
import { CaseHistoryLogsGetModel } from '@/models/history'
import { Alert, apiError } from '@/models/alerts'
import { Case } from '@/store/modules/cases'
import * as mock from '@/mockdata/caseHistoryLogs'

export interface CaseHistoryLog {
  caseId: number;
  caseHistoryLogId: number;
  date: string;
  headline: string;
}

export interface CaseHistoryLogsState {
  alert: Alert | undefined;
  caseHistoryLogs?: Page<CaseHistoryLog>;
}

export const state: CaseHistoryLogsState = {
  alert: undefined,
  caseHistoryLogs: undefined,
}

const namespaced = true

export const getters: GetterTree<CaseHistoryLogsState, RootState> = {
  getAlert(state): Alert | undefined {
    return state.alert
  },
  getHistoryLogs(state, getters, rootState, rootGetters): Page<CaseHistoryLog> | undefined {
    return state.caseHistoryLogs
  },
}

export const mutations: MutationTree<CaseHistoryLogsState> = {
  setAlert(state, alert: Alert | undefined) {
    state.alert = alert
  },
  setCaseHistoryLogs(state, caseHistoryLogs: Page<CaseHistoryLog>) {
    state.caseHistoryLogs = caseHistoryLogs
  },
}

export const actions: ActionTree<CaseHistoryLogsState, RootState> = {
  async refreshCaseHistoryLogs(store, page: number): Promise<Page<CaseHistoryLog> | APIError> {
    console.log(`REFRESHING CASE HISTORY LOGS ${page} START`)
    const currentCase: Case | undefined = store.rootGetters['cases/getCurrentCase']

    if (currentCase === undefined) return missingError('currentCase')

    // Build pagination from requested page
    const pagination = new Pagination(10, page)

    const request = <APIRequest> {
      method: 'get',
      path: `/case-history-logs?case_id=${currentCase.caseId}`,
      extra: currentCase.caseId,
      action: 'caseHistoryLogs/recieveCaseHistoryLogsGetModel',
      mock: mock.mockCaseHistoryLogsGetModel(pagination),
      paging: pagination,
    }
    const result: true | APIError = await store.dispatch('creditorsAPI/call', request)
    if (result !== true) {
      apiError(store, result)
      return result
    }
    return store.getters.getHistoryLogs
  },

  async recieveCaseHistoryLogsGetModel(store, page: PageModel<CaseHistoryLogsGetModel>) {
    const caseId = page.extra as number

    // Transform each case to a case overview
    const caseHistoryLogs: Page<CaseHistoryLog> = Page.transform(page, ((it) => <CaseHistoryLog> {
      caseId,
      caseHistoryLogId: it.case_history_log_id,
      date: it.date,
      headline: it.headline,
    }))

    store.commit('setCaseHistoryLogs', caseHistoryLogs)
    console.log('REFRESHING CASE HISTORY LOGS END')
  },
}

export const caseHistoryLogs: Module<CaseHistoryLogsState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations,
  modules: {
    creditorsAPI,
  },
}
