
import Vue from 'vue'
import router from '@/router'
import Component from 'vue-class-component'
import { namespace } from 'vuex-class'
import { Page, APIError, isPage } from '@/store/modules/creditorsAPI'
import { MenuBar } from '@/models/menus'
import { CreditorUserMessage } from '@/store/modules/creditorUserMessages'
import { CreditorUserMessageGroup } from '@/store/modules/creditorUserMessageGroups'
import { CreditorUserMessageSubject } from '@/store/modules/creditorUserMessageSubjects'

const Authentications = namespace('authentications')
const Cases = namespace('cases')
const CreditorUserMessageGroups = namespace('creditorUserMessageGroups')
const CreditorUserMessages = namespace('creditorUserMessages')
const CreditorUserMessageSubjects = namespace('creditorUserMessageSubjects')
const Navigation = namespace('navigation')

@Component
export default class MessagesInboxView extends Vue {
  @CreditorUserMessageGroups.Getter getAllCreditorUserMessageGroups!: CreditorUserMessageGroup[]
  @CreditorUserMessageSubjects.Getter getAllCreditorUserMessageSubjects!: CreditorUserMessageSubject[]
  @Navigation.Getter getMenuBar!: MenuBar

  @Authentications.Action switchCurrentCreditor!: (creditorId: number) => Promise<true | APIError>
  @Cases.Action switchCurrentCaseId!: (caseId: number) => Promise<true | APIError>
  @CreditorUserMessageGroups.Action refreshCreditorUserMessageGroups!: (page: number) => Promise<Page<CreditorUserMessageGroup> | APIError>
  @CreditorUserMessages.Action refreshInbox!: (page: number) => Promise<Page<CreditorUserMessage> | APIError>
  @CreditorUserMessageSubjects.Action refreshCreditorUserMessageSubjects!: (page: number) => Promise<Page<CreditorUserMessageSubject> | APIError>

  busy = false

  inboxPage: Page<CreditorUserMessage> = new Page<CreditorUserMessage>([], 0, 10, 1)

  async switchCreditor(creditorId: number) {
    if (await this.switchCurrentCreditor(creditorId) === true) router.push('/cases-overviews')
  }

  sentView() {
    router.push('/messages-sent')
  }

  caseDetails(data: {item: CreditorUserMessage}) {
    this.switchCurrentCaseId(data.item.caseId)
    router.push({ name: 'CaseDetailsView', params: { source: 'inbox' } })
  }

  caseMessages(data: {item: CreditorUserMessage}) {
    this.switchCurrentCaseId(data.item.caseId)
    router.push({ name: 'CaseDetailsView', params: { source: 'inbox' } })
  }

  get creditorUserMessageGroupsOptions(): { value: number; text: string }[] {
    return this.getAllCreditorUserMessageGroups.map((it) => ({ value: it.groupMessageId, text: it.name }))
  }

  get creditorUserMessageSubjectsOptions(): { value: string; text: string }[] {
    return this.getAllCreditorUserMessageSubjects.map((it) => ({ value: it.subject, text: it.subject }))
  }

  inboxProvider(ctx: { currentPage: number; perPage: number}, callback: (items: any[]) => void): any[] | null {
    this.refreshInbox(ctx.currentPage)
      .then((page) => {
        if (isPage(page)) {
          this.inboxPage = page ?? this.inboxPage
          callback(page?.items ?? [])
        } else {
          callback([])
        }
      })
      .catch(() => {
        callback([])
      })
    return null
  }

  get fields(): { key: string; label: string; sortable: boolean; tdClass: string }[] {
    return [
      {
        key: 'subject', label: 'Ärende', sortable: true, tdClass: '',
      },
      {
        key: 'caseId', label: 'Akt.nr', sortable: false, tdClass: '',
      },
      {
        key: 'faktNr', label: 'Fakt.nr', sortable: false, tdClass: '',
      },
      {
        key: 'groupMessageId', label: 'Grupp', sortable: true, tdClass: '',
      },
      {
        key: 'clientId', label: 'Avd.', sortable: false, tdClass: '',
      },
      {
        key: 'createDate', label: 'Datum', sortable: false, tdClass: '',
      },
      {
        key: 'showDetails', label: '', sortable: false, tdClass: '',
      },
    ]
  }

  async mounted() {
    await this.refreshCreditorUserMessageGroups(1)
    await this.refreshCreditorUserMessageSubjects(1)
    this.busy = false
  }
}

