
import Vue from 'vue'
import router from '@/router'
import Component from 'vue-class-component'
import { namespace } from 'vuex-class'
import { State2Way } from 'vuex-class-state2way'
import { Alert } from '@/models/alerts'
import { InputText, validInputs } from '@/models/validations'
import { MenuBar } from '@/models/menus'
import { APIError } from '@/store/modules/creditorsAPI'

const Authentications = namespace('authentications')
const Creditors = namespace('creditors')
const Navigation = namespace('navigation')

@Component
export default class EnrollStartView extends Vue {
  @Navigation.Getter getMenuBar!: MenuBar

  @Creditors.Mutation setCurrentCreditorOptionId!: (creditorOptionId: number) => void

  @Creditors.Action startCreditorEnroll!: () => void
  @Authentications.Action switchCurrentCreditor!: (creditorId: number) => Promise<true | APIError>

  @State2Way('creditors/setAlert', (state) => state.creditors.alert) alert!: Alert | undefined
  @State2Way('creditors/setOrganisationId', (state) => state.creditors.organisationId) organisationId!: InputText

  strict = false

  async reset() {
    this.strict = false
  }

  async switchCreditor(creditorId: number) {
    if (await this.switchCurrentCreditor(creditorId) === true) router.push('/cases-overviews')
  }

  next() {
    const inputs = [this.organisationId]
    this.strict = true
    if (validInputs(inputs, this.strict)) {
      router.push('/enroll-creditor')
    }
  }

  async mounted() {
    this.startCreditorEnroll()
  }
}
