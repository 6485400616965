import { CreditorUserDepartmentAccessesGetModel, CreditorUserDepartmentAccessesPostResponseModel, CreditorUserDepartmentAccessesPostModel } from '@/models/creditors'
import { PageModel, Pagination } from '@/store/modules/creditorsAPI'

export function mockCreditorUserDepartmentAccessesPostResponseModel(data: CreditorUserDepartmentAccessesPostModel[]): CreditorUserDepartmentAccessesPostResponseModel[] {
  return [{
  }]
}

export function mockCreditorUserDepartmentAccessesGetModel(pagination: Pagination): PageModel<CreditorUserDepartmentAccessesGetModel> {
  let results = mockCreditorUserDepartmentAccessesGetModels
  const total = results.length

  // Paging
  const start = (pagination.page - 1) * pagination.maxResults
  const end = start + pagination.maxResults
  results = results.slice(start, end)

  return {
    _total: total,
    _max_results: pagination.maxResults,
    _page: pagination.page,
    extra: undefined,
    _items: results,
  }
}

const mockCreditorUserDepartmentAccessesGetModels: CreditorUserDepartmentAccessesGetModel[] = [
]
