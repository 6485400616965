
import Vue from 'vue'
import router from '@/router'
import Component from 'vue-class-component'
import { namespace } from 'vuex-class'
import { State2Way } from 'vuex-class-state2way'
import { Alert } from '@/models/alerts'
import { InputFile, InputSelect, validInputs } from '@/models/validations'
import { MenuBar } from '@/models/menus'
import { DebtorOption } from '@/store/modules/caseDebtors'
import { CreditorDepartment } from '@/store/modules/creditorDepartments'
import { APIError } from '@/store/modules/creditorsAPI'

const Authentications = namespace('authentications')
const Cases = namespace('cases')
const CreditorDepartments = namespace('creditorDepartments')
const Navigation = namespace('navigation')

@Component
export default class RegisterImportStartView extends Vue {
  @Navigation.Getter getMenuBar!: MenuBar

  @Cases.Mutation setImportCSV!: (csv: any[] | undefined) => void

  @Authentications.Action switchCurrentCreditor!: (creditorId: number) => Promise<true | APIError>
  @Cases.Action startCreatingReminder!: () => void

  @State2Way('cases/setAlert', (state) => state.cases.alert) alert!: Alert | undefined
  @State2Way('cases/setImportFile', (state) => state.cases.importFile) importFile!: InputFile
  @State2Way('creditorDepartments/setCreditorDepartment', (state) => state.creditorDepartments.creditorDepartment) creditorDepartment!: InputSelect

  strict = false
  disabled = true
  _csv: any[] = []
  departmentCount = 0

  async reset() {
    this.strict = false
  }

  get csv(): any[] { return this._csv }
  set csv(csv: any[]) {
    this.disabled = false
    this._csv = csv
  }

  get hasDepartments(): boolean { return this.creditorDepartment.optionCount > 1 }

  async switchCreditor(creditorId: number) {
    if (await this.switchCurrentCreditor(creditorId) === true) router.push('/cases-overviews')
  }

  get csvFields(): any {
    return {
      isPrivate: this.$i18n.tc('common.fields.isPrivate'),
      organisationId: this.$i18n.tc('common.fields.orgPersonId'),
      invoiceNumber: this.$i18n.tc('common.fields.invoiceNumber'),
      invoiceDescription: this.$i18n.tc('common.fields.description'),
      invoiceDate: this.$i18n.tc('common.fields.invoiceDate'),
      invoiceDueDate: this.$i18n.tc('common.fields.invoiceDueDate'),
      remainingCapital: this.$i18n.tc('common.fields.remainingCapital'),
    }
  }

  next() {
    const inputs = [this.creditorDepartment]
    this.strict = true
    if (validInputs(inputs, this.strict)) {
      this.setImportCSV(this._csv)
      router.push('/register-import-preview')
    }
  }

  async mounted() {
    this.startCreatingReminder()
  }
}
