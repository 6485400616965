
import Vue from 'vue'
import router from '@/router'
import { namespace } from 'vuex-class'
import { State2Way } from 'vuex-class-state2way'
import Component from 'vue-class-component'
import { MenuBar } from '@/models/menus'
import { Alert } from '@/models/alerts'
import { Page, APIError } from '@/store/modules/creditorsAPI'
import { FieldType } from '@/components/NordicCoolTable.vue'
import { Case } from '@/store/modules/cases'

const Authentications = namespace('authentications')
const Cases = namespace('cases')
const Navigation = namespace('navigation')

@Component
export default class CasesClosedView extends Vue {
  @Navigation.Getter getMenuBar!: MenuBar

  @Authentications.Action switchCurrentCreditor!: (creditorId: number) => Promise<true | APIError>
  @Cases.Action refreshClosedCases!: (page: number) => Promise<Page<Case> | APIError>

  @State2Way('cases/setAlert', (state) => state.cases.alert) alert!: Alert | undefined

  async switchCreditor(creditorId: number) {
    if (await this.switchCurrentCreditor(creditorId) === true) router.push('/cases-overviews')
  }

  closedCaseItems(page: number): Promise<Page<Case> | APIError> {
    return this.refreshClosedCases(page)
  }

  get closedCaseFields(): FieldType[] {
    return [
      {
        key: 'caseId', label: this.$i18n.tc('common.fields.id'), sortable: false, size: 'small', alignment: 'left',
      },
      {
        key: 'invoiceInitialCapitalFormatted', label: this.$i18n.tc('common.fields.invoiceInitialCapital'), sortable: false, size: 'small', alignment: 'left',
      },
      {
        key: 'invoiceDatesFormatted', label: this.$i18n.tc('common.fields.invoiceDate'), sortable: false, size: 'small', alignment: 'left',
      },
      {
        key: 'invoiceNumbers', label: this.$i18n.tc('common.fields.invoiceNumber'), sortable: false, size: 'small', alignment: 'left',
      },
      {
        key: 'mainDebtorName', label: this.$i18n.tc('cases.header.mainDebtorName'), sortable: false, size: 'flex', alignment: 'left',
      },
      {
        key: 'registrationDate', label: this.$i18n.tc('cases.header.registrationDate'), sortable: false, size: 'small', alignment: 'right',
      },
      {
        key: 'formattedClosureDate', label: this.$i18n.tc('cases.header.closureDate'), sortable: false, size: 'small', alignment: 'right',
      },
      {
        key: 'closureCode', label: this.$i18n.tc('cases.header.closureCode'), sortable: false, size: 'small', alignment: 'right',
      },
    ]
  }
}
