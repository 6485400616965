import { CreditorSelfRegistrationsPostModel, CreditorSelfRegistrationsPostResponseModel, CreditorDetailsModel } from '@/models/creditors'
import { CreditSafeCompanyResponseModel, CreditSafePersonalResponseModel } from '@/models/creditsafe'

export function mockCreditorDetailsModel(): CreditorDetailsModel {
  return {
  }
}

export function mockCreditorSelfRegistrationsPostResponseModel(data: CreditorSelfRegistrationsPostModel): CreditorSelfRegistrationsPostResponseModel {
  return {
  }
}

export function mockCreditSafeCompanyResponseModel(organisationId: string | undefined): CreditSafeCompanyResponseModel {
  var f_tax: string | null
  switch (organisationId) {
    case '1111111111':
      f_tax = 'nO'
      break
    case '2222222222':
      f_tax = null
      break
    default:
      f_tax = 'yEs'
  }
  return {
  }
}

export function mockCreditSafePersonalResponseModel(): CreditSafePersonalResponseModel {
  return {
  }
}
