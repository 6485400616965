import {
  ActionTree, GetterTree, Module, MutationTree,
} from 'vuex'
import { RootState } from '@/store/index'
import {
  creditorsAPI, APIRequest, Pagination, PageModel, Page, APIError,
} from '@/store/modules/creditorsAPI'
import { CreditorUserMessageSubjectsGetModel } from '@/models/creditors'
import { Alert, apiError } from '@/models/alerts'
import * as mock from '@/mockdata/creditorUserMessageSubjects'

export interface CreditorUserMessageSubject {
  subject: string;
}

export interface CreditorUserMessageSubjectState {
  alert: Alert | undefined;
  creditorUserMessageSubjects?: Page<CreditorUserMessageSubject>;
}

export const state: CreditorUserMessageSubjectState = {
  alert: undefined,
  creditorUserMessageSubjects: undefined,
}

const namespaced = true

export const getters: GetterTree<CreditorUserMessageSubjectState, RootState> = {
  getAlert(state): Alert | undefined {
    return state.alert
  },
  getAllCreditorUserMessageSubjects(state): CreditorUserMessageSubject[] {
    return state.creditorUserMessageSubjects?.items ?? []
  },
  getCreditorUserMessageSubjects(state): Page<CreditorUserMessageSubject> | undefined {
    return state.creditorUserMessageSubjects
  },
}

export const mutations: MutationTree<CreditorUserMessageSubjectState> = {
  setAlert(state, alert: Alert | undefined) {
    state.alert = alert
  },
  setCreditorUserMessageSubjects(state, creditorUserMessageSubjects: Page<CreditorUserMessageSubject>) {
    state.creditorUserMessageSubjects = creditorUserMessageSubjects
  },
}

export const actions: ActionTree<CreditorUserMessageSubjectState, RootState> = {
  async refreshCreditorUserMessageSubjects(store, page: number): Promise<Page<CreditorUserMessageSubject> | APIError> {
    console.log(`REFRESHING CREDITOR USER MESSAGE SUBJECTS ${page} START`)

    // Build pagination from requested page
    const pagination = new Pagination(50, page)

    const request = <APIRequest> {
      method: 'get',
      path: '/creditor-user-message-subjects',
      action: 'creditorUserMessageSubjects/recieveCreditorUserMessageSubjectsGetModel',
      mock: mock.mockCreditorUserMessageSubjectsGetModel(pagination),
      paging: pagination,
    }
    const result: true | APIError = await store.dispatch('creditorsAPI/call', request)
    if (result !== true) {
      apiError(store, result)
      return result
    }
    return store.getters.getCreditorUserMessageSubjects
  },

  async recieveCreditorUserMessageSubjectsGetModel(store, page: PageModel<CreditorUserMessageSubjectsGetModel>) {
    // Transform
    const creditorUserMessageSubjects: Page<CreditorUserMessageSubject> = Page.transform(page, ((it) => ({
      subject: it.subject,
    })))
    store.commit('setCreditorUserMessageSubjects', creditorUserMessageSubjects)

    console.log('REFRESHING CREDITOR USER MESSAGE SUBJECTS END')
  },
}

export const creditorUserMessageSubjects: Module<CreditorUserMessageSubjectState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations,
  modules: {
    creditorsAPI,
  },
}
