import i18n from '@/plugins/i18n'
import { ActionTree, GetterTree, Module, MutationTree } from 'vuex'
import { RootState } from '@/store/index'
import { creditorsAPI, APIRequest, Pagination, PageModel, Page, APIError, missingError } from '@/store/modules/creditorsAPI'
import { CreditorDepartmentsGetModel, CreditorDepartmentsDetailsModel, CreditorDepartmentsPatchModel, CreditorDepartmentsPatchResponseModel } from '@/models/creditors'
import { Alert, apiError } from '@/models/alerts'
import * as mock from '@/mockdata/creditorDepartments'
import { InputText, InputNumber, InputSelect, InputOption } from '@/models/validations'

export interface CreditorDepartment {
  creditorDepartmentId: number;
  creditorId: number;
  name: string;
  creditorNumber: number;
  departmentNumber: number;
  organisationId: string;
  currency: string;

  plusgiro?: string;
  bankgiro?: string;
  iban?: string;
  bic?: string;
  address?: string;
  coAddress?: string;
  postalCode?: number;
  city?: string;
  countryCode?: string;
}

export interface CreditorDepartmentsState {
  alert: Alert | undefined;
  creditorDepartments?: Page<CreditorDepartment>;
  currentCreditorDepartment?: CreditorDepartment;
  currentEtag?: string;

  creditorDepartment: InputSelect;
  searchCreditorDepartment: InputSelect;

  name: InputText;
  address: InputText;
  postalCode: InputNumber;
  city: InputText;

  currency: InputText;
  bankgiro: InputText;
  iban: InputText;
  bic: InputText;
}

function resetCreditorDepartment(creditorDepartments: Page<CreditorDepartment> | null = null): InputSelect {
  const value = creditorDepartments?.items[0].creditorDepartmentId ?? null
  const items: CreditorDepartment[] = creditorDepartments?.items ?? []
  const options = items.map((it) => <InputOption> { value: it.creditorDepartmentId, text: it.name })
  return InputSelect.standard({ value: value, options: options })
}

function resetSearchCreditorDepartment(creditorDepartments: Page<CreditorDepartment> | null = null): InputSelect {
  const value = 0
  const none: (CreditorDepartment | undefined)[] = [undefined]
  const items: (CreditorDepartment | undefined)[] = creditorDepartments?.items ?? []
  const options = [...none, ...items].map((it) => <InputOption> { value: it?.creditorDepartmentId ?? 0, text: it?.name ?? i18n.tc('creditor.departments.all') })
  return InputSelect.standard({ value: value, options: options })
}

function resetName(value: string | null = null): InputText {
  return InputText.standard({ value: value, minLength: null, maxLength: 50, required: true })
}
function resetAddress(value: string | null = null): InputText {
  return InputText.standard({ value: value, minLength: null, maxLength: 35, required: true })
}
function resetPostalCode(value: number | null = null): InputNumber {
  return InputNumber.lengthInRange({ value: value, minLength: 5, maxLength: 5, required: true })
}
function resetCity(value: string | null = null): InputText {
  return InputText.standard({ value: value, minLength: null, maxLength: 50, required: true })
}

function resetCurrency(value: string | null = null): InputText {
  return InputText.standard({ value: value, minLength: 3, maxLength: 3, required: true })
}

function resetBankgiro(value: string | null = null): InputText {
  return InputText.standard({ value: value, minLength: 7, maxLength: null, required: true })
}

function resetIban(value: string | null = null): InputText {
  return InputText.standard({ value: value, minLength: 7, maxLength: null, required: true })
}

function resetBic(value: string | null = null): InputText {
  return InputText.standard({ value: value, minLength: 7, maxLength: null, required: true })
}

export const state: CreditorDepartmentsState = {
  alert: undefined,
  creditorDepartments: undefined,
  currentCreditorDepartment: undefined,
  currentEtag: undefined,

  creditorDepartment: resetCreditorDepartment(),
  searchCreditorDepartment: resetSearchCreditorDepartment(),

  name: resetName(),
  address: resetAddress(),
  postalCode: resetPostalCode(),
  city: resetCity(),

  currency: resetCurrency(),
  bankgiro: resetBankgiro(),
  iban: resetIban(),
  bic: resetBic(),
}

const namespaced = true

export const getters: GetterTree<CreditorDepartmentsState, RootState> = {
  getAlert(state): Alert | undefined {
    return state.alert
  },
  getAllCreditorDepartments(state): CreditorDepartment[] {
    return state.creditorDepartments?.items ?? []
  },
  getCreditorDepartments(state): Page<CreditorDepartment> | undefined {
    return state.creditorDepartments
  },
  getCurrentCreditorDepartment(state): CreditorDepartment | undefined {
    return state.currentCreditorDepartment
  },
  getCurrentEtag(state): string | undefined {
    return state.currentEtag
  },

  getCreditorDepartment(state): InputSelect {
    return state.creditorDepartment
  },
  getSearchCreditorDepartment(state): InputSelect {
    return state.searchCreditorDepartment
  },

  getName(state): InputText {
    return state.name
  },
  getAddress(state): InputText {
    return state.address
  },
  getPostalCode(state): InputNumber {
    return state.postalCode
  },
  getCity(state): InputText {
    return state.city
  },
  getCurrency(state): InputText {
    return state.currency
  },
  getBankgiro(state): InputText {
    return state.bankgiro
  },
  getIban(state): InputText {
    return state.iban
  },
  getBic(state): InputText {
    return state.bic
  },
}

export const mutations: MutationTree<CreditorDepartmentsState> = {
  setAlert(state, alert: Alert | undefined) {
    state.alert = alert
  },
  setCreditorDepartments(state, creditorDepartments: Page<CreditorDepartment>) {
    state.creditorDepartments = creditorDepartments
    state.creditorDepartment = resetCreditorDepartment(creditorDepartments)
    state.searchCreditorDepartment = resetSearchCreditorDepartment(creditorDepartments)
  },
  setCurrentCreditorDepartment(state, creditorDepartment?: CreditorDepartment) {
    state.currentCreditorDepartment = creditorDepartment

    state.name = resetName(creditorDepartment?.name)
    state.address = resetAddress(creditorDepartment?.address)
    state.postalCode = resetPostalCode(creditorDepartment?.postalCode)
    state.city = resetCity(creditorDepartment?.city)

    state.currency = resetCurrency(creditorDepartment?.currency)
    state.bankgiro = resetBankgiro(creditorDepartment?.bankgiro)
    state.iban = resetIban(creditorDepartment?.iban)
    state.bic = resetBic(creditorDepartment?.bic)
  },
  setCurrentEtag(state, currentEtag?: string) {
    state.currentEtag = currentEtag
  },

  setCreditorDepartment(state, creditorDepartment: InputSelect) {
    state.creditorDepartment = creditorDepartment
  },
  setSearchCreditorDepartment(state, searchCreditorDepartment: InputSelect) {
    state.searchCreditorDepartment = searchCreditorDepartment
  },

  setName(state, name: InputText) {
    state.name = name
  },
  setAddress(state, address: InputText) {
    state.address = address
  },
  setPostalCode(state, postalCode: InputNumber) {
    state.postalCode = postalCode
  },
  setCity(state, city: InputText) {
    state.city = city
  },
  setCurrency(state, currency: InputText) {
    state.currency = currency
  },
  setBankgiro(state, bankgiro: InputText) {
    state.bankgiro = bankgiro
  },
  setIban(state, iban: InputText) {
    state.iban = iban
  },
  setBic(state, bic: InputText) {
    state.bic = bic
  },
}

export const actions: ActionTree<CreditorDepartmentsState, RootState> = {

  /**
   * Fetches the first 50 departments, but hopefully this will mean all departments
   *
   * @param store The vuex store.
   * @param page The page number to start at.
   */
  async refreshCreditorDepartments(store, page = 1): Promise<Page<CreditorDepartment> | APIError> {
    console.log(`REFRESHING CREDITOR DEPARTMENTS ${page}`)

    // Build pagination from requested page
    const pagination = new Pagination(50, page)

    const request = <APIRequest> {
      method: 'get',
      path: '/creditor-departments',
      action: 'creditorDepartments/recieveCreditorDepartmentsGetModels',
      mock: mock.mockCreditorDepartmentsGetModel(pagination),
      paging: pagination,
    }
    const result: true | APIError = await store.dispatch('creditorsAPI/call', request)
    if (result !== true) {
      apiError(store, result)
      return result
    }
    return store.getters.getCreditorDepartments
  },

  /**
   * Recieves a page of Creditor Department Models and turning them into Creditor Departments
   * and assign them to the store. It will also automatically assign the first department as
   * being "current".
   *
   * @param store The vuex store.
   * @param page The page to recieve.
   */
  async recieveCreditorDepartmentsGetModels(store, page: PageModel<CreditorDepartmentsGetModel>) {
    // Transform
    const creditorDepartments: Page<CreditorDepartment> = Page.transform(page, ((it) => <CreditorDepartment> {
      creditorDepartmentId: it.creditor_department_id,
      creditorId: it.creditor_id,
      name: it.name,
      creditorNumber: it.creditor_number,
      departmentNumber: it.department_number,
      organisationId: it.organisation_id,
      currency: it.currency,
    }))
    // Commit them to the store
    store.commit('setCreditorDepartments', creditorDepartments)
    // Automatically select the first one
    store.commit('setCurrentCreditorDepartment', creditorDepartments.items[0])
  },

  /**
   * Refreshes the details for the current creditor deparment (if any)
   *
   * @param store The vuex store.
   */
  async refreshCurrentCreditorDepartmentDetails(store): Promise<true | APIError> {
    console.log('REFRESHING CREDITOR DEPARTMENT')
    const current = store.state.currentCreditorDepartment

    if (current === null) return true

    const request = <APIRequest> {
      method: 'get',
      path: `/creditor-departments/${current?.creditorDepartmentId}`,
      action: 'creditorDepartments/recieveCreditorDepartmentsDetailsModel',
      mock: mock.mockCreditorDepartmentsDetailsModel(current),
    }
    const result: true | APIError = await store.dispatch('creditorsAPI/call', request)
    if (result !== true) {
      apiError(store, result)
      return result
    }
    return true
  },

  /**
   * Recieves a response for refreshing a creditor department
   *
   * @param store The vuex store.
   * @param details The model for creditor department information.
   */
  async recieveCreditorDepartmentsDetailsModel(store, details: CreditorDepartmentsDetailsModel) {
    const creditorDepartment: CreditorDepartment = {
      creditorDepartmentId: details.creditor_department_id,
      creditorId: details.creditor_id,
      name: details.name,
      creditorNumber: details.creditor_number,
      departmentNumber: details.department_number,
      organisationId: details.organisation_id,
      currency: details.currency,

      plusgiro: details.plusgiro,
      bankgiro: details.bankgiro,
      iban: details.iban,
      bic: details.bic,
      address: details.address,
      coAddress: details.co_address,
      postalCode: details.postal_code,
      city: details.city,
      countryCode: details.country_code,
    }

    store.commit('setCurrentCreditorDepartment', creditorDepartment)
    store.commit('setCurrentEtag', details._etag)
  },

  /**
   * Edits a creditor department
   *
   * @param store The vuex store.
   */
  async editCreditorDepartment(store): Promise<true | APIError> {
    console.log('EDIT CREDITOR DEPARTMENT')
    const current = store.state.currentCreditorDepartment

    if (current === undefined) return missingError('currentCreditorDepartment')

    // Create a PATCH body
    const data = <CreditorDepartmentsPatchModel> {
      name: state.name.toString(),
      currency: state.currency.toString(),
      address: state.address.toString(),
      postal_code: state.postalCode.toNumber(),
      city: state.city.toString(),
      bankgiro: state.bankgiro.toString(),
    }

    // Invoke API
    const request = <APIRequest> {
      method: 'patch',
      path: `/creditor-departments/${current?.creditorDepartmentId}`,
      data: data,
      _etag: store.state.currentEtag,
      action: 'creditorDepartments/recieveCreditorDepartmentsPatchResponseModel',
      mock: mock.mockCreditorDepartmentsPatchResponseModel(current.creditorDepartmentId, data),
    }
    const result: true | APIError = await store.dispatch('creditorsAPI/call', request)
    if (result !== true) {
      apiError(store, result)
      return result
    }

    return true
  },

  /**
   * Recieves a response for editing a creditor department
   *
   * @param store The vuex store.
   * @param response The model for creditor department update.
   */
  async recieveCreditorDepartmentsPatchResponseModel(store, model: CreditorDepartmentsPatchResponseModel) {
    store.commit('setCurrentEtag', model._etag)
  },

  async switchCurrentCreditorDepartmentId(store, creditorDepartmentId?: number) {
    const CreditorDepartment = store.state.creditorDepartments?.items.find((it) => it.creditorDepartmentId === creditorDepartmentId)
    store.commit('setCurrentCreditorDepartment', CreditorDepartment)
  },

  /**
   * Prepares the store for starting a new creditor department
   *
   * @param store The vuex store.
   */
  async startCreatingCreditorDepartment(store) {
    store.commit('setName', resetName())
    store.commit('setAddress', resetAddress())
    store.commit('setPostalCode', resetPostalCode())
    store.commit('setCity', resetCity())

    store.commit('setCurrency', resetCurrency())
    store.commit('setBankgiro', resetBankgiro())
    store.commit('setIban', resetIban())
    store.commit('setBic', resetBic())
  },
}

export const creditorDepartments: Module<CreditorDepartmentsState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations,
  modules: {
    creditorsAPI,
  },
}
