import Vue from 'vue'
import axios from 'axios'
import '@/plugins/bootstrapVue'
import i18n from '@/plugins/i18n'
import '@/plugins/cookies'
import '@/plugins/csvImport'
import '@/plugins/gtm'
import App from '@/App.vue'
import router from '@/router'
import store from '@/store'
import Footer from '@/components/Footer.vue'
import NordicCoolAlert from '@/components/NordicCoolAlert.vue'
import NordicCoolBack from '@/components/NordicCoolBack.vue'
import NordicCoolConfirm from '@/components/NordicCoolConfirm.vue'
import NordicCoolInputCheckbox from '@/components/NordicCoolInputCheckbox.vue'
import NordicCoolInputDate from '@/components/NordicCoolInputDate.vue'
import NordicCoolInputEmail from '@/components/NordicCoolInputEmail.vue'
import NordicCoolInputFile from '@/components/NordicCoolInputFile.vue'
import NordicCoolInputNumber from '@/components/NordicCoolInputNumber.vue'
import NordicCoolInputPhone from '@/components/NordicCoolInputPhone.vue'
import NordicCoolInputSelect from '@/components/NordicCoolInputSelect.vue'
import NordicCoolInputText from '@/components/NordicCoolInputText.vue'
import NordicCoolMenu from '@/components/NordicCoolMenu.vue'
import NordicCoolPage from '@/components/NordicCoolPage.vue'
import NordicCoolTable from '@/components/NordicCoolTable.vue'
import NordicCoolToast from '@/components/NordicCoolToast.vue'
import './registerServiceWorker'

Vue.config.productionTip = false

axios.interceptors.request.use((request) => {
  console.log('REQUEST: ', request)
  return request
})

axios.interceptors.response.use((response) => {
  console.log('RESPONSE:', response)
  return response
})

function noop() {}

if (process.env.NODE_ENV === 'production') {
  console.log = noop
  console.debug = noop
  console.trace = noop
  console.warn = noop
  console.error = noop
}

console.log(`BACKEND_BASE_URL: ${process.env.VUE_APP_BACKEND_BASE_URL}`)
console.log(`APP_ID: ${process.env.VUE_APP_APP_ID}`)

Vue.component('nordic-cool-alert', NordicCoolAlert)
Vue.component('nordic-cool-back', NordicCoolBack)
Vue.component('nordic-cool-confirm', NordicCoolConfirm)
Vue.component('nordic-cool-input-checkbox', NordicCoolInputCheckbox)
Vue.component('nordic-cool-input-date', NordicCoolInputDate)
Vue.component('nordic-cool-input-email', NordicCoolInputEmail)
Vue.component('nordic-cool-input-file', NordicCoolInputFile)
Vue.component('nordic-cool-input-number', NordicCoolInputNumber)
Vue.component('nordic-cool-input-phone', NordicCoolInputPhone)
Vue.component('nordic-cool-input-select', NordicCoolInputSelect)
Vue.component('nordic-cool-input-text', NordicCoolInputText)
Vue.component('nordic-cool-menu', NordicCoolMenu)
Vue.component('nordic-cool-page', NordicCoolPage)
Vue.component('nordic-cool-table', NordicCoolTable)
Vue.component('nordic-cool-toast', NordicCoolToast)

Vue.component('ekrav-footer', Footer)

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount('#app')
