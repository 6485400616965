
import Vue from 'vue'
import router from '@/router'
import { namespace } from 'vuex-class'
import { State2Way } from 'vuex-class-state2way'
import Component from 'vue-class-component'
import { MenuBar } from '@/models/menus'
import { Alert } from '@/models/alerts'
import { Page, APIError } from '@/store/modules/creditorsAPI'
import { FieldSymbol, FieldType } from '@/components/NordicCoolTable.vue'
import { Case, StatusOption } from '@/store/modules/cases'
import { StatusType } from '@/models/cases'
import { InputNumber, InputSelect, InputText, validInputs } from '@/models/validations'

const Authentications = namespace('authentications')
const Cases = namespace('cases')
const CreditorDepartments = namespace('creditorDepartments')
const Navigation = namespace('navigation')

@Component
export default class CasesOngoingView extends Vue {
  @Cases.Getter getActiveCaseMessagesUnread!: number
  @Navigation.Getter getMenuBar!: MenuBar

  @Cases.Action refreshOngoingCases!: (page: number) => Promise<Page<Case> | APIError>
  @Cases.Action switchCurrentCaseId!: (caseId: number) => Promise<true | APIError>
  @Authentications.Action switchCurrentCreditor!: (creditorId: number) => Promise<true | APIError>

  @State2Way('cases/setAlert', (state) => state.cases.alert) alert!: Alert | undefined
  @State2Way('cases/setSearchCaseId', (state) => state.cases.searchCaseId) searchCaseId!: InputNumber
  @State2Way('cases/setSearchReferenceId', (state) => state.cases.searchReferenceId) searchReferenceId!: InputText
  @State2Way('cases/setStatusOption', (state) => state.cases.statusOption) statusOption!: InputSelect
  @State2Way('creditorDepartments/setSearchCreditorDepartment', (state) => state.creditorDepartments.searchCreditorDepartment) searchCreditorDepartment!: InputSelect

  busy = true
  visible = false
  strictSearchCaseId = false
  strictSearchCreditorDepartment = false
  strictSearchReferenceId = false

  get hasUnreadMessages(): boolean {
    return this.getActiveCaseMessagesUnread > 0
  }

  get selectedStatusOption(): number | null {
    return this.statusOption.value
  }

  set selectedStatusOption(v: number | null) {
    this.statusOption.value = v
    this.$root.$emit('bv::refresh::table', 'ongoingCases')
  }

  async clearSearch() {
    this.strictSearchCaseId = false
    this.strictSearchCreditorDepartment = false
    this.strictSearchReferenceId = false
    this.searchCaseId.value = 0
    this.searchCreditorDepartment.value = 0
    this.searchReferenceId.value = ''
    await this.refreshOngoingCases(1)
    this.$root.$emit('bv::refresh::table', 'ongoingCases')
  }

  async advancedSearch() {
    // Toogle validation for inputs based on if they are blank or not so that we don't display
    // green boxes for inputs that are empty
    const inputs: (InputSelect | InputText | InputNumber)[] = [this.searchCreditorDepartment]
    this.strictSearchCreditorDepartment = true
    if (!this.searchCaseId.isBlank) {
      this.strictSearchCaseId = true
      inputs.push(this.searchCaseId)
    }
    if (!this.searchReferenceId.isBlank) {
      this.strictSearchReferenceId = true
      inputs.push(this.searchReferenceId)
    }
    if (validInputs(inputs, true)) {
      await this.refreshOngoingCases(1)
      this.$root.$emit('bv::refresh::table', 'ongoingCases')
    }
  }

  async switchCreditor(creditorId: number) {
    if (await this.switchCurrentCreditor(creditorId) === true) router.push('/cases-overviews')
  }

  async caseDetails(data: {item: Case}) {
    if (await this.switchCurrentCaseId(data.item.caseId) === true) {
      router.push({ name: 'CaseDetailsView', params: { source: 'cases-overviews' } })
    }
  }

  caseSymbol(item: Case): FieldSymbol {
    return item.caseMessagesUnread > 0 ? 'dot' : 'none'
  }

  ongoingCaseItems(page: number): Promise<Page<Case> | APIError> {
    return this.refreshOngoingCases(page)
  }

  get ongoingCaseFields(): FieldType[] {
    return [
      {
        key: 'caseId', label: this.$i18n.tc('common.fields.id'), sortable: false, size: 'small', alignment: 'left',
      },
      {
        key: 'invoiceDatesFormatted', label: this.$i18n.tc('common.fields.invoiceDate'), sortable: false, size: 'small', alignment: 'left',
      },
      {
        key: 'invoiceNumbers', label: this.$i18n.tc('common.fields.invoiceNumber'), sortable: false, size: 'small', alignment: 'left',
      },
      {
        key: 'mainDebtorName', label: this.$i18n.tc('cases.header.mainDebtorName'), sortable: false, size: 'flex', alignment: 'left',
      },
      {
        key: 'registrationDate', label: this.$i18n.tc('cases.header.registrationDate'), sortable: false, size: 'small', alignment: 'right',
      },
      {
        key: 'formattedTotal', label: this.$i18n.tc('cases.header.formattedTotal'), sortable: false, size: 'small', alignment: 'right',
      },
      {
        key: 'status', label: this.$i18n.tc('cases.header.status'), sortable: false, size: 'small', alignment: 'left',
      },
      {
        key: 'symbol', label: '', sortable: false, size: 'symbol', alignment: 'right',
      },
      {
        key: 'actions', label: '', sortable: false, size: 'actions', alignment: 'right',
      },
    ]
  }

  async mounted() {
    const statusType: StatusType | undefined = this.$route.params.status as StatusType
    const statusOptions: StatusOption[] = this.statusOption.options?.map((it) => it.data as StatusOption) ?? []
    if (statusType !== undefined) {
      const option = statusOptions.find((it) => it.id !== 1 && it.matches.includes(statusType))
      this.selectedStatusOption = option?.id ?? null
    } else {
      const option = statusOptions.find((it) => it.id === 1)
      this.selectedStatusOption = option?.id ?? null
    }
    this.clearSearch()
  }
}
