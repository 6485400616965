import i18n from '@/plugins/i18n'
import { ActionTree, GetterTree, Module, MutationTree } from 'vuex'
import { RootState } from '@/store/index'
import { Route } from 'vue-router'
import { authentications, UserToken } from '@/store/modules/authentications'
import { FunctionalScope } from '@/models/authenticates'
import { MenuItem, Menu, Back, Divider, ExtendedMenu, MenuBar, Company } from '@/models/menus'

function casesOverviewItem(active: MenuItem[], functionalScope: FunctionalScope, subMenu?: Menu): MenuItem {
  return MenuItem.submenu({ title: i18n.tc('menu.cases.overview'), active: active, visible: true, path: '/cases-overviews', sub: subMenu })
}

function ongoingCasesItem(active: MenuItem[], functionalScope: FunctionalScope): MenuItem {
  return MenuItem.standard({ title: i18n.tc('menu.cases.ongoing'), active: active, visible: true, path: '/cases-ongoing' })
}

function casesOverviewSubMenu(active: MenuItem[], functionalScope: FunctionalScope, source: string | undefined, caseUnreadMessages: number): Menu {
  return new Menu([
    casesOverviewItem(active, functionalScope, caseDetailsSubMenu(active, functionalScope, source, caseUnreadMessages)),
    ongoingCasesItem(active, functionalScope),
    MenuItem.standard({ title: i18n.tc('menu.cases.closed'), active: active, visible: true, path: '/cases-closed' }),
  ])
}

function casesMenuItem(active: MenuItem[], functionalScope: FunctionalScope, source: string | undefined, caseUnreadMessages: number): MenuItem {
  return MenuItem.submenu({ title: i18n.tc('menu.cases'), active: active, visible: true, path: '/cases', sub: casesOverviewSubMenu(active, functionalScope, source, caseUnreadMessages) })
}

function messagesInboxSubMenu(active: MenuItem[], functionalScope: FunctionalScope): Menu {
  return new Menu([
    MenuItem.standard({ title: 'Översikt', active: active, visible: functionalScope !== 'simplified', path: '/messages-inbox' }),
    MenuItem.placeholder({ title: 'Skicka meddelande', active: active, visible: functionalScope !== 'simplified' }),
  ])
}

function caseDetailsSubMenu(active: MenuItem[], functionalScope: FunctionalScope, source: string | undefined, caseUnreadMessages: number): Menu {
  const back = source === 'inbox' ? inboxMenuItem(active, functionalScope) : ongoingCasesItem(active, functionalScope)
  return new Menu([
    new Back(back),
    MenuItem.standard({ title: i18n.tc('menu.case.details'), active: active, visible: true, path: '/case-details' }),
    MenuItem.standard({ title: 'Skulder', active: active, visible: functionalScope !== 'simplified', path: '/case-debts' }),
    MenuItem.standard({ title: 'Kunder', active: active, visible: functionalScope !== 'simplified', path: '/case-debtors' }),
    MenuItem.standardDotted({ title: i18n.tc('menu.case.messages'), active: active, visible: true, dot: caseUnreadMessages > 0, path: '/case-messages' }),
    MenuItem.standard({ title: i18n.tc('menu.case.history'), active: active, visible: true, path: '/case-history' }),
    MenuItem.standard({ title: i18n.tc('menu.case.documents'), active: active, visible: true, path: '/case-documents' }),
  ])
}

function inboxMenuItem(active: MenuItem[], functionalScope: FunctionalScope): MenuItem {
  return MenuItem.standard({ title: 'Inkorg', active: active, visible: functionalScope !== 'simplified', path: '/messages-inbox' })
}

function messagesMenuItem(active: MenuItem[], functionalScope: FunctionalScope, caseUnreadMessages: number): MenuItem {
  return MenuItem.submenuDotted({ title: 'Meddelanden', active: active, visible: functionalScope !== 'simplified', dot: caseUnreadMessages > 0, path: '/messages-inbox', sub: messagesInboxSubMenu(active, functionalScope) })
}

function registerSubMenu(active: MenuItem[], functionalScope: FunctionalScope): Menu {
  return new Menu([
    MenuItem.group({ title: i18n.tc('menu.register.manually'), active: active, visible: true, paths: ['/register-manually-start', '/register-manually-debtor', '/register-manually-debt', '/register-manually-success'] }),
    MenuItem.group({ title: i18n.tc('menu.register.import'), active: active, visible: true, paths: ['/register-import-start', '/register-import-preview', '/register-import-result'] }),
  ])
}

function registerMenuItem(active: MenuItem[], functionalScope: FunctionalScope): MenuItem {
  return MenuItem.submenu({ title: i18n.tc('menu.register'), active: active, visible: true, path: '/register', sub: registerSubMenu(active, functionalScope) })
}

function creditorOverviewMenuItem(active: MenuItem[], functionalScope: FunctionalScope, subMenu?: Menu): MenuItem {
  return MenuItem.submenu({ title: 'Detaljer', active: active, visible: functionalScope !== 'simplified', path: '/creditor-overview', sub: subMenu })
}

function creditorDepartmentsMenuItem(active: MenuItem[], functionalScope: FunctionalScope, subMenu?: Menu): MenuItem {
  return MenuItem.submenu({ title: 'Avdelningar', active: active, visible: functionalScope !== 'simplified', path: '/creditor-departments', sub: subMenu })
}

function creditorUsersMenuItem(active: MenuItem[], functionalScope: FunctionalScope, subMenu?: Menu): MenuItem {
  return MenuItem.submenu({ title: 'Användare', active: active, visible: functionalScope !== 'simplified', path: '/creditor-users', sub: subMenu })
}

function creditorDepartmentsDetailsSubMenu(active: MenuItem[], functionalScope: FunctionalScope, source: string | undefined): Menu {
  const back = source === 'overview' ? creditorOverviewMenuItem(active, functionalScope) : creditorDepartmentsMenuItem(active, functionalScope, undefined)
  return new Menu([
    new Back(back),
    MenuItem.standard({ title: i18n.tc('menu.department.details'), active: active, visible: true, path: '/creditor-department-edit' }),
  ])
}

function creditorUsersDetailsSubMenu(active: MenuItem[], functionalScope: FunctionalScope, mode: string | undefined, source: string | undefined): Menu {
  const back = source === 'overview' ? creditorOverviewMenuItem(active, functionalScope) : creditorUsersMenuItem(active, functionalScope, undefined)
  return new Menu([
    new Back(back),
    MenuItem.standard({ title: i18n.tc('menu.user.details'), active: active, visible: true, path: '/creditor-user-edit' }),
    MenuItem.standard({ title: 'Avdelningar', active: active, visible: functionalScope !== 'simplified' && mode === 'edit', path: '/creditor-user-department-accesses' }),
    MenuItem.placeholder({ title: 'Grupper', active: active, visible: functionalScope !== 'simplified' && mode === 'edit' }),
    MenuItem.placeholder({ title: 'Relation', active: active, visible: functionalScope !== 'simplified' && mode === 'edit' }),
  ])
}

function caseDepartmentOverviewSubMenu(active: MenuItem[], functionalScope: FunctionalScope, mode: string | undefined, source: string | undefined): Menu {
  return new Menu([
    creditorOverviewMenuItem(active, functionalScope, undefined),
    creditorDepartmentsMenuItem(active, functionalScope, creditorDepartmentsDetailsSubMenu(active, functionalScope, source)),
    creditorUsersMenuItem(active, functionalScope, creditorUsersDetailsSubMenu(active, functionalScope, mode, source)),
  ])
}

function extendedSubMenu(active: MenuItem[], functionalScope: FunctionalScope, mode: string | undefined, source: string | undefined): Menu {
  return new Menu([
    MenuItem.submenu({ title: i18n.tc('menu.company.edit'), active: active, visible: true, path: '/creditor', sub: caseDepartmentOverviewSubMenu(active, functionalScope, mode, source) }),
    MenuItem.divider({ visible: functionalScope !== 'simplified' }),
    MenuItem.standard({ title: i18n.tc('menu.company.create'), active: active, visible: true, path: '/enroll-start' }),
    MenuItem.divider({ visible: true }),
    MenuItem.external({ title: i18n.tc('menu.help'), active: active, visible: true, path: 'https://www.visma.se/inkasso/ekrav/faq/' }),
    MenuItem.standard({ title: i18n.tc('menu.contact'), active: active, visible: true, path: '/support-contact' }),
    MenuItem.divider({ visible: true }),
    MenuItem.standard({ title: i18n.tc('common.logout'), active: active, visible: true, path: '/logout' }),
  ])
}

function extendedMenu(active: MenuItem[], functionalScope: FunctionalScope, mode: string | undefined, source: string | undefined, fullName: string, companyName: string, companies: Company[]): ExtendedMenu {
  return new ExtendedMenu(
    fullName,
    companyName,
    companies,
    extendedSubMenu(active, functionalScope, mode, source),
  )
}

function menuBar(active: MenuItem[], functionalScope: FunctionalScope, caseUnreadMessages: number, mode: string | undefined, source: string | undefined, fullName: string, companyName: string, companies: Company[]): MenuBar {
  return new MenuBar(
    MenuItem.standard({ title: i18n.tc('menu.app'), active: active, visible: true, path: '/cases' }),
    new Menu([
      casesMenuItem(active, functionalScope, source, caseUnreadMessages),
      messagesMenuItem(active, functionalScope, caseUnreadMessages),
      registerMenuItem(active, functionalScope),
    ]),
    extendedMenu(active, functionalScope, mode, source, fullName, companyName, companies),
  )
}

export interface NavigationState {
  active: MenuItem[];
  mode: string | undefined;
  source: string | undefined;
}

export const state: NavigationState = {
  active: [],
  mode: undefined,
  source: undefined,
}

const namespaced = true

export const getters: GetterTree<NavigationState, RootState> = {
  getActive(state): MenuItem[] {
    return state.active
  },
  getMode(state): string | undefined {
    return state.mode
  },
  getSource(state): string | undefined {
    return state.source
  },
  getMenuBar(state, getters, rootState, rootGetters): MenuBar {
    const { active, mode, source } = state
    const creditorId: number = rootGetters.getCurrentCreditorId
    const functionalScope: FunctionalScope = rootGetters.getCurrentFunctionalScope ?? 'simplified'
    const caseUnreadMessages: number = rootGetters['cases/getCurrentCaseMessagesUnread'] ?? 0
    const fullName = getters['authentications/getFullName']
    const currentUserToken: UserToken | undefined = getters['authentications/getCurrentUserToken']
    const companyName = currentUserToken?.creditorName ?? ''
    const companies = getters['authentications/getAllUserTokens'].map((userToken: UserToken) => new Company(userToken.creditorId, userToken.creditorName, userToken.creditorId === creditorId))
    return menuBar(active, functionalScope, caseUnreadMessages, mode, source, fullName, companyName, companies)
  },
}

export const mutations: MutationTree<NavigationState> = {
  setActive(state, active: MenuItem[]) {
    state.active = active
  },
  setMode(state, mode: string | undefined) {
    state.mode = mode
  },
  setSource(state, source: string | undefined) {
    state.source = source
  },
}

export const actions: ActionTree<NavigationState, RootState> = {
  switchActiveRoute(store, route: Route) {
    // Reset current active stack
    const active = store.getters.getMenuBar.match(route.path)
    store.commit('setActive', active)

    const { mode, source } = route.params

    if (typeof mode === 'string') {
      store.commit('setMode', mode)
      console.log(`ACTIVE MODE: ${state.mode}`)
    }
    if (typeof source === 'string') {
      store.commit('setSource', source)
      console.log(`ACTIVE SOURCE: ${state.source}`)
    }

    console.log(`ACTIVE ROUTE: ${route.path}`)
    console.log(`ACTIVE MATCH: ${state.active.map((it) => it.title).join(' -> ')}`)
  },
}

export const navigation: Module<NavigationState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations,
  modules: {
    authentications,
  },
}
