
import Vue from 'vue'
import router from '@/router'
import { namespace } from 'vuex-class'
import { State2Way } from 'vuex-class-state2way'
import { Alert } from '@/models/alerts'
import Component from 'vue-class-component'
import { FieldType } from '@/components/NordicCoolTable.vue'
import { MenuBar } from '@/models/menus'
import { Creditor } from '@/store/modules/creditors'
import { Page, APIError } from '@/store/modules/creditorsAPI'
import { CreditorUserDepartmentAccess } from '@/store/modules/creditorUserDepartmentAccesses'

const Authentications = namespace('authentications')
const Creditors = namespace('creditors')
const CreditorUserDepartmentAccesses = namespace('creditorUserDepartmentAccesses')
const Navigation = namespace('navigation')

@Component
export default class CreditorUserDepartmentAccessesView extends Vue {
  @Navigation.Getter getMenuBar!: MenuBar

  @Authentications.Action switchCurrentCreditor!: (creditorId: number) => Promise<true | APIError>
  @CreditorUserDepartmentAccesses.Action refreshCreditorUserDepartmentAccesses!: (page: number) => Promise<Page<CreditorUserDepartmentAccess> | APIError>

  @State2Way('creditors/setAlert', (state) => state.creditors.alert) alert!: Alert | undefined

  async switchCreditor(creditorId: number) {
    if (await this.switchCurrentCreditor(creditorId) === true) router.push('/cases-overviews')
  }

  creditorUserDepartmentAccessItems(page: number): Promise<Page<CreditorUserDepartmentAccess> | APIError> {
    return this.refreshCreditorUserDepartmentAccesses(page)
  }

  get creditorUserDepartmentAccessFields(): FieldType[] {
    return [
      {
        key: 'creditorUserId', label: 'creditorUserId', sortable: false, size: 'small', alignment: 'left',
      },
      {
        key: 'creditorDepartmentId', label: 'creditorDepartmentId', sortable: false, size: 'flex', alignment: 'left',
      },
    ]
  }
}
