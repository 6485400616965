
import Vue from 'vue'
import Component from 'vue-class-component'
import router from '@/router'
import { namespace } from 'vuex-class'

const CreditorsAPI = namespace('creditorsAPI')

@Component
export default class OnboardingLogoutView extends Vue {
  @CreditorsAPI.Mutation setToken!: (token?: string) => void

  created() {
    this.setToken(undefined)
    router.push('/')
  }
}
