import {
  ActionTree, GetterTree, Module, MutationTree,
} from 'vuex'
import { RootState } from '@/store/index'
import {
  creditorsAPI, APIRequest, Pagination, PageModel, Page, APIError, missingError,
} from '@/store/modules/creditorsAPI'
import { CaseCostsGetModel, CostType, translateCostType } from '@/models/costs'
import { Alert, apiError } from '@/models/alerts'
import { Case } from '@/store/modules/cases'
import * as mock from '@/mockdata/caseCosts'
import { Formatting } from '@/models/formatting'

export interface CaseCost {
  caseId: number;
  created: Date;
  createdFormatted: string;
  cost: string;
  costType: CostType;
  remainingCapital: number;
  remainingCapitalFormatted: string;
  remainingInterest: number;
  remainingInterestFormatted: string;
}

export interface CaseCostsState {
  alert: Alert | undefined;
  caseCosts?: Page<CaseCost>;
}

export const state: CaseCostsState = {
  alert: undefined,
  caseCosts: undefined,
}

const namespaced = true

export const getters: GetterTree<CaseCostsState, RootState> = {
  getAlert(state): Alert | undefined {
    return state.alert
  },
  getCaseCosts(state): Page<CaseCost> | undefined {
    return state.caseCosts
  },
}

export const mutations: MutationTree<CaseCostsState> = {
  setAlert(state, alert: Alert | undefined) {
    state.alert = alert
  },
  setCaseCosts(state, caseCosts: Page<CaseCost>) {
    state.caseCosts = caseCosts
  },
}

export const actions: ActionTree<CaseCostsState, RootState> = {
  /**
   * Refresh the case costs entries
   *
   * @param store The vuex store.
   */
  async refreshCaseCosts(store, page: number): Promise<Page<CaseCost> | APIError> {
    console.log(`REFRESHING CASE COSTS ${page} START`)
    const currentCase: Case | undefined = store.rootGetters['cases/getCurrentCase']

    if (currentCase === undefined) return missingError('currentCase')

    // Build pagination from requested page
    const pagination = new Pagination(10, page)

    const request = <APIRequest> {
      method: 'get',
      path: `/case-costs?case_id=${currentCase.caseId}`,
      extra: currentCase.caseId,
      action: 'caseCosts/recieveCaseCostsGetModel',
      mock: mock.mockCaseCostsGetModel(pagination),
      paging: pagination,
    }
    const result: true | APIError = await store.dispatch('creditorsAPI/call', request)
    if (result !== true) {
      apiError(store, result)
      return result
    }
    return store.getters.getCaseCosts
  },

  /**
   * Recieves a CaseCostsGetModel and transforms it into costs an commits them to the store
   *
   * @param store The vuex store.
   * @param model The CaseCostsGetModel model.
   */
  async recieveCaseCostsGetModel(store, page: PageModel<CaseCostsGetModel>) {
    const caseId = page.extra as number

    const caseCosts: Page<CaseCost> = Page.transform(page, ((it) => <CaseCost> {
      caseId,
      created: new Date(it.created),
      createdFormatted: Formatting.formatDate(new Date(it.created)),
      cost: translateCostType(it.type),
      costType: it.type,
      remainingCapital: it.remaining_capital,
      remainingCapitalFormatted: Formatting.formatAmount(it.remaining_capital),
      remainingInterest: it.remaining_interest,
      remainingInterestFormatted: Formatting.formatAmount(it.remaining_interest),
    }))

    store.commit('setCaseCosts', caseCosts)
  },
}

export const caseCosts: Module<CaseCostsState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations,
  modules: {
    creditorsAPI,
  },
}
