import i18n from '@/plugins/i18n'

export type DocumentType = 'UNSPECIFIED' | 'INVOICE' | 'REMINDER' | 'COLLECTION' | 'RECEIPT' | 'CONTEST' | 'PRINT'
export function translateDocumentType(document: DocumentType): string {
  switch (document) {
    case 'UNSPECIFIED': return i18n.tc('models.document.UNSPECIFIED')
    case 'INVOICE': return i18n.tc('models.document.INVOICE')
    case 'REMINDER': return i18n.tc('models.document.REMINDER')
    case 'COLLECTION': return i18n.tc('models.document.COLLECTION')
    case 'RECEIPT': return i18n.tc('models.document.RECEIPT')
    case 'CONTEST': return i18n.tc('models.document.CONTEST')
    case 'PRINT': return i18n.tc('models.document.PRINT')
    default: return ''
  }
}

export interface CaseDocumentsGetModel {
  case_document_id: number; // Document identifier
  description: string; // Description of what the document contain
  filename: string; // Filename of the document
  document_type: DocumentType; // Type of document
  create_date: string; // Date when document initial created
}

export interface CaseDocumentsDetailsModel {
  url_path: string; // Url path for document
  filename: string; // Filename of the document
}

export interface CaseDocumentsPostResponseModel {
  case_document_id: number; // Temporary id to use when register sub entities to Case Documents
}
