import { Pagination, PageModel, APIError } from '@/store/modules/creditorsAPI'
import { CaseGetModel, CaseDetailsModel, StatusType, CasePostModel, CasePostResponseModel, CaseGetInvoicesModel } from '@/models/cases'
import { GetDashboardGetModel, DashboardEntryModel } from '@/models/dashboard'
import { Case } from '@/store/modules/cases'
import { CreditSafeCompanyResponseModel, CreditSafePersonalResponseModel } from '@/models/creditsafe'

export function mockGetDashboardGetModel(): GetDashboardGetModel {
  return {
    invoice: mockDashboardEntryModel('INVOICE'),
    reminder: mockDashboardEntryModel('REMINDER'),
    collection: mockDashboardEntryModel('COLLECTION'),
    surveillance: mockDashboardEntryModel('SURVEILLANCE'),
    verdict_warning: mockDashboardEntryModel('VERDICT_WARNING'),
    verdict: mockDashboardEntryModel('VERDICT'),
    enforcement: mockDashboardEntryModel('ENFORCEMENT'),
    amortisation: mockDashboardEntryModel('AMORTISATION'),
    other: mockDashboardEntryModel('OTHER'),
  }
}

export function mockDashboardEntryModel(status: StatusType): DashboardEntryModel {
  const items = mockItems.filter((it) => {
    if (status === 'OTHER') { return it.status === 'OTHER' || it.status === null }
    return it.status === status
  })
  return {
  }
}

export function mockCasePostResponseModels(data: CasePostModel[]): CasePostResponseModel[] {
  return [
  ]
}

export function mockCaseDetailsModel(current?: Case): CaseDetailsModel {
  const closure = current?.closureDate?.toDateString()
  return {
  }
}

export function mockCaseGetModel(pagination: Pagination, isClosed: boolean, matches: StatusType[] | null, creditorDepartmentId: number | null, caseId: number | null, referenceId: string | null): PageModel<CaseGetModel> {
  // Filter on status and closed
  var results = mockItems
  if (matches !== null) {
    results = results.filter((it) => it.status !== null && matches.includes(it.status))
  }
  if (creditorDepartmentId !== null && creditorDepartmentId !== 0) {
    results = results.filter((it) => it.creditor_department_id === creditorDepartmentId)
  }
  if (caseId !== null && caseId !== 0) {
    results = results.filter((it) => it.case_id === caseId)
  }
  if (referenceId !== null && referenceId !== '') {
    results = results.filter((it) => it.creditor_case_reference_id === referenceId)
  }
  if (isClosed) {
    results = results.filter((it) => it.closure_code !== null)
  }
  if (!isClosed) {
    results = results.filter((it) => it.closure_code === null)
  }
  
  const total = results.length

  // Paging
  const start = (pagination.page - 1) * pagination.maxResults
  const end = start + pagination.maxResults
  results = results.slice(start, end)

  return {
    _total: total,
    _max_results: pagination.maxResults,
    _page: pagination.page,
    extra: undefined,
    _items: results,
  }
}

const mockItems: CaseGetModel[] = [
]

export function mockCreditSafeCompanyResponseModel(organisationId: string | undefined): CreditSafeCompanyResponseModel {
  return {
  }
}

export function mockCreditSafePersonalResponseModel(organisationId: string | undefined): CreditSafePersonalResponseModel | APIError {
  if (organisationId === '9009099999') return <APIError> { errors: {}, message: 'Invalid personal identity number' }
  return {
  }
}
